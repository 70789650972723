import { useEffect } from "react";

import { message, Switch, Table, Tooltip } from "antd";
import {
  FilterValue,
  SorterResult,
  TablePaginationConfig,
} from "antd/lib/table/interface";
import { Link, useLocation } from "react-router-dom";
import { usePrevious } from "react-use";

import Button from "@app/components/atoms/Button/Button";
import TableView, {
  TableViewProps,
} from "@app/components/molecules/TableView/TableView";
import useSearchParams, { SearchParamDef } from "@app/hooks/useSearchParams";
import { useAppDispatch, useAppSelector } from "@app/redux/store";
import { SortEnum } from "@app/types/table.types";

import usePackageList from "../../hooks/usePackageList";
import {
  PackageDef,
  PackagePathsEnum,
  SearchQueryParamDef,
  toggleStatus,
} from "../../package";
import PackageFilterByFacility from "../PackagesFilter/PackageFilterByFacility";
import PackagesFilter from "../PackagesFilter/PackagesFilter";
import styles from "./PackagesTable.module.scss";

type PackageTableProps = TableViewProps<PackageDef>;

const PackagesTable = ({ ...props }: PackageTableProps) => {
  const dispatch = useAppDispatch();
  const { getSortOrder, setCurrentSort } =
    useSearchParams<SearchParamDef<SearchQueryParamDef>>();
  const {
    packageList,
    paginationData,
    loading,
    getInitialPackageList,
    getUpdatedPackageList,
  } = usePackageList();

  const { statusLoading } = useAppSelector(state => ({
    statusLoading: state.facility.statusLoading,
  }));

  const location = useLocation();
  const prevLocation = usePrevious(location);

  useEffect(() => {
    // Fetch Packages on initial load
    if (prevLocation?.search === undefined) {
      getInitialPackageList();
    }

    // Fetch data on navigation reset
    if (prevLocation?.search && location.search === "") {
      getInitialPackageList(true);
    }
  }, [getInitialPackageList, location.search, prevLocation?.search]);

  const parseSort = (
    sorter: SorterResult<PackageDef> | SorterResult<PackageDef>[]
  ) => {
    if (!Array.isArray(sorter)) {
      const { order } = sorter;

      if (order) {
        return order === "ascend" ? SortEnum.ASC : SortEnum.DESC;
      }
    }

    return undefined;
  };

  const parseOrderBy = (
    sorter: SorterResult<PackageDef> | SorterResult<PackageDef>[]
  ) => {
    if (!Array.isArray(sorter) && sorter.order) {
      if (sorter.columnKey === "title") {
        return "title";
      }

      return sorter.columnKey;
    }

    return undefined;
  };

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<PackageDef> | SorterResult<PackageDef>[]
  ) => {
    if (!loading) {
      getUpdatedPackageList({
        page: pagination.current,
        perPage: pagination.pageSize,
        sort: parseSort(sorter) ?? SortEnum.DESC,
        orderBy: (parseOrderBy(sorter) as string) ?? "is_active",
      });
    }

    if (!Array.isArray(sorter)) {
      setCurrentSort(sorter.order);
    }
  };

  const togglePackageStatus = async (id: PackageDef["id"]) => {
    const response = await dispatch(toggleStatus({ package_id: Number(id) }));

    if (toggleStatus.fulfilled.match(response)) {
      message.success("Package status changed successfully.");
    } else {
      message.error("Package status change failed");
    }
  };

  return (
    <TableView<PackageDef>
      dataSource={packageList}
      loading={loading}
      actionTitle="Action"
      title={() => (
        <div className={styles.packageTableTitle}>
          <div className={styles.filterWrapper}>
            <PackagesFilter />
            <PackageFilterByFacility />
          </div>
          <Link to={PackagePathsEnum.CREATE_PACKAGE}>
            <Button type="primary">Create a new package</Button>
          </Link>
        </div>
      )}
      pagination={{
        current: paginationData?.currentPage,
        pageSize: paginationData?.perPage,
        total: paginationData?.total,
        showSizeChanger: true,
        pageSizeOptions: ["6", "8", "10", "15", "25"],
      }}
      hideActionColumn
      onChange={handleTableChange}
      {...props}
    >
      <Table.Column
        title="Title"
        key="title"
        dataIndex="title"
        sorter
        sortOrder={getSortOrder("title")}
        ellipsis
        render={(title: string) => <div className="tableCellWrap">{title}</div>}
      />
      <Table.Column
        title="Title (Bangla)"
        key="titleBn"
        dataIndex="title_bn"
        ellipsis
        render={(titleBn: string) => (
          <div className="tableCellWrap">{titleBn}</div>
        )}
      />
      <Table.Column
        title="Facility Name"
        key="facilityName"
        dataIndex="facility"
        render={(record): string => record?.name}
      />
      <Table.Column
        title="Validity (Days)"
        key="days"
        dataIndex="days"
        sorter
        sortOrder={getSortOrder("days")}
        render={(days: number) => days}
      />
      <Table.Column
        title="Usage (Services)"
        key="usages"
        dataIndex="usages"
        sorter
        sortOrder={getSortOrder("usages")}
        render={(usages: number) => usages}
      />
      <Table.Column
        title="Amount"
        key="amount"
        dataIndex="amount"
        sorter
        sortOrder={getSortOrder("amount")}
        render={(amount: string) => amount}
      />
      <Table.Column
        title="Status"
        key="is_active"
        dataIndex="is_active"
        sorter
        sortOrder={getSortOrder("is_active")}
        render={(isActive: boolean, value: PackageDef) => {
          return (
            <Tooltip
              title={isActive ? "Deactivate package" : "Activate package"}
            >
              <Switch
                defaultChecked={isActive}
                onChange={() => togglePackageStatus(value?.id)}
                checkedChildren="Active"
                unCheckedChildren="Inactive"
                disabled={statusLoading}
              />
            </Tooltip>
          );
        }}
      />
    </TableView>
  );
};

export default PackagesTable;
