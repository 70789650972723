import { Redirect } from "react-router-dom";

import { flatten } from "@app/helpers/route.helper";
import NestedRouteWrapper from "@app/routes/NestedRouteWrapper";

import { AdminPathsEnum } from "../admin";
import { ADMIN_ROUTES } from "./admin.routes";

const AdminRoutes = () => {
  const routesWithComponents = flatten(ADMIN_ROUTES);

  return (
    <NestedRouteWrapper
      routesWithComponents={routesWithComponents}
      additionalComponent={
        /* Redirect from blank route */
        <Redirect
          exact
          from={AdminPathsEnum.ADMIN}
          to={AdminPathsEnum.ADMIN_LIST}
        />
      }
    />
  );
};

export default AdminRoutes;
