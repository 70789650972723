import { memo, useEffect } from "react";

import { useForm } from "antd/lib/form/Form";
import Search from "antd/lib/input/Search";
import { useLocation } from "react-router-dom";
import { usePrevious } from "react-use";

import PageFilter, {
  FilterItem,
} from "@app/components/molecules/PageFilter/PageFilter";

import useAdminList from "../../hooks/useAdminList";
import styles from "./AdminsFilter.module.scss";

export interface AdminsFilterProps {
  search?: string;
}

const AdminsFilter = () => {
  const location = useLocation();
  const prevLocation = usePrevious(location);
  const [form] = useForm();
  const {
    getInitialAdminList,
    getUpdatedAdminList,
    loading,
    updateSearchParams,
  } = useAdminList();

  useEffect(() => {
    // Fetch Admin on initial load
    if (prevLocation?.search === undefined) {
      getInitialAdminList();
    }

    // Fetch data on navigation reset
    if (prevLocation?.search && location.search === "") {
      getInitialAdminList(true);
    }
  }, [
    getInitialAdminList,
    location.search,
    prevLocation?.search,
    updateSearchParams,
  ]);

  const searchAdmins = () => {
    updateSearchParams({ page: 1, orderBy: undefined, sort: undefined }); // Resets search params
    getUpdatedAdminList({
      searchQuery: form.getFieldValue("search") || undefined,
      page: 1,
      orderBy: undefined,
      sort: undefined,
    });
  };

  const handleSearch = async () => {
    form.submit();

    if (!loading) {
      searchAdmins();
    }
  };

  return (
    <PageFilter<AdminsFilterProps> parseNumbers={["search"]} form={form}>
      <FilterItem className={styles.filterLabel} name="search">
        <Search
          placeholder="Search by name, email or phone"
          enterButton
          onSearch={handleSearch}
        />
      </FilterItem>
    </PageFilter>
  );
};

export default memo(AdminsFilter);
