import ContentLayout from "@app/components/layouts/ContentLayout/ContentLayout";

import UsersTable from "../../components/UsersTable/UsersTable";

const UserListScreen = () => {
  return (
    <ContentLayout header={{ title: "User List" }}>
      <UsersTable />
    </ContentLayout>
  );
};

export default UserListScreen;
