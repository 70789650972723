import { memo, useEffect, useState } from "react";

import { CommentOutlined, HeatMapOutlined } from "@ant-design/icons";
import {
  Input,
  Row,
  Col,
  message,
  Checkbox,
  Switch,
  Rate,
  Tooltip,
} from "antd";
import { Link, useHistory } from "react-router-dom";
import { useUnmount } from "react-use";

import Button from "@app/components/atoms/Button/Button";
import Form, { Item, useForm } from "@app/components/atoms/Form/Form";
import {
  DEFAULT_INPUT_LENGTH,
  DEFAULT_TEXT_AREA_LENGTH,
} from "@app/constants/input.constants";
import { ActivityStatusEnum } from "@app/constants/status.constants";
import { useAppDispatch, useAppSelector } from "@app/redux/store";
import { HasMessage } from "@app/types/api.types";

import {
  entryAFacility,
  FacilityPathsEnum,
  modifyAFacility,
  toggleStatus,
  clearFacilityDetails,
} from "../../facility";
import styles from "./FacilityForm.module.scss";

type FacilityFormDef = {
  id?: number;
  name: string;
  nameBn: string;
  address: string;
  addressBn: string;
  description: string;
  howToGo: string;
  howToGoBn: string;
  latitude: string;
  longitude: string;
  disableFriendly: boolean;
  status: string;
  rating: number;
};

export interface FacilityFormProps {
  initialValues?: FacilityFormDef;
  loading?: boolean;
  editMode?: boolean;
}

const FacilityForm = ({
  initialValues,
  editMode,
  loading,
}: FacilityFormProps) => {
  const history = useHistory();
  const [form] = useForm();
  const [geolocation, setGeolocation] = useState({
    latitude: "",
    longitude: "",
  });
  const dispatch = useAppDispatch();
  const [isActive, setIsActive] = useState(false);
  const { statusLoading } = useAppSelector(state => ({
    statusLoading: state.facility.statusLoading,
  }));

  useUnmount(() => {
    dispatch(clearFacilityDetails());
  });

  useEffect(() => {
    if (initialValues && !form.isFieldsTouched()) {
      form.setFieldsValue(initialValues);
      setGeolocation({
        latitude: initialValues.latitude,
        longitude: initialValues.longitude,
      });
      setIsActive(
        initialValues?.status === ActivityStatusEnum.ACTIVE.toLowerCase()
      );
    }
  }, [initialValues, form]);

  const createFacility = async () => {
    const { getFieldValue } = form;

    const response = await dispatch(
      entryAFacility({
        name: getFieldValue("name"),
        name_bn: getFieldValue("nameBn"),
        address: getFieldValue("address"),
        address_bn: getFieldValue("addressBn"),
        additional_note: getFieldValue("description"),
        navigation_note: getFieldValue("howToGo"),
        navigation_note_bn: getFieldValue("howToGoBn"),
        lat: getFieldValue("latitude"),
        lng: getFieldValue("longitude"),
        disable_friendly: Boolean(getFieldValue("disableFriendly")),
      })
    );

    if (entryAFacility.fulfilled.match(response)) {
      message.success("Facility created successfully");
      history.push(FacilityPathsEnum.FACILITY_LIST);
    } else {
      message.error((response.payload as HasMessage)?.message);
    }
  };

  const handleCreate = () => {
    createFacility();
  };

  const updateFacility = async () => {
    if (!initialValues?.id) {
      return;
    }

    const { getFieldValue } = form;

    const response = await dispatch(
      modifyAFacility({
        id: Number(initialValues?.id),
        payload: {
          name: getFieldValue("name"),
          name_bn: getFieldValue("nameBn"),
          address: getFieldValue("address"),
          address_bn: getFieldValue("addressBn"),
          additional_note: getFieldValue("description"),
          navigation_note: getFieldValue("howToGo"),
          navigation_note_bn: getFieldValue("howToGoBn"),
          disable_friendly: getFieldValue("disableFriendly") ?? undefined,
          lat: getFieldValue("latitude"),
          lng: getFieldValue("longitude"),
        },
      })
    );

    if (modifyAFacility.fulfilled.match(response)) {
      message.success("Facility updated successfully");
    } else {
      message.error((response.payload as HasMessage)?.message);
    }
  };

  const handleUpdate = () => {
    updateFacility();
  };

  const handleFormValueChange = (
    changedValues: undefined,
    values: FacilityFormDef
  ) => {
    setGeolocation({
      latitude: values.latitude,
      longitude: values.longitude,
    });
  };

  const toggleFacilityStatus = async () => {
    setIsActive(state => !state);

    const response = await dispatch(
      toggleStatus({ facility_id: Number(initialValues?.id) })
    );

    if (toggleStatus.fulfilled.match(response)) {
      message.success("Facility status changed successfully.");
    } else {
      setIsActive(state => !state); // Undo to previous state
      message.error("Facility status change failed");
    }
  };

  const handleToggle = () => {
    toggleFacilityStatus();
  };

  return (
    <Row justify="space-between">
      <Col xs={24} className={styles.title}>
        <span className="font-weight-bold">Facility Information</span>
      </Col>

      <Col xs={8}>
        <Form
          form={form}
          onFinish={editMode ? handleUpdate : handleCreate}
          onValuesChange={handleFormValueChange}
        >
          {editMode && (
            <Item name="id" label="Facility ID">
              <Input disabled />
            </Item>
          )}

          <Item
            name="name"
            label="Name"
            rules={[
              {
                required: true,
                whitespace: true,
                message: "Please enter the name!",
              },
              {
                max: DEFAULT_INPUT_LENGTH,
                message: `Input length can't exceed ${DEFAULT_INPUT_LENGTH} characters!`,
              },
            ]}
          >
            <Input />
          </Item>

          <Item
            name="nameBn"
            label="Name (Bengali)"
            rules={[
              {
                required: true,
                whitespace: true,
                message: "Please enter the name!",
              },
              {
                max: DEFAULT_INPUT_LENGTH,
                message: `Input length can't exceed ${DEFAULT_INPUT_LENGTH} characters!`,
              },
            ]}
          >
            <Input />
          </Item>

          <Item
            name="address"
            label="Address"
            rules={[
              {
                required: true,
                whitespace: true,
                message: "Please enter the facility address!",
              },
              {
                max: DEFAULT_INPUT_LENGTH,
                message: `Input length can't exceed ${DEFAULT_INPUT_LENGTH} characters!`,
              },
            ]}
          >
            <Input />
          </Item>

          <Item
            name="addressBn"
            label="Address (Bengali)"
            rules={[
              {
                required: true,
                whitespace: true,
                message: "Please enter the facility address!",
              },
              {
                max: DEFAULT_INPUT_LENGTH,
                message: `Input length can't exceed ${DEFAULT_INPUT_LENGTH} characters!`,
              },
            ]}
          >
            <Input />
          </Item>

          <Item
            name="description"
            label="Description"
            rules={[
              {
                required: true,
                whitespace: true,
                message: "Please enter the facility description!",
              },
              {
                max: DEFAULT_TEXT_AREA_LENGTH,
                message: `Input length can't exceed ${DEFAULT_TEXT_AREA_LENGTH} characters!`,
              },
            ]}
          >
            <Input.TextArea />
          </Item>

          <Item
            name="howToGo"
            label="How to go"
            rules={[
              {
                required: true,
                whitespace: true,
                message: "Please enter how to go to the facility!",
              },
              {
                max: DEFAULT_TEXT_AREA_LENGTH,
                message: `Input length can't exceed ${DEFAULT_TEXT_AREA_LENGTH} characters!`,
              },
            ]}
          >
            <Input.TextArea />
          </Item>

          <Item
            name="howToGoBn"
            label="How to go (Bengali)"
            rules={[
              {
                required: true,
                whitespace: true,
                message: "Please enter how to go to the facility!",
              },
              {
                max: DEFAULT_TEXT_AREA_LENGTH,
                message: `Input length can't exceed ${DEFAULT_TEXT_AREA_LENGTH} characters!`,
              },
            ]}
          >
            <Input.TextArea />
          </Item>

          <Row gutter={8} align="middle" wrap={false}>
            <Col>
              <Item
                name="latitude"
                label="Latitude"
                rules={[
                  {
                    required: true,
                    message: "Please enter the latitude of the facility!",
                  },
                ]}
              >
                <Input type="number" />
              </Item>
            </Col>

            <Col>
              <Item
                name="longitude"
                label="Longitude"
                rules={[
                  {
                    required: true,
                    message: "Please enter the longitude of the facility!",
                  },
                ]}
              >
                <Input type="number" />
              </Item>
            </Col>

            <Col>
              <Button
                disabled={Boolean(
                  !geolocation.latitude || !geolocation.longitude
                )}
                className={styles.mapButton}
                type="default"
                htmlType="button"
              >
                <a
                  href={`https://www.google.com/maps/search/?api=1&query=${geolocation.latitude}%2C${geolocation.longitude}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Show in map
                </a>
              </Button>
            </Col>
          </Row>

          <Item name="disableFriendly" valuePropName="checked">
            <Checkbox>Disabled friendly</Checkbox>
          </Item>

          <Item>
            <Button disabled={loading} type="primary" htmlType="submit">
              {editMode ? "Update" : "Create"}
            </Button>
          </Item>
        </Form>
      </Col>

      {editMode && (
        <Col className={styles.statusRatingWrapper}>
          <Tooltip
            title={isActive ? "Deactivate facility" : "Activate facility"}
          >
            <Switch
              onChange={handleToggle}
              checkedChildren="Active"
              unCheckedChildren="Inactive"
              checked={isActive}
              disabled={statusLoading || loading}
            />
          </Tooltip>
          <div>
            <Rate allowHalf disabled value={form.getFieldValue("rating")} />
            <span>{` (${form.getFieldValue("rating")} average rating)`}</span>
            <Tooltip
              placement="topLeft"
              className={styles.feedbackTooltip}
              title="View All Feedbacks and Ratings"
            >
              <Link
                to={`${FacilityPathsEnum.FACILITY_FEEDBACKS}/${initialValues?.id}/feedbacks`}
              >
                <Button
                  disabled={loading}
                  size="small"
                  type="primary"
                  shape="circle"
                  icon={<CommentOutlined />}
                />
              </Link>
            </Tooltip>

            <Tooltip
              placement="topLeft"
              title="View Facility Environment Quality"
            >
              <Link
                to={`${FacilityPathsEnum.FACILITY_ENVIRONMENT_QUALITY}/${initialValues?.id}/environment-quality`}
              >
                <Button
                  disabled={loading}
                  size="small"
                  type="primary"
                  shape="circle"
                  icon={<HeatMapOutlined />}
                />
              </Link>
            </Tooltip>
          </div>
        </Col>
      )}
    </Row>
  );
};

export default memo(FacilityForm);
