import { Redirect } from "react-router-dom";

import { flatten } from "@app/helpers/route.helper";
import NestedRouteWrapper from "@app/routes/NestedRouteWrapper";

import { FacilityPathsEnum } from "../facility";
import { FACILITY_ROUTES } from "./facility.routes";

const FacilityRoutes = () => {
  const routesWithComponents = flatten(FACILITY_ROUTES);

  return (
    <NestedRouteWrapper
      routesWithComponents={routesWithComponents}
      additionalComponent={
        /* Redirect from blank route */
        <Redirect
          exact
          from={FacilityPathsEnum.FACILITY}
          to={FacilityPathsEnum.FACILITY_LIST}
        />
      }
    />
  );
};

export default FacilityRoutes;
