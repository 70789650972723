import { UnorderedListOutlined, UserOutlined } from "@ant-design/icons";

import { AuthRoleEnum } from "@app/features/auth/auth";
import { RouteItemDef } from "@app/types/route.types";

import { UserPathsEnum } from "../constants/user.paths";
import UserDetailsScreen from "../screens/UserDetailsScreen/UserDetailsScreen";
import UserListScreen from "../screens/UserListScreen/UserListScreen";
import UserRoutes from "./UserRoutes";

const USER_SCREEN: RouteItemDef = {
  id: "user",
  path: UserPathsEnum.USER,
  navigationTitle: "User",
  component: UserRoutes,
  sidebarIcon: UserOutlined,
  role: AuthRoleEnum.SUPER_ADMIN,
  nestedRoutes: [
    /* User list */
    {
      id: "userList",
      path: UserPathsEnum.USER_LIST,
      navigationTitle: "User List",
      component: UserListScreen,
      sidebarIcon: UnorderedListOutlined,
    },
    /* User Details */
    {
      id: "userDetails",
      path: `${UserPathsEnum.USER_DETAILS}/:id`,
      component: UserDetailsScreen,
      hideInNavigation: true,
    },
  ],
};

export const USER_ROUTES = [USER_SCREEN];
