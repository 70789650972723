import { useState } from "react";

import { PlusOutlined } from "@ant-design/icons";
import { Col, Input, message, Modal, Row, Table } from "antd";

import Button from "@app/components/atoms/Button/Button";
import Form, { Item, useForm } from "@app/components/atoms/Form/Form";
import TableView, {
  TableViewProps,
} from "@app/components/molecules/TableView/TableView";
import {
  DEFAULT_INPUT_LENGTH,
  DEFAULT_NUMBER_INPUT_PATTERN,
} from "@app/constants/input.constants";
import { useAppDispatch } from "@app/redux/store";
import { HasMessage } from "@app/types/api.types";

import {
  FacilityDeviceDef,
  DeviceDef,
  addDevice,
  getAFacility,
  deleteDevice,
} from "../../facility";
import styles from "./FacilityDeviceTable.module.scss";

interface UserPurchaseTableProps extends TableViewProps<FacilityDeviceDef> {
  deviceList: DeviceDef[];
  loading?: boolean;
  facilityId?: number;
}

const FacilityDeviceTable = ({
  className,
  deviceList,
  loading,
  facilityId,
  ...props
}: UserPurchaseTableProps) => {
  const [form] = useForm();
  const [modalVisible, setModalVisible] = useState(false);
  const dispatch = useAppDispatch();

  const data: FacilityDeviceDef[] = deviceList.map(device => ({
    id: device.id,
    name: device.title,
    ip: device.ip,
    port: Number(device.port),
    mac: device.mac,
    authCode: device.auth_code,
  }));

  const removeDeviceFromFacility = async (id: FacilityDeviceDef["id"]) => {
    if (!facilityId) {
      return;
    }

    const response = await dispatch(deleteDevice({ id }));

    if (deleteDevice.fulfilled.match(response)) {
      message.success("Device deleted successfully");
      dispatch(getAFacility({ id: facilityId })); // Fetch updated facility info
    } else {
      message.error("Device deletion failed!");
    }
  };

  const handleDelete = (device: FacilityDeviceDef) => {
    removeDeviceFromFacility(device.id);
  };

  const addDeviceToFacility = async () => {
    if (!facilityId) {
      return;
    }

    const { getFieldValue } = form;

    const response = await dispatch(
      addDevice({
        facility_id: facilityId,
        title: getFieldValue("deviceName"),
        ip: getFieldValue("ip"),
        port: getFieldValue("port"),
        mac: getFieldValue("mac"),
        auth_code: getFieldValue("authCode"),
      })
    );

    if (addDevice.fulfilled.match(response)) {
      message.success("Device added successfully");
      dispatch(getAFacility({ id: facilityId })); // Fetch updated facility info
      form.resetFields();
      setModalVisible(false);
    } else {
      message.error((response.payload as HasMessage)?.message);
    }
  };

  const handleSubmit = () => {
    addDeviceToFacility();
  };

  return (
    <>
      <TableView<FacilityDeviceDef>
        dataSource={data}
        loading={loading}
        actionTitle="Action"
        title={() => (
          <div className={styles.FacilityDeviceTableTitle}>
            <div>
              <span className="font-weight-bold">Device List</span>
            </div>

            <Button
              type="primary"
              onClick={() => setModalVisible(true)}
              icon={<PlusOutlined />}
            >
              Add a device
            </Button>
          </div>
        )}
        className={className}
        hidePagination
        onDelete={handleDelete}
        deleteText="Delete Device?"
        {...props}
      >
        <Table.Column
          title="Device Name"
          key="name"
          dataIndex="name"
          render={(name: string) => name}
        />
        <Table.Column
          title="IP"
          key="ip"
          dataIndex="ip"
          render={(ip: string) => ip}
        />
        <Table.Column
          title="Port"
          key="port"
          dataIndex="port"
          render={(port: string) => port}
        />
        <Table.Column
          title="MAC Address"
          key="mac"
          dataIndex="mac"
          render={(mac: string) => mac}
        />
        <Table.Column
          title="Auth Code"
          key="authCode"
          dataIndex="authCode"
          render={(authCode: string) => authCode}
        />
      </TableView>

      <Modal
        title="Add a device to this facility"
        centered
        visible={modalVisible}
        onOk={form.submit}
        okText="Add"
        onCancel={() => {
          setModalVisible(false);
          form.resetFields();
        }}
        maskClosable={false}
        confirmLoading={loading}
      >
        <Form form={form} onFinish={handleSubmit}>
          <Item
            label="Device Name"
            name="deviceName"
            rules={[
              {
                required: true,
                message: "Please enter the device name!",
              },
              {
                max: DEFAULT_INPUT_LENGTH,
                message: `Input length can't exceed ${DEFAULT_INPUT_LENGTH} characters!`,
              },
            ]}
          >
            <Input />
          </Item>

          <Row gutter={12}>
            <Col>
              <Item
                label="IP"
                name="ip"
                rules={[
                  {
                    required: true,
                    message: "Please enter the ip address!",
                  },
                  {
                    max: DEFAULT_INPUT_LENGTH,
                    message: `Input length can't exceed ${DEFAULT_INPUT_LENGTH} characters!`,
                  },
                ]}
              >
                <Input />
              </Item>
            </Col>

            <Col>
              <Item
                label="Port"
                name="port"
                rules={[
                  {
                    required: true,
                    message: "Please enter the port number!",
                  },
                  {
                    pattern: DEFAULT_NUMBER_INPUT_PATTERN,
                    message:
                      "Input can't exceed 12 digits with 2 decimal point max!",
                  },
                ]}
              >
                <Input type="number" />
              </Item>
            </Col>
          </Row>
          <Item
            label="MAC Address"
            name="mac"
            rules={[
              {
                required: true,
                message: "Please enter the mac address!",
              },
              {
                max: DEFAULT_INPUT_LENGTH,
                message: `Input length can't exceed ${DEFAULT_INPUT_LENGTH} characters!`,
              },
            ]}
          >
            <Input />
          </Item>
          <Item
            label="Auth Code"
            name="authCode"
            rules={[
              {
                required: true,
                message: "Please enter the auth code!",
              },
              {
                max: DEFAULT_INPUT_LENGTH,
                message: `Input length can't exceed ${DEFAULT_INPUT_LENGTH} characters!`,
              },
            ]}
          >
            <Input.Password />
          </Item>
        </Form>
      </Modal>
    </>
  );
};

export default FacilityDeviceTable;
