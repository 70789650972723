import dayjs from "dayjs";
import { useParams } from "react-router-dom";

import {
  DEFAULT_API_DATE_FORMAT,
  DEFAULT_DATE_RANGE,
} from "@app/constants/time.constants";
import useSearchParams, { SearchParamDef } from "@app/hooks/useSearchParams";
import { useAppDispatch, useAppSelector } from "@app/redux/store";

import {
  GetManagerRechargeHistoryDef,
  SearchQueryParamDef,
  getMangerRechargeHistory,
  updateCurrentParams,
} from "../manager";

type UsageHistoryParams = {
  id?: string;
};

const useManagerRechargeHistory = () => {
  const dispatch = useAppDispatch();
  const { updateSearchParams, search } =
    useSearchParams<SearchParamDef<SearchQueryParamDef>>();
  const { id } = useParams<UsageHistoryParams>();
  const { rechargeHistoryList, loading } = useAppSelector(state => ({
    rechargeHistoryList: state.manager.rechargeHistoryList,
    loading: state.facility.loading,
  }));

  const parseSort = () => {
    if (!search.sort) {
      return undefined;
    }

    return search.sort;
  };

  const parseOrderBy = () => {
    if (!search.sort) {
      return undefined;
    }

    return search.orderBy === "time" ? "created_at" : search.orderBy;
  };

  const getInitialRechargeHistoryList = () => {
    const currentDate = dayjs().format(DEFAULT_API_DATE_FORMAT);

    const params: GetManagerRechargeHistoryDef = {
      from: search.dateRange?.[0]
        ? dayjs(search.dateRange?.[0]).format(DEFAULT_API_DATE_FORMAT)
        : dayjs(currentDate)
            .subtract(DEFAULT_DATE_RANGE - 1, "days")
            .format(DEFAULT_API_DATE_FORMAT),
      to: search.dateRange?.[1]
        ? dayjs(search.dateRange?.[1]).format(DEFAULT_API_DATE_FORMAT)
        : currentDate,
      managerId: String(id),
      orderBy: parseOrderBy(),
      sort: parseSort(),
    };

    dispatch(updateCurrentParams(params));
    dispatch(getMangerRechargeHistory());
  };

  const getUpdatedRechargeHistoryList = (
    params: GetManagerRechargeHistoryDef
  ) => {
    dispatch(updateCurrentParams(params));
    dispatch(getMangerRechargeHistory());
  };

  return {
    getInitialRechargeHistoryList,
    getUpdatedRechargeHistoryList,
    rechargeHistoryList,
    loading,
    updateSearchParams,
    managerId: id,
    search,
  };
};

export default useManagerRechargeHistory;
