import { LayoutTypeEnum } from "@app/constants/layout.constants";
import { RouteItemDef } from "@app/types/route.types";

import { AuthPathsEnum } from "../constants/auth.paths";
import LoginScreen from "../screens/LoginScreen/LoginScreen";
import MyAccountScreen from "../screens/MyAccountScreen/MyAccountScreen";

const LOGIN_SCREEN: RouteItemDef = {
  id: "login",
  path: AuthPathsEnum.LOGIN,
  component: LoginScreen,
  navigationTitle: "auth.loginTitle",
  layoutType: LayoutTypeEnum.BLANK,
};

const MY_ACCOUNT_SCREEN: RouteItemDef = {
  id: "myAccount",
  path: AuthPathsEnum.MY_ACCOUNT,
  component: MyAccountScreen,
  navigationTitle: "My Account",
  hideInNavigation: true,
};

export const AUTH_ROUTES = [LOGIN_SCREEN];

export const AUTH_PRIVATE_ROUTES = [MY_ACCOUNT_SCREEN];
