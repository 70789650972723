import { Redirect, useLocation } from "react-router-dom";

import ContentLayout from "@app/components/layouts/ContentLayout/ContentLayout";
import { useAppSelector } from "@app/redux/store";

import { AdminDef } from "../../admin";
import AdminForm, {
  AdminFormProps,
} from "../../components/AdminForm/AdminForm";
import { AdminPathsEnum } from "../../constants/admin.paths";

interface AdminDetailsScreenLocation {
  admin: AdminDef;
}

const AdminDetailsScreen = () => {
  const location = useLocation<AdminDetailsScreenLocation>();
  const { loading, userId } = useAppSelector(state => ({
    loading: state.admin.loading,
    userId: state.auth.user?.id,
  }));

  const adminDetails = location.state?.admin;

  if (!adminDetails) {
    return <Redirect exact to={AdminPathsEnum.ADMIN_LIST} />;
  }

  const adminFormProps: AdminFormProps = {
    initialValues: adminDetails
      ? {
          id: adminDetails.id,
          name: adminDetails.name,
          email: adminDetails.email,
          phone: adminDetails.phone_number,
          canBeDeleted:
            adminDetails.canBeDeleted && Number(userId) !== adminDetails.id,
        }
      : undefined,
  };

  return (
    <ContentLayout header={{ title: "Admin Details" }}>
      <AdminForm {...adminFormProps} editMode loading={loading} />
    </ContentLayout>
  );
};

export default AdminDetailsScreen;
