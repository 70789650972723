import { message } from "antd";
import { useParams } from "react-router-dom";
import { useMount } from "react-use";

import ContentLayout from "@app/components/layouts/ContentLayout/ContentLayout";
import { useAppDispatch, useAppSelector } from "@app/redux/store";

import ManagerForm, {
  ManagerFormProps,
} from "../../components/ManagerForm/ManagerForm";
import { getAManager } from "../../manager";

type ManagerEditScreenParams = {
  id?: string;
};

const ManagerEditScreen = () => {
  const dispatch = useAppDispatch();
  const { managerDetails } = useAppSelector(state => ({
    managerDetails: state.manager.managerDetails,
  }));
  const params = useParams<ManagerEditScreenParams>();

  const managerId = Number(params.id);

  const getManagerDetails = async () => {
    const response = await dispatch(getAManager({ id: managerId }));

    if (!getAManager.fulfilled.match(response)) {
      message.error("Manager fetching failed");
    }
  };

  useMount(() => {
    getManagerDetails();
  });

  const initialValues: ManagerFormProps["initialValues"] = managerDetails && {
    id: managerId,
    email: managerDetails.email,
    firstName: managerDetails.first_name,
    lastName: managerDetails.last_name,
    phone: managerDetails.phone_number,
    cardId: managerDetails.card_id,
  };

  return (
    <ContentLayout header={{ title: "Edit Manager" }}>
      <ManagerForm editMode initialValues={initialValues} />
    </ContentLayout>
  );
};

export default ManagerEditScreen;
