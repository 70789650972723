import { Table, TablePaginationConfig } from "antd";
import { FilterValue, SorterResult } from "antd/lib/table/interface";
import dayjs from "dayjs";
import { useMount } from "react-use";

import TableView, {
  TableViewProps,
} from "@app/components/molecules/TableView/TableView";
import { DEFAULT_DATE_TIME_FORMAT } from "@app/constants/time.constants";
import { renderGender } from "@app/helpers/gender.helper";
import { SortEnum } from "@app/types/table.types";

import useEnvironmentQualityList from "../../hooks/useEnvironmentQualityList";
import { EnvironmentQualityDef } from "../../types/facility.types";
import EnvironmentQualityFilter from "../EnvironmentQualityFilter/EnvironmentQualityFilter";

type EnvironmentQualityTableProps = TableViewProps<EnvironmentQualityDef>;

const EnvironmentQualityTable = ({
  ...props
}: EnvironmentQualityTableProps) => {
  const {
    paginationData,
    loading,
    getInitialEnvironmentQualityList,
    getUpdatedEnvironmentQualityList,
    facilityId,
    setCurrentSort,
    getSortOrder,
    environmentQualityList,
  } = useEnvironmentQualityList();

  useMount(() => {
    // Fetch feedback list on initial load
    getInitialEnvironmentQualityList();
  });

  const parseSort = (
    sorter:
      | SorterResult<EnvironmentQualityDef>
      | SorterResult<EnvironmentQualityDef>[]
  ) => {
    if (!Array.isArray(sorter)) {
      const { order } = sorter;

      if (order) {
        return order === "ascend" ? SortEnum.ASC : SortEnum.DESC;
      }
    }

    return undefined;
  };

  const parseOrderBy = (
    sorter:
      | SorterResult<EnvironmentQualityDef>
      | SorterResult<EnvironmentQualityDef>[]
  ) => {
    if (!Array.isArray(sorter)) {
      const { order } = sorter;

      if (order) {
        return sorter.columnKey === "time" ? "created_at" : sorter.columnKey;
      }
    }

    return undefined;
  };

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter:
      | SorterResult<EnvironmentQualityDef>
      | SorterResult<EnvironmentQualityDef>[]
  ) => {
    if (!loading) {
      getUpdatedEnvironmentQualityList({
        facility_id: Number(facilityId),
        page: pagination.current,
        per_page: pagination.pageSize,
        sort: parseSort(sorter),
        order_by: parseOrderBy(sorter) as string,
      });
    }

    if (!Array.isArray(sorter)) {
      setCurrentSort(sorter.order);
    }
  };

  const getColoredClassName = (level: number) => {
    switch (true) {
      case level < 10:
        return "text-red";

      case level >= 10 && level <= 15:
        return "text-yellow";

      case level > 15:
        return "text-green";

      default:
        return "";
    }
  };

  return (
    <TableView<EnvironmentQualityDef>
      dataSource={environmentQualityList}
      loading={loading}
      actionTitle="Action"
      pagination={{
        current: paginationData?.currentPage,
        pageSize: paginationData?.perPage,
        total: paginationData?.total,
        showSizeChanger: true,
        pageSizeOptions: ["6", "8", "10", "15", "25"],
      }}
      onChange={handleTableChange}
      title={() => <EnvironmentQualityFilter facilityId={Number(facilityId)} />}
      hideActionColumn
      {...props}
    >
      <Table.Column
        title="Section"
        key="section"
        dataIndex="section"
        render={(section: string) => renderGender(section)}
      />
      <Table.Column
        title="Air Quality"
        key="airQuality"
        dataIndex="air_quality"
        render={(airQuality: string) => (
          <span className={getColoredClassName(Number(airQuality))}>
            {airQuality ? Number(airQuality) : ""}
          </span>
        )}
      />
      <Table.Column
        title="Humidity"
        key="humidity"
        dataIndex="humidity"
        render={(humidity: string) => (humidity ? `${Number(humidity)}%` : "")}
      />
      <Table.Column
        title="Temperature"
        key="temperature"
        dataIndex="temperature"
        render={(temperature: string) =>
          temperature ? `${Number(temperature)}°c` : ""
        }
      />
      <Table.Column
        title="Time"
        key="time"
        dataIndex="created_at"
        render={(date: string) => dayjs(date).format(DEFAULT_DATE_TIME_FORMAT)}
        sorter
        sortOrder={getSortOrder("time")}
      />
    </TableView>
  );
};

export default EnvironmentQualityTable;
