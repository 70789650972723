import { SortOrder } from "antd/lib/table/interface";

export type OrderByDef = {
  key: string | undefined;
  direction: SortOrder | undefined;
};

export type PaginationDef = {
  currentPage: number;
  perPage: number;
  total: number;
};

export enum SortEnum {
  ASC = "asc",
  DESC = "desc",
}

export interface CanBeDeleted {
  canBeDeleted?: boolean;
}
