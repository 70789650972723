import { combineReducers } from "@reduxjs/toolkit";

import { adminReducer, ADMIN_FEATURE_KEY } from "@app/features/admin/admin";
import { authReducer, AUTH_FEATURE_KEY } from "@app/features/auth/auth";
import {
  dashboardReducer,
  DASHBOARD_FEATURE_KEY,
} from "@app/features/dashboard/dashboard";
import {
  facilityReducer,
  FACILITY_FEATURE_KEY,
} from "@app/features/facility/facility";
import {
  managerReducer,
  MANAGER_FEATURE_KEY,
} from "@app/features/manager/manager";
import {
  PACKAGE_FEATURE_KEY,
  packageReducer,
} from "@app/features/package/package";
import {
  rechargeReducer,
  RECHARGE_FEATURE_KEY,
} from "@app/features/recharge/recharge";
import { userReducer, USER_FEATURE_KEY } from "@app/features/user/user";

const rootReducer = combineReducers({
  [AUTH_FEATURE_KEY]: authReducer,
  [ADMIN_FEATURE_KEY]: adminReducer,
  [USER_FEATURE_KEY]: userReducer,
  [FACILITY_FEATURE_KEY]: facilityReducer,
  [MANAGER_FEATURE_KEY]: managerReducer,
  [PACKAGE_FEATURE_KEY]: packageReducer,
  [RECHARGE_FEATURE_KEY]: rechargeReducer,
  [DASHBOARD_FEATURE_KEY]: dashboardReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
