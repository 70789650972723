/* eslint-disable @typescript-eslint/ban-types */
import { memo, ReactNode } from "react";

import {
  CopyOutlined,
  DeleteOutlined,
  EditOutlined,
  MenuOutlined,
  EyeOutlined,
  HistoryOutlined,
} from "@ant-design/icons";
import {
  Table,
  Space,
  Popconfirm,
  Tooltip,
  Menu,
  Dropdown,
  Switch,
} from "antd";
import { TablePaginationConfig, TableProps } from "antd/lib/table";
import {
  Key,
  SorterResult,
  TableCurrentDataSource,
} from "antd/lib/table/interface";
import cx from "classnames";

import Button from "@app/components/atoms/Button/Button";
import { ActivityStatusEnum } from "@app/constants/status.constants";
import {
  ViewActionTypeEnum,
  ToggleActionTypeEnum,
} from "@app/constants/table.constants";
import { scrollToTop } from "@app/helpers/util.helper";
import useSearchParams from "@app/hooks/useSearchParams";
import { CanBeDeleted, SortEnum } from "@app/types/table.types";

import styles from "./TableView.module.scss";

const { Column } = Table;

export type ActionMenuDef = { key: string; label: string }[];

export interface TableViewProps<T = {}> extends Omit<TableProps<T>, "columns"> {
  actionTitle?: string;
  onView?: (record: T) => void;
  viewActionType?: ViewActionTypeEnum;
  onEdit?: (record: T) => void;
  onDelete?: (record: T) => void;
  deleteText?: string;
  onToggle?: (record: T) => void;
  toggleActionType?: ToggleActionTypeEnum;
  onDuplicate?: (record: T) => void;
  extraActions?: (record: T) => ReactNode;
  actionMenu?: ActionMenuDef;
  onActionMenu?: (key: string, record: T) => void;
  actionWidth?: number | string;
  hideActionColumn?: boolean;
  disableScrollToTopOnChange?: boolean;
  hidePagination?: boolean;
}

const TableView = <T extends CanBeDeleted>({
  actionTitle,
  onView,
  onEdit,
  onDelete,
  deleteText,
  onToggle,
  onDuplicate,
  children,
  onChange,
  actionMenu,
  viewActionType,
  toggleActionType,
  onActionMenu,
  extraActions,
  actionWidth = 150,
  hideActionColumn = false,
  className,
  disableScrollToTopOnChange,
  hidePagination,
  ...tableProps
}: TableViewProps<T>) => {
  const { updateSearchParams } = useSearchParams();

  const handleOnChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, (Key | boolean)[] | null>,
    sorter: SorterResult<T> | SorterResult<T>[],
    extra: TableCurrentDataSource<T>
  ) => {
    let orderBy: string | undefined;
    let sort: SortEnum | undefined;

    if (!Array.isArray(sorter)) {
      // Use column key or field (`dataIndex`), depending on what is set
      const columnKey =
        sorter.columnKey?.toString() ?? sorter.field?.toString();

      orderBy = (sorter.order && columnKey) || undefined;
      sort =
        (sorter.order &&
          (sorter.order === "ascend" ? SortEnum.ASC : SortEnum.DESC)) ||
        undefined;
    }

    const page = pagination.current;
    const { pageSize } = pagination;

    updateSearchParams({
      orderBy,
      sort,
      page,
      pageSize,
    });

    if (!disableScrollToTopOnChange) {
      // Scroll to top when there are changes to pagination, sorting, or filters
      scrollToTop();
    }

    onChange?.(pagination, filters, sorter, extra);
  };

  const getMenu = (record: T) => (
    <Menu onClick={e => onActionMenu?.(e.key.toString(), record)}>
      {actionMenu?.map(({ key, label }) => (
        <Menu.Item key={key}>{label}</Menu.Item>
      ))}
    </Menu>
  );

  return (
    <Table
      rowKey="id"
      onChange={handleOnChange}
      className={cx(styles.table, className)}
      pagination={hidePagination ? false : tableProps.pagination}
      {...tableProps}
    >
      {children}
      {!hideActionColumn && (
        <Column<T>
          key="action"
          title={actionTitle}
          fixed="right"
          width={actionWidth}
          className={styles.actions}
          render={(_, record) => (
            <Space size="middle">
              {!!onView &&
                (viewActionType === ViewActionTypeEnum.RECHARGE_HISTORY ? (
                  <Tooltip title={viewActionType}>
                    <Button
                      onClick={() => onView(record)}
                      shape="circle"
                      icon={<HistoryOutlined />}
                    />
                  </Tooltip>
                ) : (
                  <Tooltip title={ViewActionTypeEnum.GENERIC}>
                    <Button
                      onClick={() => onView(record)}
                      shape="circle"
                      icon={<EyeOutlined />}
                    />
                  </Tooltip>
                ))}
              {!!onEdit && (
                <Tooltip title="Edit">
                  <Button
                    onClick={() => onEdit(record)}
                    shape="circle"
                    icon={<EditOutlined />}
                  />
                </Tooltip>
              )}
              {!!onDuplicate && (
                <Tooltip title="Duplicate">
                  <Button
                    onClick={() => onDuplicate(record)}
                    shape="circle"
                    icon={<CopyOutlined />}
                  />
                </Tooltip>
              )}
              {!!onDelete && (
                <Popconfirm
                  title={deleteText ?? "Delete Admin?"}
                  okText="Yes"
                  cancelText="Cancel"
                  onConfirm={() => onDelete(record)}
                  placement="left"
                >
                  <Tooltip title="Delete">
                    <Button
                      disabled={
                        record.canBeDeleted !== undefined
                          ? !record.canBeDeleted
                          : false
                      }
                      shape="circle"
                      icon={<DeleteOutlined />}
                    />
                  </Tooltip>
                </Popconfirm>
              )}
              {!!onToggle &&
                (toggleActionType === ToggleActionTypeEnum.ACTIVE_INACTIVE ? (
                  <Tooltip title="Active / Inactive">
                    <Switch
                      onChange={() => onToggle(record)}
                      checkedChildren={ActivityStatusEnum.ACTIVE}
                      unCheckedChildren={ActivityStatusEnum.INACTIVE}
                    />
                  </Tooltip>
                ) : null)}
              {/* {!!onToggle &&
                (toggleActionType === ToggleActionTypeEnum.ENABLE_DISABLE
                  ? { children }
                  : null)} */}
              {!!extraActions && extraActions(record)}
              {actionMenu && (
                <Dropdown
                  key="more"
                  overlay={getMenu(record)}
                  trigger={["click"]}
                >
                  <Tooltip title="default.moreTitle">
                    <Button shape="circle" icon={<MenuOutlined />} />
                  </Tooltip>
                </Dropdown>
              )}
            </Space>
          )}
        />
      )}
    </Table>
  );
};

export default memo(TableView) as typeof TableView;
export { Column };
