import { memo } from "react";

import { Select } from "antd";
import { useForm } from "antd/lib/form/Form";
import dayjs from "dayjs";
import { useMount } from "react-use";

import { RangePicker } from "@app/components/atoms/DateTimePicker/DateTimePicker";
import PageFilter, {
  FilterItem,
} from "@app/components/molecules/PageFilter/PageFilter";
import { GenderEnum } from "@app/constants/gender.constants";
import {
  DEFAULT_API_DATE_FORMAT,
  DEFAULT_DATE_FORMAT,
  DEFAULT_DATE_RANGE,
} from "@app/constants/time.constants";
import { UserTypeEnum } from "@app/constants/user.constants";

import useUsageHistory from "../../hooks/useUsageHistory";
import styles from "./UsageFilter.module.scss";

export interface UsageFilterDef {
  dateRange?: [dayjs.Dayjs, dayjs.Dayjs];
  gender?: GenderEnum;
  type?: UserTypeEnum;
}

export interface UsageFilterProps {
  facilityId: number;
}

const { Option } = Select;

const UsageFilter = ({ facilityId }: UsageFilterProps) => {
  const [form] = useForm();
  const { loading, updateSearchParams, getUpdatedUsageList, search } =
    useUsageHistory();

  const currentDate = dayjs();

  useMount(() => {
    if (!search.dateRange) {
      const from = dayjs(currentDate).subtract(DEFAULT_DATE_RANGE - 1, "days");
      const to = currentDate;

      form.setFieldsValue({
        dateRange: [from, to],
      });

      updateSearchParams({
        dateRange: [
          from.format(DEFAULT_API_DATE_FORMAT),
          to.format(DEFAULT_API_DATE_FORMAT),
        ],
      });
    }
  });

  const searchUsage = () => {
    const dateRange = form.getFieldValue("dateRange") || undefined;
    const gender = form.getFieldValue("gender") || undefined;
    const type = form.getFieldValue("type") || undefined;

    // Resets search params
    updateSearchParams({
      dateRange,
      gender,
      type,
      page: 1,
      orderBy: undefined,
      sort: undefined,
    });

    getUpdatedUsageList({
      from: dateRange?.[0]
        ? dayjs(dateRange[0]).format(DEFAULT_API_DATE_FORMAT)
        : undefined,
      to: dateRange?.[1]
        ? dayjs(dateRange[1]).format(DEFAULT_API_DATE_FORMAT)
        : undefined,
      facilityId: String(facilityId),
      page: 1,
      orderBy: undefined,
      sort: undefined,
      gender,
      userType: type,
    });
  };

  const handleSearch = async () => {
    if (!loading) {
      searchUsage();
    }
  };

  return (
    <PageFilter<UsageFilterDef>
      parseDates
      form={form}
      columns={3}
      submitOnChange
      onSubmit={handleSearch}
    >
      <FilterItem name="dateRange">
        <RangePicker format={DEFAULT_DATE_FORMAT} inputReadOnly />
      </FilterItem>

      <FilterItem name="gender">
        <Select
          placeholder="Filter by gender"
          allowClear
          className={styles.filterLabel}
        >
          <Option value={GenderEnum.MALE}>Male</Option>
          <Option value={GenderEnum.FEMALE}>Female</Option>
        </Select>
      </FilterItem>

      <FilterItem name="type">
        <Select
          placeholder="Filter by type"
          allowClear
          className={styles.filterLabel}
        >
          <Option value={UserTypeEnum.USER}>User</Option>
          <Option value={UserTypeEnum.MANAGER}>Manager</Option>
          <Option value={UserTypeEnum.PACKAGE_USER}>Package User</Option>
        </Select>
      </FilterItem>
    </PageFilter>
  );
};

export default memo(UsageFilter);
