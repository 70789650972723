import { message, Table } from "antd";
import { Link } from "react-router-dom";
import { useMount } from "react-use";

import Button from "@app/components/atoms/Button/Button";
import TableView, {
  TableViewProps,
} from "@app/components/molecules/TableView/TableView";
import { useAppDispatch, useAppSelector } from "@app/redux/store";

import {
  AmountDef,
  RechargePathsEnum,
  getAllAmounts,
  removeAmount,
} from "../../recharge";
import styles from "./AmountsTable.module.scss";

type AmountTableProps = TableViewProps<AmountDef>;

const AmountsTable = ({ ...props }: AmountTableProps) => {
  const dispatch = useAppDispatch();
  const { amountList, loading } = useAppSelector(state => ({
    amountList: state.recharge.amountList,
    loading: state.recharge.loading,
  }));

  const getAmountList = async () => {
    const response = await dispatch(getAllAmounts());

    if (!getAllAmounts.fulfilled.match(response)) {
      message.error("Amount list fetching failed");
    }
  };

  useMount(() => {
    getAmountList();
  });

  const deleteAmount = async (id: AmountDef["id"]) => {
    const response = await dispatch(removeAmount({ id }));

    if (removeAmount.fulfilled.match(response)) {
      message.success("Recharge amount deleted successfully");
      dispatch(getAllAmounts()); // Get updated amount list
    } else {
      message.error("Recharge amount deletion failed");
    }
  };

  const handleDelete = (amount: AmountDef) => {
    deleteAmount(amount.id);
  };

  return (
    <TableView<AmountDef>
      dataSource={amountList}
      loading={loading}
      actionTitle="Action"
      title={() => (
        <div className={styles.amountTableTitle}>
          <Link to={RechargePathsEnum.CREATE_AMOUNT}>
            <Button type="primary">Create a new amount</Button>
          </Link>
        </div>
      )}
      onDelete={handleDelete}
      deleteText="Delete Package?"
      hidePagination
      {...props}
    >
      <Table.Column
        title="Title"
        key="title"
        dataIndex="title"
        render={(title: string) => title}
      />
      <Table.Column
        title="Title (Bengali)"
        key="titleBn"
        dataIndex="title_bn"
        render={(titleBn: string) => titleBn}
      />
      <Table.Column
        title="Amount"
        key="amount"
        dataIndex="amount"
        render={(amount: string) => amount}
      />
    </TableView>
  );
};

export default AmountsTable;
