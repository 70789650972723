/* eslint-disable camelcase */
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { RootState } from "@app/redux/root-reducer";

import {
  blockUser,
  getUser,
  getUserList,
  getUserPurchaseHistory,
  getUserPackageSubscriptionHistory,
  unblockUser,
} from "../api/user.api";
import { USER_FEATURE_KEY } from "../constants/user.keys";
import {
  InitialStateDef,
  GetUserListDef,
  userErrorHelper,
  GetUserDef,
  BlockUserDef,
  UnblockUserDef,
  GetUserHistoriesDef,
} from "../user";

const initialState: InitialStateDef = {
  userList: [],
  error: false,
  loading: false,
  currentParams: {},
  purchaseHistoryLoading: false,
  purchaseHistory: [],
  packageSubscriptionHistory: [],
  packageSubscriptionHistoryLoading: false,
};

export const getAllUsers = createAsyncThunk(
  `${USER_FEATURE_KEY}/getAllUsers`,
  async (values: undefined, { rejectWithValue, getState }) => {
    const { user } = getState() as RootState;

    try {
      const response = await getUserList(user.currentParams);

      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getUserDetails = createAsyncThunk(
  `${USER_FEATURE_KEY}/getUserDetails`,
  async (payload: GetUserDef, { rejectWithValue }) => {
    try {
      const response = await getUser(payload);

      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const blockAUser = createAsyncThunk(
  `${USER_FEATURE_KEY}/blockAUser`,
  async (payload: BlockUserDef, { rejectWithValue }) => {
    try {
      const response = await blockUser(payload);

      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const unblockAUser = createAsyncThunk(
  `${USER_FEATURE_KEY}/unblockAUser`,
  async (payload: UnblockUserDef, { rejectWithValue }) => {
    try {
      const response = await unblockUser(payload);

      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getPurchaseHistory = createAsyncThunk(
  `${USER_FEATURE_KEY}/getPurchaseHistory`,
  async (values: undefined, { rejectWithValue, getState }) => {
    const { user } = getState() as RootState;

    try {
      const response = await getUserPurchaseHistory(
        user.currentParams as GetUserHistoriesDef
      );

      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getPackageSubscriptionHistory = createAsyncThunk(
  `${USER_FEATURE_KEY}/getPackageSubscriptionHistory`,
  async (values: undefined, { rejectWithValue, getState }) => {
    const { user } = getState() as RootState;

    try {
      const response = await getUserPackageSubscriptionHistory(
        user.currentParams as GetUserHistoriesDef
      );

      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

const userSlice = createSlice({
  name: USER_FEATURE_KEY,
  initialState,
  reducers: {
    updateCurrentParams(state, action: PayloadAction<GetUserListDef>) {
      state.currentParams = {
        ...state.currentParams,
        ...action.payload,
      };
    },
  },
  extraReducers: builder => {
    /**
     * GET_USER_LIST
     */
    builder.addCase(getAllUsers.pending, state => {
      state.error = false;
      state.loading = true;
    });
    builder.addCase(getAllUsers.fulfilled, (state, action) => {
      const { data, meta } = action.payload;

      state.loading = false;
      state.userList = data;
      state.paginationData = {
        currentPage: meta?.current_page,
        perPage: meta?.per_page,
        total: meta?.total,
      };
    });
    builder.addCase(getAllUsers.rejected, state => {
      userErrorHelper(state);
    });
    /**
     * GET_USER_DETAILS
     */
    builder.addCase(getUserDetails.pending, state => {
      state.error = false;
      state.loading = true;
    });
    builder.addCase(getUserDetails.fulfilled, (state, action) => {
      state.loading = false;
      state.userDetails = action.payload;
    });
    builder.addCase(getUserDetails.rejected, state => {
      userErrorHelper(state);
    });
    /**
     * BLOCK_A_USER
     */
    builder.addCase(blockAUser.pending, state => {
      state.error = false;
      state.loading = true;
    });
    builder.addCase(blockAUser.fulfilled, state => {
      state.loading = false;

      if (state.userDetails) {
        state.userDetails.blocked = true;
      }
    });
    builder.addCase(blockAUser.rejected, state => {
      userErrorHelper(state);
    });
    /**
     * UNBLOCK_A_USER
     */
    builder.addCase(unblockAUser.pending, state => {
      state.error = false;
      state.loading = true;
    });
    builder.addCase(unblockAUser.fulfilled, state => {
      state.loading = false;

      if (state.userDetails) {
        state.userDetails.blocked = false;
      }
    });
    builder.addCase(unblockAUser.rejected, state => {
      userErrorHelper(state);
    });
    /**
     * GET_RECHARGE_HISTORY
     */
    builder.addCase(getPurchaseHistory.pending, state => {
      state.error = false;
      state.purchaseHistoryLoading = true;
    });
    builder.addCase(getPurchaseHistory.fulfilled, (state, action) => {
      const { usage_count, payment_history } = action.payload;

      state.purchaseHistoryLoading = false;
      state.purchaseHistory = payment_history;
      state.usageCount = usage_count;
    });
    builder.addCase(getPurchaseHistory.rejected, state => {
      userErrorHelper(state);
    });
    /**
     * GET_PACKAGE_SUBSCRIPTION_HISTORY
     */
    builder.addCase(getPackageSubscriptionHistory.pending, state => {
      state.error = false;
      state.packageSubscriptionHistoryLoading = true;
    });
    builder.addCase(
      getPackageSubscriptionHistory.fulfilled,
      (state, action) => {
        const { data } = action.payload;

        state.packageSubscriptionHistoryLoading = false;
        state.packageSubscriptionHistory = data;
      }
    );
    builder.addCase(getPackageSubscriptionHistory.rejected, state => {
      userErrorHelper(state);
    });
  },
});

export const { updateCurrentParams } = userSlice.actions;

export const userReducer = userSlice.reducer;
