/* eslint-disable @typescript-eslint/ban-types */
import { useCallback, useEffect, useState } from "react";

import { SortOrder } from "antd/lib/table/interface";
import _toInteger from "lodash/toInteger";
import qs, { ParseOptions, StringifyOptions } from "query-string";
import { useLocation, useHistory } from "react-router-dom";
import { useMount } from "react-use";

import { ItemModalEnum } from "@app/constants/route.constants";
import { SortEnum } from "@app/types/table.types";

const ARRAY_FORMAT: StringifyOptions["arrayFormat"] = "bracket";
const QUERY_OPTIONS: StringifyOptions = {
  arrayFormat: ARRAY_FORMAT,
  skipEmptyString: true,
};
const PARSE_OPTIONS: ParseOptions = {
  arrayFormat: ARRAY_FORMAT,
  parseBooleans: true,
};

/**
 * The reason for the generic type being wrapped in Partial,
 * is that we want to be able to update the search params one
 * parameter at a time. As we have no other way of forcing generics
 * passed to the hook to always have optional properties, we can wrap
 * it in Partial, and declare that we do not "care" if a property
 * in the generic type passed in contains a mandatory property.
 */
export type SearchParamDef<T = {}> = Partial<T> & {
  action?: ItemModalEnum;
  entryId?: string;
  entryType?: string;
  orderBy?: string;
  sort?: SortEnum;
  page?: number;
  pageSize?: number;
};

const useSearchParams = <T = {}>() => {
  const location = useLocation();
  const history = useHistory();
  const [currentSort, setCurrentSort] = useState<SortOrder>();
  const getCurrentSearch = useCallback(() => {
    const currentSearch = qs.parse(
      location.search,
      PARSE_OPTIONS
    ) as SearchParamDef as SearchParamDef<T>;
    currentSearch.page = _toInteger(currentSearch.page) || 1;
    currentSearch.pageSize = _toInteger(currentSearch.pageSize) || undefined;
    return currentSearch;
  }, [location.search]);

  const [search, setSearch] = useState<SearchParamDef<T>>(getCurrentSearch());

  const setSort = () => {
    if (search.sort) {
      setCurrentSort(search.sort === SortEnum.ASC ? "ascend" : "descend");
    } else {
      setCurrentSort(null);
    }
  };

  useMount(() => {
    setSort();
  });

  useEffect(() => {
    setSearch(getCurrentSearch());
  }, [getCurrentSearch]);

  /**
   * Get direction if order by key is present in search params
   */
  const getSortOrder = useCallback(
    (orderBy: string) => {
      if (search.orderBy === orderBy) {
        return currentSort;
      }

      return null;
    },
    [currentSort, search.orderBy]
  );

  /**
   * Clear search params with new params
   */
  const setSearchParams = useCallback(
    (filters: SearchParamDef<T>) => {
      history.replace({
        pathname: location.pathname,
        search: qs.stringify(filters, QUERY_OPTIONS),
      });
    },
    [history, location.pathname]
  );

  /**
   * Update existing search params with new params
   */
  const updateSearchParams = useCallback(
    (filters: SearchParamDef<T>) => {
      // Keep current search params
      const currentSearch = qs.parse(location.search, PARSE_OPTIONS);

      history.replace({
        pathname: location.pathname,
        search: qs.stringify(
          {
            ...currentSearch,
            ...filters,
          },
          QUERY_OPTIONS
        ),
        state: location.state,
      });
    },
    [history, location.pathname, location.search, location.state]
  );

  return {
    search,
    setSearchParams,
    updateSearchParams,
    getSortOrder,
    currentSort,
    setCurrentSort,
  };
};

export default useSearchParams;
