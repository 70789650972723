export enum FacilityPathsEnum {
  FACILITY = "/facility",
  FACILITY_LIST = "/facility/list",
  FACILITY_CREATE = "/facility/create",
  FACILITY_EDIT = "/facility/list",
  FACILITY_EXPENSE_HISTORY = "/facility/list",
  FACILITY_USAGE_HISTORY = "/facility/list",
  FACILITY_FEEDBACKS = "/facility/list",
  FACILITY_ENVIRONMENT_QUALITY = "/facility/list",
}
