import { DEFAULT_PAGE_SIZE } from "@app/constants/pagination.constants";
import useSearchParams from "@app/hooks/useSearchParams";
import { useAppDispatch, useAppSelector } from "@app/redux/store";

import { getAllUsers, updateCurrentParams } from "../redux/user.slice";
import { GetUserListDef, SearchQueryParamDef } from "../types/user.types";

const useUserList = () => {
  const dispatch = useAppDispatch();
  const { search, updateSearchParams, getSortOrder, setCurrentSort } =
    useSearchParams<SearchQueryParamDef>();

  const { userList, loading, paginationData } = useAppSelector(state => ({
    userList: state.user.userList,
    loading: state.user.loading,
    paginationData: state.user.paginationData,
  }));

  const parseSort = () => {
    if (search.sort) {
      return search.sort;
    }

    return undefined;
  };

  const parseOrderBy = () => {
    if (search.sort) {
      return search.orderBy === "name" ? "first_name" : search.orderBy;
    }

    return undefined;
  };

  const getInitialUserList = (reset?: boolean) => {
    const params = {
      searchQuery: !reset ? search.search ?? undefined : undefined,
      gender: !reset ? search.gender ?? undefined : undefined,
      orderBy: !reset ? parseOrderBy() : undefined,
      sort: !reset ? parseSort() : undefined,
      page: !reset ? search.page ?? undefined : 1,
      perPage: !reset
        ? search.pageSize ?? DEFAULT_PAGE_SIZE
        : DEFAULT_PAGE_SIZE,
    };

    dispatch(updateCurrentParams(params));
    dispatch(getAllUsers());
  };

  const getUpdatedUserList = (params: GetUserListDef) => {
    dispatch(updateCurrentParams(params));
    dispatch(getAllUsers());
  };

  return {
    getInitialUserList,
    getUpdatedUserList,
    userList,
    loading,
    paginationData,
    updateSearchParams,
    getSortOrder,
    setCurrentSort,
  };
};

export default useUserList;
