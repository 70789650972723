import dayjs from "dayjs";

import {
  DEFAULT_API_DATE_FORMAT,
  DEFAULT_DATE_RANGE,
} from "@app/constants/time.constants";
import useSearchParams from "@app/hooks/useSearchParams";
import { useAppDispatch, useAppSelector } from "@app/redux/store";

import {
  updateCurrentParams,
  getPurchaseHistory,
  getPackageSubscriptionHistory,
} from "../redux/user.slice";
import {
  GetUserHistoriesDef,
  HistorySearchQueryParamDef,
} from "../types/user.types";

const useUserHistories = () => {
  const dispatch = useAppDispatch();
  const { search, updateSearchParams, getSortOrder, setCurrentSort } =
    useSearchParams<HistorySearchQueryParamDef>();

  const {
    purchaseHistory,
    purchaseHistoryLoading,
    usageCount,
    packageSubscriptionHistory,
    packageSubscriptionHistoryLoading,
  } = useAppSelector(state => ({
    purchaseHistory: state.user.purchaseHistory,
    usageCount: state.user.usageCount,
    purchaseHistoryLoading: state.user.purchaseHistoryLoading,
    packageSubscriptionHistory: state.user.packageSubscriptionHistory,
    packageSubscriptionHistoryLoading:
      state.user.packageSubscriptionHistoryLoading,
  }));

  const parseSort = () => {
    if (search.sort) {
      return search.sort;
    }

    return undefined;
  };

  const parseOrderBy = () => {
    if (search.sort) {
      return search.orderBy === "purchaseDate" ? "created_at" : search.orderBy;
    }

    return undefined;
  };

  /**
   * Purchase History/List
   */

  const getInitialUserPurchaseList = (userId: GetUserHistoriesDef["id"]) => {
    const currentDate = dayjs().format(DEFAULT_API_DATE_FORMAT);

    const params = {
      id: userId,
      from: dayjs(currentDate)
        .subtract(
          Number(search.dateRange) - 1 || DEFAULT_DATE_RANGE - 1,
          "days"
        )
        .format(DEFAULT_API_DATE_FORMAT),
      to: currentDate,
      orderBy: parseOrderBy(),
      sort: parseSort(),
    };

    dispatch(updateCurrentParams(params));
    dispatch(getPurchaseHistory());
  };

  const getUpdatedUserPurchaseList = (params: GetUserHistoriesDef) => {
    dispatch(updateCurrentParams(params));
    dispatch(getPurchaseHistory());
  };

  /**
   * Package Subscription History/List
   */

  const getInitialPackageSubscriptionList = (
    userId: GetUserHistoriesDef["id"]
  ) => {
    const currentDate = dayjs().format(DEFAULT_API_DATE_FORMAT);

    const params = {
      id: userId,
      from: dayjs(currentDate)
        .subtract(
          Number(search.dateRange) - 1 || DEFAULT_DATE_RANGE - 1,
          "days"
        )
        .format(DEFAULT_API_DATE_FORMAT),
      to: currentDate,
      orderBy: parseOrderBy(),
      sort: parseSort(),
    };

    dispatch(updateCurrentParams(params));
    dispatch(getPackageSubscriptionHistory());
  };

  const getUpdatedPackageSubscriptionList = (params: GetUserHistoriesDef) => {
    dispatch(updateCurrentParams(params));
    dispatch(getPackageSubscriptionHistory());
  };

  return {
    getInitialUserPurchaseList,
    getUpdatedUserPurchaseList,
    purchaseHistory,
    usageCount,
    purchaseHistoryLoading,
    getInitialPackageSubscriptionList,
    getUpdatedPackageSubscriptionList,
    packageSubscriptionHistory,
    packageSubscriptionHistoryLoading,
    updateSearchParams,
    getSortOrder,
    setCurrentSort,
  };
};

export default useUserHistories;
