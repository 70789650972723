import { Redirect } from "react-router-dom";

import { flatten } from "@app/helpers/route.helper";
import NestedRouteWrapper from "@app/routes/NestedRouteWrapper";

import { RechargePathsEnum } from "../recharge";
import { RECHARGE_ROUTES } from "./recharge.routes";

const RechargeRoutes = () => {
  const routesWithComponents = flatten(RECHARGE_ROUTES);

  return (
    <NestedRouteWrapper
      routesWithComponents={routesWithComponents}
      additionalComponent={
        /* Redirect from blank route */
        <Redirect
          exact
          from={RechargePathsEnum.RECHARGE}
          to={RechargePathsEnum.AMOUNT_LIST}
        />
      }
    />
  );
};

export default RechargeRoutes;
