import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  InitialStateDef,
  GetAdminListDef,
  adminErrorHelper,
} from "@app/features/admin/admin";
import { RootState } from "@app/redux/root-reducer";

import {
  createAdmin,
  deleteAdmin,
  getAdminList,
  updateAdmin,
} from "../api/admin.api";
import { ADMIN_FEATURE_KEY } from "../constants/admin.keys";
import { AdminDef, CreateAdminDef, UpdateAdminDef } from "../types/admin.types";

const initialState: InitialStateDef = {
  adminList: [],
  error: false,
  loading: false,
  currentParams: {},
};

export const getAllAdmins = createAsyncThunk(
  `${ADMIN_FEATURE_KEY}/getAllAdmins`,
  async (values: undefined, { rejectWithValue, getState }) => {
    const { admin } = getState() as RootState;

    try {
      const response = await getAdminList(admin.currentParams);

      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const removeAdmin = createAsyncThunk(
  `${ADMIN_FEATURE_KEY}/removeAdmin`,
  async (id: AdminDef["id"], { rejectWithValue }) => {
    try {
      const response = await deleteAdmin({ id });

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const registerAdmin = createAsyncThunk(
  `${ADMIN_FEATURE_KEY}/createAdmin`,
  async (payload: CreateAdminDef, { rejectWithValue }) => {
    try {
      const response = await createAdmin(payload);

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const modifyAdmin = createAsyncThunk(
  `${ADMIN_FEATURE_KEY}/updateAdmin`,
  async (payload: UpdateAdminDef, { rejectWithValue }) => {
    try {
      const response = await updateAdmin(payload);

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const adminSlice = createSlice({
  name: ADMIN_FEATURE_KEY,
  initialState,
  reducers: {
    updateCurrentParams(state, action: PayloadAction<GetAdminListDef>) {
      state.currentParams = {
        ...state.currentParams,
        ...action.payload,
      };
    },
  },
  extraReducers: builder => {
    /**
     * GET_ADMIN_LIST
     */
    builder.addCase(getAllAdmins.pending, state => {
      state.error = false;
      state.loading = true;
    });
    builder.addCase(getAllAdmins.fulfilled, (state, action) => {
      const { data, meta } = action.payload;

      state.loading = false;
      state.adminList = data;
      state.paginationData = {
        currentPage: meta?.current_page,
        perPage: meta?.per_page,
        total: meta?.total,
      };
    });
    builder.addCase(getAllAdmins.rejected, state => {
      adminErrorHelper(state);
    });
    /**
     * DELETE ADMIN
     */
    builder.addCase(removeAdmin.pending, state => {
      state.error = false;
      state.loading = true;
    });
    builder.addCase(removeAdmin.fulfilled, state => {
      state.loading = false;
    });
    builder.addCase(removeAdmin.rejected, state => {
      adminErrorHelper(state);
    });
    /**
     * CREATE ADMIN
     */
    builder.addCase(registerAdmin.pending, state => {
      state.error = false;
      state.loading = true;
    });
    builder.addCase(registerAdmin.fulfilled, state => {
      state.loading = false;
    });
    builder.addCase(registerAdmin.rejected, state => {
      adminErrorHelper(state);
    });
    /**
     * UPDATE ADMIN
     */
    builder.addCase(modifyAdmin.pending, state => {
      state.error = false;
      state.loading = true;
    });
    builder.addCase(modifyAdmin.fulfilled, state => {
      state.loading = false;
    });
    builder.addCase(modifyAdmin.rejected, state => {
      adminErrorHelper(state);
    });
  },
});

export const { updateCurrentParams } = adminSlice.actions;

export const adminReducer = adminSlice.reducer;
