import ContentLayout from "@app/components/layouts/ContentLayout/ContentLayout";

import ManagerForm from "../../components/ManagerForm/ManagerForm";

const ManagerCreationScreen = () => {
  return (
    <ContentLayout header={{ title: "Create Manager" }}>
      <ManagerForm />
    </ContentLayout>
  );
};

export default ManagerCreationScreen;
