import { DEFAULT_PAGE_SIZE } from "@app/constants/pagination.constants";
import useSearchParams, { SearchParamDef } from "@app/hooks/useSearchParams";
import { useAppDispatch, useAppSelector } from "@app/redux/store";

import {
  updateCurrentParams,
  getAllManagers,
  GetManagerListDef,
  SearchQueryParamDef,
} from "../manager";

const useManagerList = () => {
  const dispatch = useAppDispatch();
  const { search, updateSearchParams } =
    useSearchParams<SearchParamDef<SearchQueryParamDef>>();

  const { managerList, loading, paginationData } = useAppSelector(state => ({
    managerList: state.manager.managerList,
    loading: state.manager.loading,
    paginationData: state.manager.paginationData,
  }));

  const parseSort = () => {
    if (!search.sort) {
      return undefined;
    }

    return search.sort;
  };

  const parseOrderBy = () => {
    if (!search.sort) {
      return undefined;
    }

    if (search.orderBy === "createdAt") {
      return "created_at";
    }

    if (search.orderBy === "name") {
      return "first_name";
    }

    return search.orderBy;
  };

  const getInitialManagerList = (reset?: boolean) => {
    const params = {
      searchQuery: !reset ? search.search ?? undefined : undefined,
      orderBy: !reset ? parseOrderBy() : undefined,
      sort: !reset ? parseSort() : undefined,
      page: !reset ? search.page ?? undefined : 1,
      perPage: !reset
        ? search.pageSize ?? DEFAULT_PAGE_SIZE
        : DEFAULT_PAGE_SIZE,
    };
    dispatch(updateCurrentParams(params));
    dispatch(getAllManagers());
  };

  const getUpdatedManagerList = (params: GetManagerListDef) => {
    dispatch(updateCurrentParams(params));
    dispatch(getAllManagers());
  };

  return {
    getInitialManagerList,
    getUpdatedManagerList,
    managerList,
    loading,
    paginationData,
    updateSearchParams,
  };
};

export default useManagerList;
