import { AxiosResponse } from "axios";

import { api } from "@app/api/api";

import { ManagerEndpointsEnum } from "../constants/manager.endpoints";
import {
  BlockManagerDef,
  CreateManagerDef,
  DeleteManagerDef,
  GetManagerDetailsDef,
  GetManagerListDef,
  GetManagerRechargeHistoryDef,
  UnblockManagerDef,
  UpdateManagerDef,
} from "../types/manager.types";

export const createManager = (
  data: CreateManagerDef
): Promise<AxiosResponse> => {
  return api.post(ManagerEndpointsEnum.CREATE_MANAGER, { ...data });
};

export const getManagerList = (
  data: GetManagerListDef
): Promise<AxiosResponse> => {
  return api.get(ManagerEndpointsEnum.GET_MANAGER_LIST, {
    params: {
      page: data.page,
      per_page: data.perPage,
      search_query: data.searchQuery,
      sort: data.sort,
      order_by: data.orderBy,
    },
  });
};

export const deleteManager = (
  data: DeleteManagerDef
): Promise<AxiosResponse> => {
  return api.delete(`${ManagerEndpointsEnum.DELETE_MANAGER}/${data.id}`);
};

export const getManagerDetails = (
  data: GetManagerDetailsDef
): Promise<AxiosResponse> => {
  return api.get(`${ManagerEndpointsEnum.GET_MANAGER_DETAILS}/${data.id}`);
};

export const updateManager = (
  data: UpdateManagerDef
): Promise<AxiosResponse> => {
  return api.patch(`${ManagerEndpointsEnum.UPDATE_MANAGER}/${data.id}`, {
    ...data.payload,
  });
};

export const getManagerRechargeHistory = (
  data: GetManagerRechargeHistoryDef
): Promise<AxiosResponse> => {
  return api.get(
    `${ManagerEndpointsEnum.MANAGER_RECHARGE_HISTORY}/${data.managerId}/history`,
    {
      params: {
        from: data.from,
        to: data.to,
        sort: data.sort,
        order_by: data.orderBy,
      },
    }
  );
};

export const blockManager = (data: BlockManagerDef): Promise<AxiosResponse> => {
  return api.post(`${ManagerEndpointsEnum.BLOCK_MANAGER}/${data.id}/block`, {
    blocked: true,
  });
};

export const unblockManager = (
  data: UnblockManagerDef
): Promise<AxiosResponse> => {
  return api.delete(
    `${ManagerEndpointsEnum.UNBLOCK_MANAGER}/${data.id}/block`,
    {
      data: {
        blocked: false,
      },
    }
  );
};
