import { memo } from "react";

import { useForm } from "antd/lib/form/Form";
import dayjs from "dayjs";
import { useMount } from "react-use";

import { RangePicker } from "@app/components/atoms/DateTimePicker/DateTimePicker";
import PageFilter, {
  FilterItem,
} from "@app/components/molecules/PageFilter/PageFilter";
import {
  DEFAULT_API_DATE_FORMAT,
  DEFAULT_DATE_FORMAT,
  DEFAULT_DATE_RANGE,
} from "@app/constants/time.constants";

import useManagerRechargeHistory from "../../hooks/useManagerRechargeHistory";

export interface RechargeHistoryDef {
  dateRange?: [dayjs.Dayjs, dayjs.Dayjs];
}

export interface RechargeHistoryProps {
  managerId: number;
}

const RechargeHistory = ({ managerId }: RechargeHistoryProps) => {
  const [form] = useForm();
  const { loading, updateSearchParams, getUpdatedRechargeHistoryList, search } =
    useManagerRechargeHistory();

  const currentDate = dayjs();

  useMount(() => {
    if (!search.dateRange) {
      const from = dayjs(currentDate).subtract(DEFAULT_DATE_RANGE - 1, "days");
      const to = currentDate;

      form.setFieldsValue({
        dateRange: [from, to],
      });

      updateSearchParams({
        dateRange: [
          from.format(DEFAULT_API_DATE_FORMAT),
          to.format(DEFAULT_API_DATE_FORMAT),
        ],
      });
    }
  });

  const searchUsage = () => {
    const dateRange = form.getFieldValue("dateRange") || undefined;

    // Resets search params
    updateSearchParams({
      dateRange,
      page: 1,
      orderBy: undefined,
      sort: undefined,
    });

    getUpdatedRechargeHistoryList({
      from: dateRange?.[0]
        ? dayjs(dateRange[0]).format(DEFAULT_API_DATE_FORMAT)
        : undefined,
      to: dateRange?.[1]
        ? dayjs(dateRange[1]).format(DEFAULT_API_DATE_FORMAT)
        : undefined,
      managerId: String(managerId),
      orderBy: undefined,
      sort: undefined,
    });
  };

  const handleSearch = async () => {
    if (!loading) {
      searchUsage();
    }
  };

  return (
    <PageFilter<RechargeHistoryDef>
      parseDates
      form={form}
      columns={3}
      submitOnChange
      onSubmit={handleSearch}
    >
      <FilterItem name="dateRange">
        <RangePicker format={DEFAULT_DATE_FORMAT} inputReadOnly />
      </FilterItem>
    </PageFilter>
  );
};

export default memo(RechargeHistory);
