import { AxiosResponse } from "axios";

import { api } from "@app/api/api";

import { DashboardEndpointsEnum } from "../constants/dashboard.endpoints";
import {
  GetBusinessOverviewDef,
  GetEnvironmentNotificationsDef,
} from "../types/dashboard.types";

export const getBusinessOverview = (
  payload: GetBusinessOverviewDef
): Promise<AxiosResponse> => {
  return api.get(DashboardEndpointsEnum.GET_BUSINESS_OVERVIEW, {
    params: { ...payload },
  });
};

export const getTotalIncomeBySubscriptions = (
  payload: GetBusinessOverviewDef
): Promise<AxiosResponse> => {
  return api.get(DashboardEndpointsEnum.GET_TOTAL_INCOME_BY_SUBSCRIPTION, {
    params: { ...payload },
  });
};

export const getEnvironmentNotifications = (
  payload: GetEnvironmentNotificationsDef
): Promise<AxiosResponse> => {
  return api.get(DashboardEndpointsEnum.GET_ENVIRONMENT_NOTIFICATIONS, {
    params: { ...payload },
  });
};
