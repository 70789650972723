import { memo } from "react";

import { useForm } from "antd/lib/form/Form";
import Search from "antd/lib/input/Search";

import PageFilter, {
  FilterItem,
} from "@app/components/molecules/PageFilter/PageFilter";

import useManagerList from "../../hooks/useManagerList";
import styles from "./ManagersFilter.module.scss";

export interface ManagersFilterProps {
  search?: string;
}

const ManagersFilter = () => {
  const [form] = useForm();
  const { loading, updateSearchParams, getUpdatedManagerList } =
    useManagerList();

  const searchManagers = () => {
    updateSearchParams({ page: 1, orderBy: undefined, sort: undefined }); // Resets search params
    getUpdatedManagerList({
      searchQuery: form.getFieldValue("search") || undefined,
      page: 1,
      orderBy: undefined,
      sort: undefined,
    });
  };

  const handleSearch = async () => {
    form.submit();

    if (!loading) {
      searchManagers();
    }
  };

  return (
    <PageFilter<ManagersFilterProps> parseNumbers={["search"]} form={form}>
      <FilterItem className={styles.filterLabel} name="search">
        <Search
          placeholder="Search by name or phone"
          enterButton
          onSearch={handleSearch}
        />
      </FilterItem>
    </PageFilter>
  );
};

export default memo(ManagersFilter);
