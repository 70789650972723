import {
  DesktopOutlined,
  FileAddOutlined,
  UnorderedListOutlined,
} from "@ant-design/icons";

import { AuthRoleEnum } from "@app/features/auth/auth";
import { RouteItemDef } from "@app/types/route.types";

import { AdminPathsEnum } from "../constants/admin.paths";
import AdminCreateScreen from "../screens/AdminCreateScreen/AdminCreateScreen";
import AdminDetailsScreen from "../screens/AdminDetailsScreen/AdminDetailsScreen";
import AdminListScreen from "../screens/AdminListScreen/AdminListScreen";
import AdminRoutes from "./AdminRoutes";

const ADMIN_SCREEN: RouteItemDef = {
  id: "admin",
  path: AdminPathsEnum.ADMIN,
  navigationTitle: "Admin",
  component: AdminRoutes,
  sidebarIcon: DesktopOutlined,
  role: AuthRoleEnum.SUPER_ADMIN,
  nestedRoutes: [
    /* Admin list */
    {
      id: "adminList",
      path: AdminPathsEnum.ADMIN_LIST,
      navigationTitle: "Admin List",
      component: AdminListScreen,
      sidebarIcon: UnorderedListOutlined,
    },
    /* Admin Create */
    {
      id: "adminCreate",
      path: AdminPathsEnum.ADMIN_CREATE,
      navigationTitle: "Create Admin",
      component: AdminCreateScreen,
      sidebarIcon: FileAddOutlined,
    },
    /* Admin Details */
    {
      id: "adminDetails",
      path: `${AdminPathsEnum.ADMIN_DETAILS}/:id`,
      navigationTitle: "Admin Details",
      component: AdminDetailsScreen,
      hideInNavigation: true,
    },
  ],
};

export const ADMIN_ROUTES = [ADMIN_SCREEN];
