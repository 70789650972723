import { useEffect, useState } from "react";

import { Switch, Table, Tooltip, message } from "antd";
import {
  FilterValue,
  SorterResult,
  TablePaginationConfig,
} from "antd/lib/table/interface";
import dayjs from "dayjs";
import { Link, useHistory, useLocation } from "react-router-dom";
import { usePrevious } from "react-use";

import Button from "@app/components/atoms/Button/Button";
import TableView, {
  TableViewProps,
} from "@app/components/molecules/TableView/TableView";
import { ActivityStatusEnum } from "@app/constants/status.constants";
import {
  ToggleActionTypeEnum,
  ViewActionTypeEnum,
} from "@app/constants/table.constants";
import { DEFAULT_DATE_FORMAT } from "@app/constants/time.constants";
import useSearchParams, { SearchParamDef } from "@app/hooks/useSearchParams";
import { useAppDispatch } from "@app/redux/store";
import { SortEnum } from "@app/types/table.types";

import useManagerList from "../../hooks/useManagerList";
import {
  ManagerDef,
  ManagerPathsEnum,
  SearchQueryParamDef,
  blockAManager,
  unblockAManager,
} from "../../manager";
import ManagersFilter from "../ManagersFilter/ManagersFilter";
import styles from "./ManagersTable.module.scss";

interface ManagerTableProps extends TableViewProps<ManagerDef> {
  onAdd?: () => void;
}

const ManagerTable = ({ onAdd, ...props }: ManagerTableProps) => {
  const [enabledManagers, setEnabledManagers] = useState<ManagerDef["id"][]>(
    []
  );
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { getSortOrder, setCurrentSort } =
    useSearchParams<SearchParamDef<SearchQueryParamDef>>();
  const {
    paginationData,
    loading,
    getInitialManagerList,
    getUpdatedManagerList,
    managerList,
  } = useManagerList();
  const location = useLocation();
  const prevLocation = usePrevious(location);

  useEffect(() => {
    setEnabledManagers(
      managerList
        .filter(manager => !manager?.blocked)
        .map(manager => manager?.id)
    );
  }, [managerList]);

  useEffect(() => {
    // Fetch Manager on initial load
    if (prevLocation?.search === undefined) {
      getInitialManagerList();
    }

    // Fetch data on navigation reset
    if (prevLocation?.search && location.search === "") {
      getInitialManagerList(true);
    }
  }, [getInitialManagerList, location.search, prevLocation?.search]);

  const parseSort = (
    sorter: SorterResult<ManagerDef> | SorterResult<ManagerDef>[]
  ) => {
    if (!Array.isArray(sorter)) {
      const { order } = sorter;

      if (order) {
        return order === "ascend" ? SortEnum.ASC : SortEnum.DESC;
      }
    }

    return undefined;
  };

  const parseOrderBy = (
    sorter: SorterResult<ManagerDef> | SorterResult<ManagerDef>[]
  ) => {
    if (!Array.isArray(sorter) && sorter.order) {
      if (sorter.columnKey === "createdAt") {
        return "created_at";
      }

      if (sorter.columnKey === "name") {
        return "first_name";
      }

      return sorter.columnKey;
    }

    return undefined;
  };

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<ManagerDef> | SorterResult<ManagerDef>[]
  ) => {
    if (!loading) {
      getUpdatedManagerList({
        page: pagination.current,
        perPage: pagination.pageSize,
        sort: parseSort(sorter),
        orderBy: parseOrderBy(sorter) as string,
      });
    }

    if (!Array.isArray(sorter)) {
      setCurrentSort(sorter.order);
    }
  };

  const handleView = (manager: ManagerDef) => {
    history.push(
      `${ManagerPathsEnum.MANAGER_RECHARGE_HISTORY}/${manager.id}/manager-recharge-history`
    );
  };

  const blockUnblockManager = async (managerId: ManagerDef["id"]) => {
    if (!enabledManagers.includes(managerId)) {
      const response = await dispatch(
        unblockAManager({ id: Number(managerId) })
      );

      if (unblockAManager.fulfilled.match(response)) {
        setEnabledManagers(prevEnabledManagers => [
          ...prevEnabledManagers,
          managerId,
        ]);
        message.success("Manager unblocked successfully.");
      } else {
        message.error("Manager unblocking failed");
      }
    } else {
      const response = await dispatch(blockAManager({ id: Number(managerId) }));

      if (blockAManager.fulfilled.match(response)) {
        setEnabledManagers(prevEnabledManagers =>
          prevEnabledManagers.filter(manager => manager !== managerId)
        );
        message.success("Manager blocked successfully.");
      } else {
        message.error("Manager blocking failed");
      }
    }
  };

  const renderEnableDisableSwitch = (record: ManagerDef) => {
    return (
      <Tooltip
        title={
          !enabledManagers.includes(record?.id)
            ? "Enable manager"
            : "Disable manager"
        }
      >
        <Switch
          defaultChecked={!record.blocked}
          onChange={() => blockUnblockManager(record?.id)}
          checkedChildren={`${ActivityStatusEnum.ENABLE}d`}
          unCheckedChildren={`${ActivityStatusEnum.DISABLE}d`}
        />
      </Tooltip>
    );
  };

  const handleEdit = (manager: ManagerDef) => {
    history.push(`${ManagerPathsEnum.EDIT_MANAGER}/${manager.id}`);
  };

  return (
    <TableView<ManagerDef>
      dataSource={managerList}
      loading={loading}
      actionTitle="Action"
      pagination={{
        current: paginationData?.currentPage,
        pageSize: paginationData?.perPage,
        total: paginationData?.total,
        showSizeChanger: true,
        pageSizeOptions: ["6", "8", "10", "15", "25"],
      }}
      title={() => (
        <div className={styles.managerTableTitle}>
          <ManagersFilter />

          <Link to={ManagerPathsEnum.CREATE_MANAGER}>
            <Button type="primary" onClick={onAdd}>
              Create a new Manager
            </Button>
          </Link>
        </div>
      )}
      onChange={handleTableChange}
      onView={handleView}
      viewActionType={ViewActionTypeEnum.RECHARGE_HISTORY}
      onEdit={handleEdit}
      toggleActionType={ToggleActionTypeEnum.ENABLE_DISABLE}
      extraActions={record => renderEnableDisableSwitch(record)}
      {...props}
    >
      <Table.Column
        title="Name"
        key="name"
        dataIndex="name"
        render={(name: undefined, record: ManagerDef) => {
          return `${record.first_name} ${record.last_name}`;
        }}
        sorter
        sortOrder={getSortOrder("name")}
      />
      <Table.Column
        title="Email"
        key="email"
        dataIndex="email"
        render={(email: string) => email}
      />
      <Table.Column
        title="Phone"
        key="phone"
        dataIndex="phone_number"
        render={(phone: string) => phone}
      />
      <Table.Column
        title="Card ID"
        key="cardId"
        dataIndex="card_id"
        render={(cardId: string) => cardId}
      />
      <Table.Column
        title="Created At"
        key="createdAt"
        dataIndex="created_at"
        render={(createdAt: string) =>
          dayjs(createdAt).format(DEFAULT_DATE_FORMAT)
        }
        sorter
        sortOrder={getSortOrder("createdAt")}
      />
    </TableView>
  );
};

export default ManagerTable;
