import { useEffect } from "react";

import { ClearOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { message, Modal, Table, TablePaginationConfig } from "antd";
import { FilterValue, SorterResult } from "antd/lib/table/interface";
import dayjs from "dayjs";
import { useHistory, useLocation } from "react-router-dom";
import { usePrevious, useUnmount } from "react-use";

import Button from "@app/components/atoms/Button/Button";
import TableView, {
  TableViewProps,
} from "@app/components/molecules/TableView/TableView";
import { ActivityStatusEnum } from "@app/constants/status.constants";
import useSearchParams, { SearchParamDef } from "@app/hooks/useSearchParams";
import { useAppDispatch } from "@app/redux/store";
import { SortEnum } from "@app/types/table.types";

import { OdourQuality } from "../../constants/facility.constants";
import {
  FacilityPathsEnum,
  resetPaginationAndParams,
  clearAllEnvironmentQuality,
} from "../../facility";
import useFacilityList from "../../hooks/useFacilityList";
import {
  FacilityDef,
  LatestEnvironmentQualityDef,
} from "../../types/facility.types";
import FacilityFilter from "../FacilityFilter/FacilityFilter";
import styles from "./FacilitiesTable.module.scss";

type FacilitiesTableProps = TableViewProps<FacilityDef>;

const { confirm } = Modal;

const FacilitiesTable = ({ ...props }: FacilitiesTableProps) => {
  const location = useLocation();
  const history = useHistory();
  const prevLocation = usePrevious(location);
  const { getSortOrder, setCurrentSort } = useSearchParams<SearchParamDef>();
  const {
    facilityList,
    paginationData,
    loading,
    getInitialFacilityList,
    getUpdatedFacilityList,
  } = useFacilityList();
  const dispatch = useAppDispatch();

  useEffect(() => {
    // Fetch Facility on initial load
    if (prevLocation?.search === undefined) {
      getInitialFacilityList();
    }

    // Fetch data on navigation reset
    if (prevLocation?.search && location.search === "") {
      getInitialFacilityList(true);
    }
  }, [getInitialFacilityList, location.search, prevLocation?.search]);

  useUnmount(() => {
    dispatch(resetPaginationAndParams());
  });

  const parseSort = (
    sorter: SorterResult<FacilityDef> | SorterResult<FacilityDef>[]
  ) => {
    if (!Array.isArray(sorter)) {
      const { order } = sorter;

      if (order) {
        return order === "ascend" ? SortEnum.ASC : SortEnum.DESC;
      }
    }

    return undefined;
  };

  const parseOrderBy = (
    sorter: SorterResult<FacilityDef> | SorterResult<FacilityDef>[]
  ) => {
    if (!Array.isArray(sorter)) {
      const { order } = sorter;

      if (order) {
        return sorter.columnKey === "phone" ? "phone_number" : sorter.columnKey;
      }
    }

    return undefined;
  };

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<FacilityDef> | SorterResult<FacilityDef>[]
  ) => {
    if (!loading) {
      getUpdatedFacilityList({
        page: pagination.current,
        perPage: pagination.pageSize,
        sort: parseSort(sorter) ?? SortEnum.DESC,
        orderBy: (parseOrderBy(sorter) as string) ?? "is_active",
      });
    }

    if (!Array.isArray(sorter)) {
      setCurrentSort(sorter.order);
    }
  };

  const handleCreate = () => {
    history.push(FacilityPathsEnum.FACILITY_CREATE);
  };

  const handleView = (facility: FacilityDef) => {
    history.push(`${FacilityPathsEnum.FACILITY_EDIT}/${facility.id}`);
  };

  const clearAllEnvironmentHistory = async () => {
    const response = await dispatch(clearAllEnvironmentQuality());

    if (clearAllEnvironmentQuality.fulfilled.match(response)) {
      message.success("All environment quality cleared successfully");
    } else {
      message.error("Environment quality clearing failed");
    }
  };

  const handleClear = () => {
    confirm({
      title:
        "Are you sure want to clear all the environment quality history prior last 7 days?",
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        return clearAllEnvironmentHistory();
      },
    });
  };

  const odourQualityRenderer = (
    latestEnvironmentQuality: LatestEnvironmentQualityDef
  ): string => {
    if (latestEnvironmentQuality === null) {
      return "N/A";
    }
    const numOdourQuality = Number(latestEnvironmentQuality?.air_quality);
    switch (latestEnvironmentQuality !== null) {
      case numOdourQuality >= 0 && numOdourQuality <= 5:
        return OdourQuality.GOOD;
      case numOdourQuality >= 6 && numOdourQuality <= 20:
        return OdourQuality.MODERATE;
      case numOdourQuality >= 21 && numOdourQuality <= 50:
        return OdourQuality.POOR;
      case numOdourQuality >= 51 && numOdourQuality <= 100:
        return OdourQuality.BAD;
      case numOdourQuality > 100:
        return OdourQuality.SEVERE;
      default:
        return "N/A";
    }
  };

  const returnSensorStatus = (
    latestEnvironmentQuality: LatestEnvironmentQualityDef
  ): string => {
    if (latestEnvironmentQuality === null) {
      return ActivityStatusEnum.INACTIVE;
    }
    const now = dayjs();
    const differenceSeconds = now.diff(
      dayjs(latestEnvironmentQuality?.created_at),
      "second"
    );
    const status =
      differenceSeconds > 15 * 60 || latestEnvironmentQuality === null
        ? ActivityStatusEnum.INACTIVE
        : ActivityStatusEnum.ACTIVE;
    return status;
  };

  const renderConditionalStatus = (dataNode: string) =>
    dataNode === ActivityStatusEnum.ACTIVE.toLowerCase()
      ? ActivityStatusEnum.ACTIVE
      : ActivityStatusEnum.INACTIVE;

  return (
    <TableView<FacilityDef>
      dataSource={facilityList}
      loading={loading}
      actionTitle="Action"
      pagination={{
        current: paginationData?.currentPage,
        pageSize: paginationData?.perPage,
        total: paginationData?.total,
        showSizeChanger: true,
        pageSizeOptions: ["6", "8", "10", "15", "25"],
      }}
      title={() => (
        <div className={styles.facilityTableTitle}>
          <FacilityFilter />

          <div>
            <Button
              className="clearEnvironmentHistory"
              type="primary"
              danger
              onClick={handleClear}
              icon={<ClearOutlined />}
            >
              Clear all environment quality history
            </Button>

            <Button type="primary" onClick={handleCreate}>
              Create a new Facility
            </Button>
          </div>
        </div>
      )}
      onChange={handleTableChange}
      onView={handleView}
      {...props}
    >
      <Table.Column
        title="Name"
        key="name"
        dataIndex="name"
        render={(name: string) => name}
        sorter
        sortOrder={getSortOrder("name")}
      />
      <Table.Column
        title="Daily Income"
        key="income"
        dataIndex="daily_income"
        render={(dailyIncome: number) => dailyIncome}
      />
      <Table.Column
        title="Odour Quality"
        key="odourQuality"
        dataIndex="latest_env_quality"
        render={(latestEnvironmentQuality: LatestEnvironmentQualityDef) =>
          odourQualityRenderer(latestEnvironmentQuality)
        }
      />
      <Table.Column
        title="Sensor Status"
        key="sensorStatus"
        dataIndex="latest_env_quality"
        render={(latestEnvironmentQuality: LatestEnvironmentQualityDef) =>
          returnSensorStatus(latestEnvironmentQuality)
        }
      />
      <Table.Column
        title="Rating"
        key="rating"
        dataIndex="rating"
        sorter
        sortOrder={getSortOrder("rating")}
        render={(rating: string) => rating}
      />
      <Table.Column
        title="Facility Status"
        key="status"
        dataIndex="status"
        sorter
        sortOrder={getSortOrder("is_active")}
        render={(status: string) => renderConditionalStatus(status)}
      />
    </TableView>
  );
};

export default FacilitiesTable;
