export enum ManagerEndpointsEnum {
  CREATE_MANAGER = "/admin/managers",
  GET_MANAGER_LIST = "/admin/managers",
  DELETE_MANAGER = "/admin/managers",
  BLOCK_MANAGER = "admin/users",
  UNBLOCK_MANAGER = "admin/users",
  GET_MANAGER_DETAILS = "/admin/managers",
  UPDATE_MANAGER = "/admin/managers",
  MANAGER_RECHARGE_HISTORY = "/admin/managers",
}
