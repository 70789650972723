import {
  FileAddOutlined,
  SettingOutlined,
  UnorderedListOutlined,
} from "@ant-design/icons";

import { AuthRoleEnum } from "@app/features/auth/auth";
import { RouteItemDef } from "@app/types/route.types";

import { ManagerPathsEnum } from "../constants/manager.paths";
import ManagerCreationScreen from "../screens/ManagerCreationScreen/ManagerCreationScreen";
import ManagerEditScreen from "../screens/ManagerEditScreen/ManagerEditScreen";
import ManagerListScreen from "../screens/ManagerListScreen/ManagerListScreen";
import ManagerRechargeHistoryScreen from "../screens/ManagerRechargeHistoryScreen/ManagerRechargeHistoryScreen";
import ManagerRoutes from "./ManagerRoutes";

const MANAGER_SCREEN: RouteItemDef = {
  id: "manager",
  path: ManagerPathsEnum.MANAGER,
  navigationTitle: "Manager",
  component: ManagerRoutes,
  sidebarIcon: SettingOutlined,
  role: AuthRoleEnum.SUPER_ADMIN,
  nestedRoutes: [
    /* Manager list */
    {
      id: "managerList",
      path: ManagerPathsEnum.MANAGER_LIST,
      navigationTitle: "Manager List",
      component: ManagerListScreen,
      sidebarIcon: UnorderedListOutlined,
    },
    /* Create manager */
    {
      id: "createManager",
      path: ManagerPathsEnum.CREATE_MANAGER,
      navigationTitle: "Create Manager",
      component: ManagerCreationScreen,
      sidebarIcon: FileAddOutlined,
    },
    /* Create manager */
    {
      id: "editManager",
      path: `${ManagerPathsEnum.EDIT_MANAGER}/:id`,
      component: ManagerEditScreen,
      hideInNavigation: true,
    },
    /* Manager Recharge History */
    {
      id: "managerRechargeHistory",
      path: `${ManagerPathsEnum.MANAGER_RECHARGE_HISTORY}/:id/manager-recharge-history`,
      component: ManagerRechargeHistoryScreen,
      hideInNavigation: true,
    },
  ],
};

export const MANAGER_ROUTES = [MANAGER_SCREEN];
