import { memo, useEffect } from "react";

import {
  CheckCircleOutlined,
  MinusCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import {
  Col,
  Input,
  message,
  Popconfirm,
  Row,
  Select,
  Space,
  Tooltip,
} from "antd";
import { useMount } from "react-use";

import Button from "@app/components/atoms/Button/Button";
import Form, { Item, List, useForm } from "@app/components/atoms/Form/Form";
import { GenderEnum } from "@app/constants/gender.constants";
import { DEFAULT_NUMBER_INPUT_PATTERN } from "@app/constants/input.constants";
import { UserTypeEnum } from "@app/constants/user.constants";
import { renderGender } from "@app/helpers/gender.helper";
import { renderUserType } from "@app/helpers/user.helper";
import { useAppDispatch, useAppSelector } from "@app/redux/store";
import { HasMessage } from "@app/types/api.types";

import {
  addService,
  getAllServices,
  removeService,
  getAFacility,
} from "../../facility";
import styles from "./FacilityServiceForm.module.scss";

type ServiceOfferedDef = {
  section: Omit<GenderEnum, GenderEnum.OTHER>;
  serviceId: number;
  userType: UserTypeEnum;
  price: number;
  canNotBeUpdated?: boolean;
};

const { Option } = Select;

const FacilityServiceForm = () => {
  const [form] = useForm();
  const dispatch = useAppDispatch();
  const { serviceList, services, facilityId, loading } = useAppSelector(
    state => ({
      serviceList: state.facility.serviceList,
      services: state.facility.facilityDetails?.services,
      facilityId: state.facility.facilityDetails?.id,
      loading: state.facility.loading,
    })
  );

  useMount(async () => {
    const response = await dispatch(getAllServices());

    if (!getAllServices.fulfilled.match(response)) {
      message.error("Service fetching failed");
    }
  });

  useEffect(() => {
    form.setFieldsValue({
      servicesOffered: services?.map(service => ({
        serviceId: service?.service?.id,
        price: String(service?.price),
        section: service.section,
        userType: service.user_type,
        canNotBeUpdated: true,
      })),
    });
  }, [form, services]);

  const addServiceToFacility = async (service: ServiceOfferedDef) => {
    const response = await dispatch(
      addService({
        facility_id: Number(facilityId),
        service_id: service.serviceId,
        price: Number(service.price),
        section: service.section,
        user_type: service.userType,
      })
    );

    if (addService.fulfilled.match(response)) {
      message.success("Service added successfully");
    } else {
      message.error((response.payload as HasMessage)?.message);
    }
  };

  const handleServiceSave = (service: ServiceOfferedDef) => {
    if (
      !service?.serviceId ||
      !service?.price ||
      !service.section ||
      !service.userType
    ) {
      return;
    }

    addServiceToFacility(service);
  };

  const handleRemoveService = async (
    serviceId: ServiceOfferedDef["serviceId"]
  ) => {
    if (!serviceId) {
      return;
    }

    const response = await dispatch(
      removeService({ facilityServiceId: serviceId })
    );

    if (removeService.fulfilled.match(response)) {
      message.success("Service removed successfully");
      dispatch(getAFacility({ id: Number(facilityId) })); // Fetch updated facility info
    } else {
      message.error("Service remove failed");
    }
  };

  const handleSubmit = () => {
    const servicesOffered = form.getFieldValue("servicesOffered");

    handleServiceSave(servicesOffered?.slice(-1)?.[0]); // Save the latest service added
  };

  return (
    <Row justify="space-between">
      <Col xs={8}>
        <Form form={form} onFinish={handleSubmit}>
          <Item label="Services Offered with Unit Cost">
            <List name="servicesOffered">
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, ...restField }) => (
                    <Space key={key} align="baseline">
                      <Item
                        {...restField}
                        name={[name, "section"]}
                        rules={[
                          {
                            required: true,
                            message: "Please select a section!",
                          },
                        ]}
                        className={styles.serviceDropdown}
                        validateFirst
                      >
                        <Select
                          disabled={
                            form.getFieldValue("servicesOffered")?.[name]
                              ?.canNotBeUpdated
                          }
                          placeholder="Select a section"
                        >
                          <Option value={GenderEnum.MALE}>
                            {renderGender(GenderEnum.MALE)}
                          </Option>
                          <Option value={GenderEnum.FEMALE}>
                            {renderGender(GenderEnum.FEMALE)}
                          </Option>
                        </Select>
                      </Item>
                      <Item
                        {...restField}
                        name={[name, "serviceId"]}
                        rules={[
                          {
                            required: true,
                            message: "Please select a service!",
                          },
                        ]}
                        className={styles.serviceDropdown}
                        validateFirst
                      >
                        <Select
                          disabled={
                            form.getFieldValue("servicesOffered")?.[name]
                              ?.canNotBeUpdated
                          }
                          placeholder="Select a service"
                        >
                          {serviceList?.map(
                            service =>
                              !service.hidden && (
                                <Option key={service.id} value={service.id}>
                                  {service.title_en}
                                </Option>
                              )
                          )}
                        </Select>
                      </Item>
                      <Item
                        {...restField}
                        name={[name, "userType"]}
                        rules={[
                          {
                            required: true,
                            message: "Please select a user type!",
                          },
                        ]}
                        className={styles.serviceDropdown}
                        validateFirst
                      >
                        <Select
                          disabled={
                            form.getFieldValue("servicesOffered")?.[name]
                              ?.canNotBeUpdated
                          }
                          placeholder="Select a user type"
                        >
                          <Option value={UserTypeEnum.USER}>
                            {renderUserType(UserTypeEnum.USER)}
                          </Option>
                          <Option value={UserTypeEnum.MANAGER}>
                            {renderUserType(UserTypeEnum.MANAGER)}
                          </Option>
                        </Select>
                      </Item>
                      <Item
                        {...restField}
                        name={[name, "price"]}
                        rules={[
                          { required: true, message: "Please enter a cost!" },
                          {
                            pattern: DEFAULT_NUMBER_INPUT_PATTERN,
                            message:
                              "Input can't exceed 12 digits with 2 decimal point max!",
                          },
                        ]}
                        className={styles.serviceDropdown}
                      >
                        <Input
                          disabled={
                            form.getFieldValue("servicesOffered")?.[name]
                              ?.canNotBeUpdated
                          }
                          placeholder="Cost of the service"
                          type="number"
                        />
                      </Item>

                      <Button
                        onClick={form.submit}
                        type="primary"
                        disabled={
                          loading ||
                          form.getFieldValue("servicesOffered")?.[name]
                            ?.canNotBeUpdated
                        }
                        shape="circle"
                        icon={<CheckCircleOutlined />}
                      />

                      <Popconfirm
                        title="Remove the service?"
                        okText="Yes"
                        cancelText="Cancel"
                        onConfirm={() => {
                          remove(name);

                          if (services?.[name]?.id) {
                            handleRemoveService(services?.[name]?.id);
                          }
                        }}
                        placement="left"
                      >
                        <Tooltip title="Remove">
                          <Button
                            disabled={loading}
                            danger
                            shape="circle"
                            icon={<MinusCircleOutlined />}
                          />
                        </Tooltip>
                      </Popconfirm>
                    </Space>
                  ))}

                  <Item>
                    <Button
                      disabled={
                        loading || fields.length - Number(services?.length) >= 1 // Only allows single service addition at a time
                      }
                      type="dashed"
                      onClick={() => add()}
                      block
                      icon={<PlusOutlined />}
                    >
                      Add a new service
                    </Button>
                  </Item>
                </>
              )}
            </List>
          </Item>
        </Form>
      </Col>
    </Row>
  );
};

export default memo(FacilityServiceForm);
