import { AxiosResponse } from "axios";

import { api } from "@app/api/api";

import { AuthEndpointsEnum } from "../constants/auth.endpoints";
import { LoginRequestDef, UpdateProfileDef } from "../types/auth.types";

export const authLogin = (data: LoginRequestDef): Promise<AxiosResponse> => {
  return api.post(AuthEndpointsEnum.LOGIN, data);
};

export const authLogout = (): Promise<AxiosResponse> => {
  return api.delete(AuthEndpointsEnum.LOGOUT);
};

export const updateProfile = (
  data: UpdateProfileDef
): Promise<AxiosResponse> => {
  // eslint-disable-next-line camelcase
  const { id, name, password, phone_number } = data;

  return api.patch(`${AuthEndpointsEnum.UPDATE_PROFILE}/${id}`, {
    name,
    phone_number,
    password,
  });
};
