import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { RootState } from "@app/redux/root-reducer";

import {
  blockManager,
  createManager,
  deleteManager,
  getManagerDetails,
  getManagerList,
  getManagerRechargeHistory,
  unblockManager,
  updateManager,
} from "../api/manager.api";
import {
  CreateManagerDef,
  MANAGER_FEATURE_KEY,
  InitialStateDef,
  managerErrorHelper,
  GetManagerListDef,
  DeleteManagerDef,
  GetManagerDetailsDef,
  UpdateManagerDef,
  GetManagerRechargeHistoryDef,
  BlockManagerDef,
  UnblockManagerDef,
} from "../manager";

const initialState: InitialStateDef = {
  error: false,
  loading: false,
  managerList: [],
  currentParams: {},
  rechargeHistoryList: [],
};

export const registerManager = createAsyncThunk(
  `${MANAGER_FEATURE_KEY}/createManager`,
  async (payload: CreateManagerDef, { rejectWithValue }) => {
    try {
      const response = await createManager(payload);

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getAllManagers = createAsyncThunk(
  `${MANAGER_FEATURE_KEY}/getAllManagers`,
  async (payload: undefined, { rejectWithValue, getState }) => {
    const { manager } = getState() as RootState;

    try {
      const response = await getManagerList(manager.currentParams);

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const removeManager = createAsyncThunk(
  `${MANAGER_FEATURE_KEY}/removeManager`,
  async (payload: DeleteManagerDef, { rejectWithValue }) => {
    try {
      const response = await deleteManager(payload);

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const blockAManager = createAsyncThunk(
  `${MANAGER_FEATURE_KEY}/blockAManager`,
  async (payload: BlockManagerDef, { rejectWithValue }) => {
    try {
      const response = await blockManager(payload);

      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const unblockAManager = createAsyncThunk(
  `${MANAGER_FEATURE_KEY}/unblockAManager`,
  async (payload: UnblockManagerDef, { rejectWithValue }) => {
    try {
      const response = await unblockManager(payload);

      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getAManager = createAsyncThunk(
  `${MANAGER_FEATURE_KEY}/getAManager`,
  async (payload: GetManagerDetailsDef, { rejectWithValue }) => {
    try {
      const response = await getManagerDetails(payload);

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const modifyManager = createAsyncThunk(
  `${MANAGER_FEATURE_KEY}/modifyManager`,
  async (payload: UpdateManagerDef, { rejectWithValue }) => {
    try {
      const response = await updateManager(payload);

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getMangerRechargeHistory = createAsyncThunk(
  `${MANAGER_FEATURE_KEY}/getMangerRechargeHistory`,
  async (values: undefined, { rejectWithValue, getState }) => {
    const { manager } = getState() as RootState;

    try {
      const response = await getManagerRechargeHistory(
        manager.currentParams as GetManagerRechargeHistoryDef
      );

      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

const managerSlice = createSlice({
  name: MANAGER_FEATURE_KEY,
  initialState,
  reducers: {
    updateCurrentParams(state, action: PayloadAction<GetManagerListDef>) {
      state.currentParams = {
        ...state.currentParams,
        ...action.payload,
      };
    },
    clearManagerDetails(state) {
      state.managerDetails = undefined;
    },
  },
  extraReducers: builder => {
    /**
     * CREATE MANAGER
     */
    builder.addCase(registerManager.pending, state => {
      state.error = false;
      state.loading = true;
    });
    builder.addCase(registerManager.fulfilled, state => {
      state.loading = false;
    });
    builder.addCase(registerManager.rejected, state => {
      managerErrorHelper(state);
    });
    /**
     * GET MANAGER LIST
     */
    builder.addCase(getAllManagers.pending, state => {
      state.error = false;
      state.loading = true;
    });
    builder.addCase(getAllManagers.fulfilled, (state, action) => {
      const { data, meta } = action.payload;

      state.loading = false;
      state.managerList = data;
      state.paginationData = {
        currentPage: meta?.current_page,
        perPage: meta?.per_page,
        total: meta?.total,
      };
    });
    builder.addCase(getAllManagers.rejected, state => {
      managerErrorHelper(state);
    });
    /**
     * REMOVE MANAGER
     */
    builder.addCase(removeManager.pending, state => {
      state.error = false;
      state.loading = true;
    });
    builder.addCase(removeManager.fulfilled, state => {
      state.loading = false;
    });
    builder.addCase(removeManager.rejected, state => {
      managerErrorHelper(state);
    });
    /**
     * GET MANAGER DETAILS
     */
    builder.addCase(getAManager.pending, state => {
      state.error = false;
      state.loading = true;
    });
    builder.addCase(
      getAManager.fulfilled,
      (state, action: PayloadAction<InitialStateDef["managerDetails"]>) => {
        state.loading = false;
        state.managerDetails = action.payload;
      }
    );
    builder.addCase(getAManager.rejected, state => {
      managerErrorHelper(state);
    });
    /**
     * UPDATE MANAGER DETAILS
     */
    builder.addCase(modifyManager.pending, state => {
      state.error = false;
      state.loading = true;
    });
    builder.addCase(
      modifyManager.fulfilled,
      (state, action: PayloadAction<InitialStateDef["managerDetails"]>) => {
        state.loading = false;
        state.managerDetails = action.payload;
      }
    );
    builder.addCase(modifyManager.rejected, state => {
      managerErrorHelper(state);
    });

    /**
     * BLOCK_A_MANAGER
     */
    builder.addCase(blockAManager.pending, state => {
      state.error = false;
      state.loading = true;
    });
    builder.addCase(blockAManager.fulfilled, state => {
      state.loading = false;

      if (state.managerDetails) {
        state.managerDetails.blocked = true;
      }
    });
    builder.addCase(blockAManager.rejected, state => {
      managerErrorHelper(state);
    });

    /**
     * UNBLOCK_A_MANAGER
     */
    builder.addCase(unblockAManager.pending, state => {
      state.error = false;
      state.loading = true;
    });
    builder.addCase(unblockAManager.fulfilled, state => {
      state.loading = false;

      if (state.managerDetails) {
        state.managerDetails.blocked = false;
      }
    });
    builder.addCase(unblockAManager.rejected, state => {
      managerErrorHelper(state);
    });

    /**
     * GET_MANAGER_RECHARGE_HISTORY
     */
    builder.addCase(getMangerRechargeHistory.pending, state => {
      state.error = false;
      state.loading = true;
    });
    builder.addCase(getMangerRechargeHistory.fulfilled, (state, action) => {
      const { data, meta } = action.payload;

      state.loading = false;
      state.rechargeHistoryList = data;
      state.paginationData = {
        currentPage: meta?.current_page,
        perPage: meta?.per_page,
        total: meta?.total,
      };
    });
    builder.addCase(getMangerRechargeHistory.rejected, state => {
      managerErrorHelper(state);
    });
  },
});

export const { updateCurrentParams, clearManagerDetails } =
  managerSlice.actions;

export const managerReducer = managerSlice.reducer;
