import ContentLayout from "@app/components/layouts/ContentLayout/ContentLayout";

import ExpenseHistory from "../../components/ExpenseHistory/ExpenseHistory";

const FacilityExpenseHistoryScreen = () => {
  return (
    <ContentLayout header={{ title: "Facility Expense History" }}>
      <ExpenseHistory />
    </ContentLayout>
  );
};

export default FacilityExpenseHistoryScreen;
