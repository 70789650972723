import { memo, useEffect } from "react";

import { Col, message, Row } from "antd";
import { useForm } from "antd/lib/form/Form";
import dayjs from "dayjs";

import { RangePicker } from "@app/components/atoms/DateTimePicker/DateTimePicker";
import Statistic from "@app/components/atoms/Statistic/Statistic";
import PageFilter, {
  FilterItem,
} from "@app/components/molecules/PageFilter/PageFilter";
import {
  DEFAULT_API_DATE_FORMAT,
  DEFAULT_DATE_FORMAT,
  DEFAULT_DATE_RANGE,
} from "@app/constants/time.constants";
import useSearchParams, { SearchParamDef } from "@app/hooks/useSearchParams";
import { useAppDispatch, useAppSelector } from "@app/redux/store";

import {
  getFacilityExpense,
  getFacilityIncome,
  getFacilityUsage,
  getSubscriptionIncome,
} from "../../facility";
import styles from "./FacilityStats.module.scss";

export interface FacilityStatsFilterDef {
  dateRange?: string;
}

export interface FacilityStatsProps {
  facilityId: number;
}

type SearchQueryParamDef = {
  dateRange?: [string, string];
};

const FacilityStats = ({ facilityId }: FacilityStatsProps) => {
  const [form] = useForm();
  const { facilityStats, loading } = useAppSelector(state => ({
    facilityStats: state.facility.facilityStats,
    loading: state.facility.statsLoading,
  }));
  const dispatch = useAppDispatch();
  const { search, updateSearchParams } =
    useSearchParams<SearchParamDef<SearchQueryParamDef>>();

  const currentDate = dayjs();

  const getStats = async (dateRange?: [string, string]) => {
    const payload = {
      facility_id: facilityId,
      from: dateRange?.[0]
        ? dayjs(dateRange[0]).format(DEFAULT_API_DATE_FORMAT)
        : undefined,
      to: dateRange?.[1]
        ? dayjs(dateRange[1]).format(DEFAULT_API_DATE_FORMAT)
        : undefined,
    };

    // Fetch total income
    const incomeResponse = await dispatch(getFacilityIncome(payload));

    if (!getFacilityIncome.fulfilled.match(incomeResponse)) {
      message.error("Facility total income fetching failed.");
    }

    // Fetch total expense
    const expenseResponse = await dispatch(getFacilityExpense(payload));

    if (!getFacilityExpense.fulfilled.match(expenseResponse)) {
      message.error("Facility total expense fetching failed.");
    }

    // Fetch total usage
    const usageResponse = await dispatch(getFacilityUsage(payload));

    if (!getFacilityUsage.fulfilled.match(usageResponse)) {
      message.error("Facility total usage fetching failed.");
    }

    // Fetch subscription income
    const subscriptionIncomeResponse = await dispatch(
      getSubscriptionIncome(payload)
    );

    if (!getSubscriptionIncome.fulfilled.match(subscriptionIncomeResponse)) {
      message.error("Facility subscription income fetching failed.");
    }
  };

  useEffect(() => {
    if (facilityId) {
      if (!search.dateRange) {
        const from = dayjs(currentDate).subtract(
          DEFAULT_DATE_RANGE - 1,
          "days"
        );
        const to = currentDate;

        form.setFieldsValue({
          dateRange: [from, to],
        });

        updateSearchParams({
          dateRange: [
            from.format(DEFAULT_API_DATE_FORMAT),
            to.format(DEFAULT_API_DATE_FORMAT),
          ],
        });

        getStats([
          from.format(DEFAULT_API_DATE_FORMAT),
          to.format(DEFAULT_API_DATE_FORMAT),
        ]);
      } else {
        getStats(search.dateRange);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [facilityId]);

  const handleSearch = async () => {
    if (!loading) {
      getStats(form.getFieldValue("dateRange"));
    }
  };

  const getActualTotalIncome = () =>
    Number(facilityStats.total_income) +
    Number(facilityStats.total_income_subscriptions);

  const getTotalProfit = () =>
    Number(facilityStats?.total_income) +
    Number(facilityStats.total_income_subscriptions) -
    Number(facilityStats?.total_expense);

  return (
    <div>
      <div className="title">
        <span className="font-weight-bold">Facility Statistics</span>
      </div>

      <PageFilter<FacilityStatsFilterDef>
        parseDates
        form={form}
        submitOnChange
        onSubmit={handleSearch}
        columns={3}
      >
        <FilterItem name="dateRange">
          <RangePicker format={DEFAULT_DATE_FORMAT} inputReadOnly />
        </FilterItem>
      </PageFilter>

      <Row gutter={[12, 12]} className={styles.statsWrapper}>
        <Col xs={6}>
          <Statistic
            title="Total Income"
            value={getActualTotalIncome() || 0}
            suffix="BDT"
            backgroundColor="#0050b3"
          />
        </Col>

        <Col xs={6}>
          <Statistic
            title="Total Expense"
            value={facilityStats.total_expense ?? 0}
            suffix="BDT"
            backgroundColor="red"
          />
        </Col>

        <Col xs={6}>
          <Statistic
            title="Total Profit"
            value={getTotalProfit() || 0}
            suffix="BDT"
            backgroundColor="green"
          />
        </Col>

        <Col xs={6}>
          <Statistic
            title="Total Income by Pay As You Go"
            value={facilityStats.total_income_subscribed ?? 0}
            suffix="BDT"
            backgroundColor="royalblue"
          />
        </Col>

        <Col xs={6}>
          <Statistic
            title="Total Income by Anonymous Users"
            value={facilityStats.total_income_anon ?? 0}
            suffix="BDT"
            backgroundColor="royalblue"
          />
        </Col>

        <Col xs={6}>
          <Statistic
            title="Total Usages (Male)"
            value={facilityStats.total_usage_male ?? 0}
            backgroundColor="orange"
          />
        </Col>

        <Col xs={6}>
          <Statistic
            title="Total Usages (Female)"
            value={facilityStats.total_usage_female ?? 0}
            backgroundColor="orange"
          />
        </Col>

        <Col xs={6}>
          <Statistic
            title="Total Income by package"
            value={facilityStats.total_income_subscriptions ?? 0}
            suffix="BDT"
            backgroundColor="purple"
          />
        </Col>
      </Row>
    </div>
  );
};

export default memo(FacilityStats);
