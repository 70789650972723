import { useState } from "react";

import {
  BulbOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { Input, Row, Col, message, Modal, Popover } from "antd";
import { useHistory } from "react-router-dom";
import { useMount } from "react-use";

import Button from "@app/components/atoms/Button/Button";
import Form, { Item, useForm } from "@app/components/atoms/Form/Form";
import {
  DEFAULT_INPUT_LENGTH,
  DEFAULT_BD_PHONE_NO_PATTERN,
} from "@app/constants/input.constants";
import { modifyProfile } from "@app/features/auth/auth";
import { useAppDispatch } from "@app/redux/store";
import { HasMessage } from "@app/types/api.types";

import { registerAdmin, modifyAdmin, removeAdmin, AdminDef } from "../../admin";
import { AdminPathsEnum } from "../../constants/admin.paths";
import styles from "./AdminForm.module.scss";

type AdminFormDef = {
  id?: number;
  name: string;
  email: string;
  phone?: string;
  canBeDeleted?: boolean;
};

export interface AdminFormProps {
  initialValues?: AdminFormDef;
  profileMode?: boolean;
  loading?: boolean;
  editMode?: boolean;
}

const { confirm } = Modal;

const AdminForm = ({
  initialValues,
  profileMode,
  editMode,
  loading,
}: AdminFormProps) => {
  const [form] = useForm();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const [isPassWordSet, setIsPassWordSet] = useState(false);

  useMount(() => {
    if (initialValues) {
      form.setFieldsValue(initialValues);
    }
  });

  const createAdmin = async () => {
    const { getFieldValue } = form;

    const response = await dispatch(
      registerAdmin({
        name: getFieldValue("name"),
        email: getFieldValue("email"),
        phone_number: getFieldValue("phone") || undefined,
        password: getFieldValue("password"),
      })
    );

    if (registerAdmin.fulfilled.match(response)) {
      message.success("Admin created successfully");
      history.push(AdminPathsEnum.ADMIN_LIST);
    } else {
      message.error((response.payload as HasMessage)?.message);
    }
  };

  const handleCreate = () => {
    createAdmin();
  };

  const updateProfile = async () => {
    const { getFieldValue, resetFields } = form;

    const response = await dispatch(
      modifyProfile({
        id: Number(initialValues?.id),
        name: getFieldValue("name"),
        phone_number: getFieldValue("phone"),
        password: getFieldValue("password") || undefined,
      })
    );

    if (modifyProfile.fulfilled.match(response)) {
      message.success("Updated successfully");
      resetFields(["password", "confirm"]);
      setIsPassWordSet(false);
    } else {
      message.error((response.payload as HasMessage)?.message);
    }
  };

  const updateAdmin = async () => {
    const { getFieldValue } = form;

    const response = await dispatch(
      modifyAdmin({
        id: Number(initialValues?.id),
        name: getFieldValue("name"),
        phone_number: getFieldValue("phone"),
        password: getFieldValue("password") || undefined,
      })
    );

    if (modifyAdmin.fulfilled.match(response)) {
      message.success("Updated successfully");
      history.goBack();
    } else {
      message.error((response.payload as HasMessage)?.message);
    }
  };

  const handleUpdate = () => {
    if (profileMode) {
      updateProfile();
    }

    if (editMode) {
      updateAdmin();
    }
  };

  const getPasswordLabel = () => {
    if (profileMode) {
      return "New Password";
    }
    if (editMode) {
      return "Reset Password";
    }

    return "Password";
  };

  const deleteAdmin = async (id: AdminDef["id"]) => {
    const response = await dispatch(removeAdmin(id));

    if (removeAdmin.fulfilled.match(response)) {
      message.success("Admin deleted successfully");
      history.goBack();
    } else {
      message.error("Admin deletion failed");
    }

    return response;
  };

  const confirmDelete = () => {
    const id = initialValues?.id;

    if (id) {
      confirm({
        title: "Are you sure want to delete this admin?",
        icon: <ExclamationCircleOutlined />,
        okText: "Yes",
        okType: "danger",
        cancelText: "No",
        onOk() {
          return deleteAdmin(id);
        },
      });
    }
  };

  const handleDelete = () => {
    confirmDelete();
  };

  return (
    <Row justify="space-between">
      <Col xs={8}>
        <Form
          form={form}
          onFinish={profileMode || editMode ? handleUpdate : handleCreate}
        >
          <Item
            name="name"
            label="Name"
            rules={[
              {
                required: true,
                whitespace: true,
                message: "Please enter the name!",
              },
              {
                max: DEFAULT_INPUT_LENGTH,
                message: `Input length can't exceed ${DEFAULT_INPUT_LENGTH} characters!`,
              },
            ]}
          >
            <Input />
          </Item>

          <Item
            name="email"
            label="E-mail"
            rules={[
              {
                type: "email",
                message: "The input is not valid e-mail!",
              },
              {
                required: true,
                message: "Please enter the e-mail!",
              },
              {
                max: DEFAULT_INPUT_LENGTH,
                message: `Input length can't exceed ${DEFAULT_INPUT_LENGTH} characters!`,
              },
            ]}
          >
            <Input disabled={profileMode ?? editMode} />
          </Item>

          <Item
            name="phone"
            label="Phone Number"
            rules={[
              {
                pattern: DEFAULT_BD_PHONE_NO_PATTERN,
                message: `Invalid phone no!`,
              },
            ]}
          >
            <Input type="tel" defaultValue="+88" />
          </Item>

          <Row align="middle" wrap={false}>
            <Col xs={24}>
              <Item
                name="password"
                label={getPasswordLabel()}
                rules={[
                  {
                    required: !profileMode && !editMode,
                    message: "Please enter the password!",
                  },
                  {
                    max: DEFAULT_INPUT_LENGTH,
                    message: `Input length can't exceed ${DEFAULT_INPUT_LENGTH} characters!`,
                  },
                ]}
                hasFeedback={!profileMode && !editMode ? true : isPassWordSet}
                getValueProps={value => {
                  setIsPassWordSet(!!value);

                  return value;
                }}
              >
                <Input.Password />
              </Item>
            </Col>
            <Col>
              <Popover
                placement="right"
                content={
                  <div className={styles.hint}>
                    {`Should contain at least one uppercase, one lowercase, one
                    numeric, one special character and shouldn't be leaked.`}
                  </div>
                }
                title="Password Hint"
              >
                <Button type="link" shape="circle" icon={<BulbOutlined />} />
              </Popover>
            </Col>
          </Row>

          <Item
            name="confirm"
            label="Confirm Password"
            dependencies={["password"]}
            hasFeedback={!profileMode && !editMode ? true : isPassWordSet}
            rules={[
              {
                required: !profileMode && !editMode ? true : isPassWordSet,
                message: "Please confirm the password!",
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error(
                      "The two passwords that you entered do not match!"
                    )
                  );
                },
              }),
            ]}
          >
            <Input.Password />
          </Item>
          <Item>
            <Button disabled={loading} type="primary" htmlType="submit">
              {profileMode || editMode ? "Update" : "Create"}
            </Button>
          </Item>
        </Form>
      </Col>

      {editMode && (
        <Col>
          <Button
            disabled={!initialValues?.canBeDeleted}
            onClick={handleDelete}
            type="primary"
            danger
          >
            <DeleteOutlined />
            <span>Delete</span>
          </Button>
        </Col>
      )}
    </Row>
  );
};

export default AdminForm;
