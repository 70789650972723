import ContentLayout from "@app/components/layouts/ContentLayout/ContentLayout";

import AmountsTable from "../../components/AmountsTable/AmountsTable";

const AmountListScreen = () => {
  return (
    <ContentLayout header={{ title: "Amount List" }}>
      <AmountsTable />
    </ContentLayout>
  );
};

export default AmountListScreen;
