import { useEffect, useState } from "react";

import { ExportOutlined, PlusOutlined } from "@ant-design/icons";
import { message, Modal, Table, TablePaginationConfig, Tooltip } from "antd";
import { FilterValue, SorterResult } from "antd/lib/table/interface";
import dayjs from "dayjs";
import { useLocation } from "react-router-dom";
import { usePrevious } from "react-use";

import Button from "@app/components/atoms/Button/Button";
import { useForm } from "@app/components/atoms/Form/Form";
import TableView, {
  TableViewProps,
} from "@app/components/molecules/TableView/TableView";
import { DEFAULT_DATE_FORMAT } from "@app/constants/time.constants";
import { exportTableData } from "@app/helpers/table.helper";
import useSearchParams, { SearchParamDef } from "@app/hooks/useSearchParams";
import { useAppDispatch, useAppSelector } from "@app/redux/store";
import { SortEnum } from "@app/types/table.types";

import {
  removeExpense,
  getExpenseHistory,
  getExpenseDetails,
  clearExpenseDetails,
  GetFacilityExpenseHistoryDef,
} from "../../facility";
import useExpenseHistory from "../../hooks/useExpenseHistory";
import { ExpenseDef } from "../../types/facility.types";
import ExpenseFilter from "../ExpenseFilter/ExpenseFilter";
import ExpenseForm from "../ExpenseForm/ExpenseForm";
import styles from "./ExpenseHistory.module.scss";

type FacilitiesTableProps = TableViewProps<ExpenseDef>;

const ExpenseHistory = ({ ...props }: FacilitiesTableProps) => {
  const [form] = useForm();
  const location = useLocation();
  const prevLocation = usePrevious(location);
  const [modalVisible, setModalVisible] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const { getSortOrder, setCurrentSort } = useSearchParams<SearchParamDef>();
  const {
    expenseList,
    paginationData,
    loading,
    getInitialExpenseList,
    getUpdatedExpenseList,
    facilityId,
  } = useExpenseHistory();
  const dispatch = useAppDispatch();
  const { expenseDetails, currentParams } = useAppSelector(state => ({
    expenseDetails: state.facility.expenseDetails,
    currentParams: state.facility.currentParams as GetFacilityExpenseHistoryDef,
  }));

  useEffect(() => {
    // Fetch expense list on initial load
    if (prevLocation?.search === undefined) {
      getInitialExpenseList();
    }
  }, [getInitialExpenseList, prevLocation?.search]);

  useEffect(() => {
    form.resetFields();
  }, [modalVisible, form]);

  const parseSort = (
    sorter: SorterResult<ExpenseDef> | SorterResult<ExpenseDef>[]
  ) => {
    if (!Array.isArray(sorter)) {
      const { order } = sorter;

      if (order) {
        return order === "ascend" ? SortEnum.ASC : SortEnum.DESC;
      }
    }

    return undefined;
  };

  const parseOrderBy = (
    sorter: SorterResult<ExpenseDef> | SorterResult<ExpenseDef>[]
  ) => {
    if (!Array.isArray(sorter)) {
      const { order } = sorter;

      if (order) {
        return sorter.columnKey === "date" ? "created_at" : sorter.columnKey;
      }
    }

    return undefined;
  };

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<ExpenseDef> | SorterResult<ExpenseDef>[]
  ) => {
    if (!loading) {
      getUpdatedExpenseList({
        facilityId: String(facilityId),
        page: pagination.current,
        perPage: pagination.pageSize,
        sort: parseSort(sorter),
        orderBy: parseOrderBy(sorter) as string,
      });
    }

    if (!Array.isArray(sorter)) {
      setCurrentSort(sorter.order);
    }
  };

  const handleCreate = () => {
    setModalVisible(true);
  };

  const getExpenseInfo = async (id: ExpenseDef["id"]) => {
    const response = await dispatch(getExpenseDetails({ id }));

    if (!getExpenseDetails.fulfilled.match(response)) {
      message.success("Expense details fetching failed");
    }
  };

  const handleEdit = (expense: ExpenseDef) => {
    setEditMode(true);
    setModalVisible(true);
    getExpenseInfo(expense.id);
  };

  const deleteExpense = async (id: ExpenseDef["id"]) => {
    const response = await dispatch(removeExpense({ id }));

    if (removeExpense.fulfilled.match(response)) {
      message.success("Expense removed successfully");
      dispatch(getExpenseHistory()); // Fetch updated list
    } else {
      message.error("Expense removing failed");
    }
  };

  const handleDelete = (expense: ExpenseDef) => {
    deleteExpense(expense.id);
  };

  const handleCancel = () => {
    setModalVisible(false);
    setEditMode(false);
    dispatch(clearExpenseDetails());
  };

  const handleExport = () => {
    const facilityInfo = ["Facility Name", expenseList[0]?.facility?.name, ""];
    const dateInfo = [
      "Date",
      `${currentParams.from} - ${currentParams.to}`,
      "",
    ];
    const emptyRow = ["", "", ""];
    const header = ["Title", "Date", "Amount"];
    const data = expenseList.map(expense => ({
      title: expense.title,
      date: dayjs(expense.created_at).format(DEFAULT_DATE_FORMAT),
      amount: String(expense.amount),
    }));

    exportTableData(data, `expense_history_${Date.now()}`, [
      facilityInfo,
      dateInfo,
      emptyRow,
      header,
    ]);
  };

  return (
    <>
      <TableView<ExpenseDef>
        dataSource={expenseList}
        loading={loading}
        actionTitle="Action"
        pagination={{
          current: paginationData?.currentPage,
          pageSize: paginationData?.perPage,
          total: paginationData?.total,
          showSizeChanger: true,
          pageSizeOptions: ["6", "8", "10", "15", "25"],
        }}
        title={() => (
          <div className={styles.expenseHistoryTableTitle}>
            <ExpenseFilter facilityId={Number(facilityId)} />

            <div>
              <Button
                type="primary"
                onClick={handleCreate}
                icon={<PlusOutlined />}
              >
                Add Expense
              </Button>

              <Tooltip
                title={!expenseList.length ? "There is no data to export" : ""}
              >
                <span>
                  <Button
                    className={styles.exportButton}
                    type="primary"
                    onClick={handleExport}
                    icon={<ExportOutlined />}
                    disabled={!expenseList.length}
                  >
                    Export Data
                  </Button>
                </span>
              </Tooltip>
            </div>
          </div>
        )}
        onChange={handleTableChange}
        onEdit={handleEdit}
        onDelete={handleDelete}
        deleteText="Delete Expense?"
        {...props}
      >
        <Table.Column
          title="Title"
          key="title"
          dataIndex="title"
          render={(title: string) => title}
        />
        <Table.Column
          title="Date"
          key="date"
          dataIndex="created_at"
          render={(date: string) => dayjs(date).format(DEFAULT_DATE_FORMAT)}
          sorter
          sortOrder={getSortOrder("date")}
        />
        <Table.Column
          title="Amount"
          key="amount"
          dataIndex="amount"
          render={(amount: string) => amount}
        />
      </TableView>

      <Modal
        title={`${
          editMode ? "Update expense" : "Add expense to this facility"
        }`}
        centered
        visible={modalVisible}
        onOk={form.submit}
        okText={editMode ? "Update" : "Add"}
        onCancel={handleCancel}
        maskClosable={false}
        confirmLoading={loading}
        destroyOnClose
      >
        <ExpenseForm
          form={form}
          editMode={editMode}
          initialValues={{ facilityId: Number(facilityId), ...expenseDetails }}
          handleClose={handleCancel}
        />
      </Modal>
    </>
  );
};

export default ExpenseHistory;
