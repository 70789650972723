import { message, Table, TablePaginationConfig } from "antd";
import { FilterValue, SorterResult } from "antd/lib/table/interface";
import { Link, useHistory } from "react-router-dom";

import Button from "@app/components/atoms/Button/Button";
import TableView, {
  TableViewProps,
} from "@app/components/molecules/TableView/TableView";
import { AuthRoleEnum } from "@app/features/auth/auth";
import useSearchParams, { SearchParamDef } from "@app/hooks/useSearchParams";
import { useAppDispatch } from "@app/redux/store";
import { SortEnum } from "@app/types/table.types";

import { removeAdmin, getAllAdmins } from "../../admin";
import { AdminPathsEnum } from "../../constants/admin.paths";
import useAdminList from "../../hooks/useAdminList";
import { AdminDef } from "../../types/admin.types";
import AdminsFilter from "../AdminsFilter/AdminsFilter";
import styles from "./AdminsTable.module.scss";

interface AdminTableProps extends TableViewProps<AdminDef> {
  onAdd?: () => void;
}

const AdminsTable = ({ onAdd, ...props }: AdminTableProps) => {
  const dispatch = useAppDispatch();
  const { getSortOrder, setCurrentSort } = useSearchParams<SearchParamDef>();
  const { adminList, paginationData, loading, getUpdatedAdminList } =
    useAdminList();
  const history = useHistory();

  const parseSort = (
    sorter: SorterResult<AdminDef> | SorterResult<AdminDef>[]
  ) => {
    if (!Array.isArray(sorter)) {
      const { order } = sorter;

      if (order) {
        return order === "ascend" ? SortEnum.ASC : SortEnum.DESC;
      }
    }

    return undefined;
  };

  const parseOrderBy = (
    sorter: SorterResult<AdminDef> | SorterResult<AdminDef>[]
  ) => {
    if (!Array.isArray(sorter)) {
      const { order } = sorter;

      if (order) {
        return sorter.columnKey === "phone" ? "phone_number" : sorter.columnKey;
      }
    }

    return undefined;
  };

  const handleAdminTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<AdminDef> | SorterResult<AdminDef>[]
  ) => {
    if (!loading) {
      getUpdatedAdminList({
        page: pagination.current,
        perPage: pagination.pageSize,
        sort: parseSort(sorter),
        orderBy: parseOrderBy(sorter),
      });
    }

    if (!Array.isArray(sorter)) {
      setCurrentSort(sorter.order);
    }
  };

  const deleteAdmin = async (id: AdminDef["id"]) => {
    const response = await dispatch(removeAdmin(id));

    if (removeAdmin.fulfilled.match(response)) {
      message.success("Admin deleted successfully");
      dispatch(getAllAdmins()); // Get updated admin list
    } else {
      message.error("Admin deletion failed");
    }
  };

  const handleDelete = (admin: AdminDef) => {
    deleteAdmin(admin.id);
  };

  const data = adminList.map(admin => ({
    ...admin,
    canBeDeleted: admin.role !== AuthRoleEnum.SUPER_ADMIN,
  }));

  const handleEdit = (admin: AdminDef) => {
    history.push(`${AdminPathsEnum.ADMIN_DETAILS}/${admin.id}`, { admin });
  };

  return (
    <TableView<AdminDef>
      dataSource={data}
      loading={loading}
      actionTitle="Action"
      pagination={{
        current: paginationData?.currentPage,
        pageSize: paginationData?.perPage,
        total: paginationData?.total,
        showSizeChanger: true,
        pageSizeOptions: ["6", "8", "10", "15", "25"],
      }}
      title={() => (
        <div className={styles.adminTableTitle}>
          <AdminsFilter />

          <Link to={AdminPathsEnum.ADMIN_CREATE}>
            <Button type="primary" onClick={onAdd}>
              Create a new Admin
            </Button>
          </Link>
        </div>
      )}
      onChange={handleAdminTableChange}
      onDelete={handleDelete}
      onEdit={handleEdit}
      {...props}
    >
      <Table.Column
        title="Name"
        key="name"
        dataIndex="name"
        render={(name: string) => name}
        sorter
        sortOrder={getSortOrder("name")}
      />
      <Table.Column
        title="Email"
        key="email"
        dataIndex="email"
        render={(email: string) => email}
        sorter
        sortOrder={getSortOrder("email")}
      />
      <Table.Column
        title="Phone"
        key="phone"
        dataIndex="phone_number"
        render={(phone: string) => phone}
        sorter
        sortOrder={getSortOrder("phone")}
      />
    </TableView>
  );
};

export default AdminsTable;
