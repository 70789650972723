import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  getBusinessOverview,
  getEnvironmentNotifications,
  getTotalIncomeBySubscriptions,
} from "../api/dashboard.api";
import { DASHBOARD_FEATURE_KEY } from "../constants/dashboard.keys";
import { dashboardErrorHelper } from "../helpers/dashboard.helpers";
import {
  GetBusinessOverviewDef,
  GetEnvironmentNotificationsDef,
  InitialStateDef,
} from "../types/dashboard.types";

const initialState: InitialStateDef = {
  error: false,
  loading: false,
  environmentNotificationsLoading: false,
};

export const getBusinessOverviewData = createAsyncThunk(
  `${DASHBOARD_FEATURE_KEY}/getBusinessOverviewData`,
  async (payload: GetBusinessOverviewDef, { rejectWithValue }) => {
    try {
      const response = await getBusinessOverview(payload);

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getTotalIncomeBySubscriptionsData = createAsyncThunk(
  `${DASHBOARD_FEATURE_KEY}/getTotalIncomeBySubscriptionsData`,
  async (payload: GetBusinessOverviewDef, { rejectWithValue }) => {
    try {
      const response = await getTotalIncomeBySubscriptions(payload);

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getFacilityEnvironmentNotifications = createAsyncThunk(
  `${DASHBOARD_FEATURE_KEY}/getFacilityEnvironmentNotifications`,
  async (payload: GetEnvironmentNotificationsDef, { rejectWithValue }) => {
    try {
      const response = await getEnvironmentNotifications(payload);

      return response.data.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const dashboardSlice = createSlice({
  name: DASHBOARD_FEATURE_KEY,
  initialState,
  reducers: {},
  extraReducers: builder => {
    /**
     * GET_BUSINESS_OVERVIEW
     */
    builder.addCase(getBusinessOverviewData.pending, state => {
      state.error = false;
      state.loading = true;
    });
    builder.addCase(
      getBusinessOverviewData.fulfilled,
      (state, action: PayloadAction<InitialStateDef["businessOverview"]>) => {
        state.loading = false;
        state.businessOverview = action.payload;
      }
    );
    builder.addCase(getBusinessOverviewData.rejected, state => {
      dashboardErrorHelper(state);
    });
    /**
     * GET_TOTAL_INCOME_BY_SUBSCRIPTION
     */
    builder.addCase(getTotalIncomeBySubscriptionsData.pending, state => {
      state.error = false;
      state.loading = true;
    });
    builder.addCase(
      getTotalIncomeBySubscriptionsData.fulfilled,
      (
        state,
        action: PayloadAction<InitialStateDef["totalIncomeSubscriptions"]>
      ) => {
        state.loading = false;
        state.totalIncomeSubscriptions = action.payload;
      }
    );
    builder.addCase(getTotalIncomeBySubscriptionsData.rejected, state => {
      dashboardErrorHelper(state);
    });
    /**
     * GET_FACILITY_ENVIRONMENT_NOTIFICATIONS
     */
    builder.addCase(getFacilityEnvironmentNotifications.pending, state => {
      state.error = false;
      state.environmentNotificationsLoading = true;
    });
    builder.addCase(
      getFacilityEnvironmentNotifications.fulfilled,
      (
        state,
        action: PayloadAction<InitialStateDef["environmentNotifications"]>
      ) => {
        state.environmentNotificationsLoading = false;
        state.environmentNotifications = action.payload;
      }
    );
    builder.addCase(getFacilityEnvironmentNotifications.rejected, state => {
      dashboardErrorHelper(state);
    });
  },
});

export const dashboardReducer = dashboardSlice.reducer;
