import { message } from "antd";
import { useParams } from "react-router-dom";

import { DEFAULT_PAGE_SIZE } from "@app/constants/pagination.constants";
import useSearchParams, { SearchParamDef } from "@app/hooks/useSearchParams";
import { useAppDispatch, useAppSelector } from "@app/redux/store";

import { getFacilityFeedbacks } from "../facility";
import { GetFacilityFeedbackListDef } from "../types/facility.types";

type UsageHistoryParams = {
  id?: string;
};

const useFeedbackList = () => {
  const dispatch = useAppDispatch();
  const { updateSearchParams, search, getSortOrder, setCurrentSort } =
    useSearchParams<SearchParamDef>();
  const { id } = useParams<UsageHistoryParams>();
  const { facilityFeedbacks, loading, paginationData } = useAppSelector(
    state => ({
      facilityFeedbacks: state.facility.facilityFeedbacks,
      loading: state.facility.loading,
      paginationData: state.facility.paginationData,
    })
  );

  const parseSort = () => {
    if (!search.sort) {
      return undefined;
    }

    return search.sort;
  };

  const parseOrderBy = () => {
    if (!search.sort) {
      return undefined;
    }

    return search.orderBy === "time" ? "created_at" : search.orderBy;
  };

  const getInitialFeedbackList = async () => {
    const params: GetFacilityFeedbackListDef = {
      facility_id: Number(id),
      order_by: parseOrderBy(),
      sort: parseSort(),
      page: search.page ?? undefined,
      per_page: search.pageSize ?? DEFAULT_PAGE_SIZE,
    };

    const response = await dispatch(getFacilityFeedbacks(params));

    if (!getFacilityFeedbacks.fulfilled.match(response)) {
      message.error("Feedback list fetching failed");
    }
  };

  const getUpdatedFeedbackList = async (params: GetFacilityFeedbackListDef) => {
    const response = await dispatch(getFacilityFeedbacks(params));

    if (!getFacilityFeedbacks.fulfilled.match(response)) {
      message.error("Feedback list fetching failed");
    }
  };

  return {
    getInitialFeedbackList,
    getUpdatedFeedbackList,
    facilityFeedbacks,
    loading,
    paginationData,
    updateSearchParams,
    facilityId: id,
    search,
    getSortOrder,
    setCurrentSort,
  };
};

export default useFeedbackList;
