export enum FacilityEndpointsEnum {
  GET_FACILITY_LIST = "admin/facilities",
  CREATE_FACILITY = "admin/facilities",
  GET_FACILITY_DETAILS = "admin/facilities",
  GET_SERVICE_LIST = "admin/services",
  UPDATE_FACILITY = "admin/facilities",
  ADD_SERVICE = "admin/facilities/services",
  REMOVE_SERVICE = "admin/facilities/services",
  ADD_DEVICE = "admin/facilities/devices",
  REMOVE_DEVICE = "admin/facilities/devices",
  TOGGLE_FACILITY_STATUS = "admin/facilities",
  GET_FACILITY_EXPENSE_HISTORY = "admin/facilities/expenses",
  DELETE_FACILITY_EXPENSE = "admin/facilities/expenses",
  CREATE_FACILITY_EXPENSE = "admin/facilities",
  GET_FACILITY_EXPENSE_DETAILS = "admin/facilities/expenses",
  UPDATE_FACILITY_EXPENSE = "admin/facilities/expenses",
  GET_FACILITY_USAGE_HISTORY = "admin/facilities/usages",
  GET_FACILITY_TOTAL_INCOME = "admin/business-overview/total-income",
  GET_FACILITY_TOTAL_EXPENSE = "admin/business-overview/total-expense",
  GET_FACILITY_TOTAL_USAGE = "admin/business-overview/total-usages",
  GET_FACILITY_TOTAL_SUBSCRIPTION_INCOME = "admin/subscription-packages/income",
  GET_FACILITY_FEEDBACKS_LIST = "admin/feedbacks",
  GET_FACILITY_ENVIRONMENT_QUALITY_LIST = "admin/facilities/environment-qualities",
  CLEAR_ALL_FACILITY_ENVIRONMENT_QUALITY = "admin/facilities/environment-qualities",
}
