import ContentLayout from "@app/components/layouts/ContentLayout/ContentLayout";

import BusinessOverview from "../../components/BusinessOverview/BusinessOverview";
import EnvironmentQualityNotification from "../../components/EnvironmentQualityNotification/EnvironmentQualityNotification";

const DashboardScreen = () => {
  return (
    <ContentLayout header={{ title: "Dashboard" }}>
      <BusinessOverview />
      <EnvironmentQualityNotification />
    </ContentLayout>
  );
};

export default DashboardScreen;
