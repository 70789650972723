import ContentLayout from "@app/components/layouts/ContentLayout/ContentLayout";

import PackagesTable from "../../components/PackagesTable/PackagesTable";

const AmountListScreen = () => {
  return (
    <ContentLayout header={{ title: "Package List" }}>
      <PackagesTable />
    </ContentLayout>
  );
};

export default AmountListScreen;
