import { message } from "antd";
import dayjs from "dayjs";
import { useParams } from "react-router-dom";

import { DEFAULT_PAGE_SIZE } from "@app/constants/pagination.constants";
import { DEFAULT_API_DATE_FORMAT } from "@app/constants/time.constants";
import useSearchParams, { SearchParamDef } from "@app/hooks/useSearchParams";
import { useAppDispatch, useAppSelector } from "@app/redux/store";

import { EnvironmentQualityFilterDef } from "../components/EnvironmentQualityFilter/EnvironmentQualityFilter";
import { getAllEnvironmentQuality, updateCurrentParams } from "../facility";
import { GetFacilityEnvironmentQualityListDef } from "../types/facility.types";

type EnvironmentQualityListParams = {
  id?: string;
};

const useEnvironmentQualityList = () => {
  const dispatch = useAppDispatch();
  const { updateSearchParams, search, getSortOrder, setCurrentSort } =
    useSearchParams<SearchParamDef<EnvironmentQualityFilterDef>>();
  const { id } = useParams<EnvironmentQualityListParams>();
  const { environmentQualityList, loading, paginationData } = useAppSelector(
    state => ({
      environmentQualityList: state.facility.environmentQualityList,
      loading: state.facility.loading,
      paginationData: state.facility.paginationData,
    })
  );

  const parseSort = () => {
    if (!search.sort) {
      return undefined;
    }

    return search.sort;
  };

  const parseOrderBy = () => {
    if (!search.sort) {
      return undefined;
    }

    return search.orderBy === "time" ? "created_at" : search.orderBy;
  };

  const getInitialEnvironmentQualityList = async () => {
    const params: GetFacilityEnvironmentQualityListDef = {
      facility_id: Number(id),
      order_by: parseOrderBy(),
      sort: parseSort(),
      page: search.page ?? undefined,
      per_page: search.pageSize ?? DEFAULT_PAGE_SIZE,
      date: dayjs(search.date ?? undefined).format(DEFAULT_API_DATE_FORMAT),
    };

    dispatch(updateCurrentParams(params));
    const response = await dispatch(getAllEnvironmentQuality());

    if (!getAllEnvironmentQuality.fulfilled.match(response)) {
      message.error("Environment Quality list fetching failed");
    }
  };

  const getUpdatedEnvironmentQualityList = async (
    params: GetFacilityEnvironmentQualityListDef
  ) => {
    dispatch(updateCurrentParams(params));

    const response = await dispatch(getAllEnvironmentQuality());

    if (!getAllEnvironmentQuality.fulfilled.match(response)) {
      message.error("Environment Quality list fetching failed");
    }
  };

  return {
    getInitialEnvironmentQualityList,
    getUpdatedEnvironmentQualityList,
    environmentQualityList,
    loading,
    paginationData,
    updateSearchParams,
    facilityId: id,
    search,
    getSortOrder,
    setCurrentSort,
  };
};

export default useEnvironmentQualityList;
