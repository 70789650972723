import { useEffect } from "react";

import { Table, TablePaginationConfig } from "antd";
import { FilterValue, SorterResult } from "antd/lib/table/interface";
import dayjs from "dayjs";
import { useHistory, useLocation } from "react-router-dom";
import { usePrevious } from "react-use";

import TableView, {
  TableViewProps,
} from "@app/components/molecules/TableView/TableView";
import { DYNAMIC_SCROLL_HEIGHT_FOR_BOTTOM_OF_SCREEN_TABLE } from "@app/constants/table.constants";
import {
  DATE_FORMAT_LONG,
  TIME_FORMAT_12_HOUR,
} from "@app/constants/time.constants";
import useSearchParams, { SearchParamDef } from "@app/hooks/useSearchParams";
import { SortEnum } from "@app/types/table.types";

import useManagerRechargeHistory from "../../hooks/useManagerRechargeHistory";
import { ManagerPathsEnum, RechargeDef } from "../../manager";
import RechargeHistoryFilter from "../RechargeHistoryFilter/RechargeHistoryFilter";
import styles from "./ManagerRechargeHistoryTable.module.scss";

type UsagesTableProps = TableViewProps<RechargeDef>;

const ManagerRechargeHistory = ({ ...props }: UsagesTableProps) => {
  const location = useLocation();
  const history = useHistory();
  const prevLocation = usePrevious(location);
  const { setCurrentSort } = useSearchParams<SearchParamDef>();
  const {
    loading,
    getInitialRechargeHistoryList,
    getUpdatedRechargeHistoryList,
    managerId,
    rechargeHistoryList,
  } = useManagerRechargeHistory();

  useEffect(() => {
    // Fetch usage list on initial load
    if (prevLocation?.search === undefined) {
      getInitialRechargeHistoryList();
    }
  }, [getInitialRechargeHistoryList, prevLocation?.search]);

  const parseSort = (
    sorter: SorterResult<RechargeDef> | SorterResult<RechargeDef>[]
  ) => {
    if (!Array.isArray(sorter)) {
      const { order } = sorter;

      if (order) {
        return order === "ascend" ? SortEnum.ASC : SortEnum.DESC;
      }
    }

    return undefined;
  };

  const parseOrderBy = (
    sorter: SorterResult<RechargeDef> | SorterResult<RechargeDef>[]
  ) => {
    if (!Array.isArray(sorter)) {
      const { order } = sorter;

      if (order) {
        return sorter.columnKey === "time" ? "created_at" : sorter.columnKey;
      }
    }

    return undefined;
  };

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<RechargeDef> | SorterResult<RechargeDef>[]
  ) => {
    if (!loading) {
      getUpdatedRechargeHistoryList({
        managerId: String(managerId),
        sort: parseSort(sorter),
        orderBy: parseOrderBy(sorter) as string,
      });
    }

    if (!Array.isArray(sorter)) {
      setCurrentSort(sorter.order);
    }
  };

  const handleView = (record: RechargeDef) => {
    history.push(
      `${ManagerPathsEnum.MANAGER_RECHARGE_HISTORY}}/${record.user.id}`
    );
  };

  const customFooter = () => {
    // Calculate and return the Total as the footer content
    const totalAge = rechargeHistoryList.reduce(
      (sum, record) => sum + record.amount,
      0
    );
    return <span className={styles.tableFooterWrapper}>Total: {totalAge}</span>;
  };

  return (
    <TableView<RechargeDef>
      dataSource={rechargeHistoryList}
      loading={loading}
      hideActionColumn
      pagination={false}
      title={() => (
        <div className={styles.ManagerRechargeHistoryTableTitle}>
          <RechargeHistoryFilter managerId={Number(managerId)} />
        </div>
      )}
      footer={customFooter}
      onChange={handleTableChange}
      scroll={{ y: DYNAMIC_SCROLL_HEIGHT_FOR_BOTTOM_OF_SCREEN_TABLE }}
      onView={handleView}
      className={styles.tableFooterWrapper}
      {...props}
    >
      <Table.Column
        title="Date"
        key="created_at"
        dataIndex="created_at"
        render={(date: string) => dayjs(date).format(DATE_FORMAT_LONG)}
        sorter
      />
      <Table.Column
        title="Time"
        key="time"
        dataIndex="created_at"
        render={(time: string) =>
          time ? dayjs(time).format(TIME_FORMAT_12_HOUR) : ""
        }
      />
      <Table.Column
        title="User Name"
        key="userName"
        dataIndex="user_name"
        render={(_, record: RechargeDef) => {
          return `${record?.user.first_name} ${record?.user?.last_name}`;
        }}
      />
      <Table.Column
        title="Uesr Phone Number"
        key="phone"
        dataIndex="phone_number"
        align="center"
        render={(_, record: RechargeDef) => record?.user?.phone_number}
      />
      <Table.Column
        title="Recharge Amount"
        key="amount"
        dataIndex="recharge_amount"
        align="right"
        render={(_, record: RechargeDef) => record?.amount}
      />
    </TableView>
  );
};

export default ManagerRechargeHistory;
