import { Col, Row, Table, TablePaginationConfig, Typography } from "antd";
import { FilterValue, SorterResult } from "antd/lib/table/interface";
import cx from "classnames";
import dayjs from "dayjs";
import { useParams, Link } from "react-router-dom";

import TableView, {
  TableViewProps,
} from "@app/components/molecules/TableView/TableView";
import { TABLE_SCROLL_HEIGHT_7_PLUS_ROWS } from "@app/constants/table.constants";
import { DEFAULT_DATE_FORMAT } from "@app/constants/time.constants";
import { ManagerPathsEnum } from "@app/features/manager/manager";
import { SortEnum } from "@app/types/table.types";

import useUserHistories from "../../hooks/useUserHistories";
import { PurchaseHistoryDef, UserDef } from "../../user";
import UserPurchaseFilter from "../UserHistoriesFilter/UserHistoriesFilter";
import styles from "./UserPurchaseTable.module.scss";

const { Text, Title } = Typography;

type UserPurchaseTableProps = TableViewProps<PurchaseHistoryDef>;
interface UsersPurchaseTableParams {
  id?: string;
}

const UserPurchaseTable = ({ className, ...props }: UserPurchaseTableProps) => {
  const params = useParams<UsersPurchaseTableParams>();
  const {
    purchaseHistoryLoading,
    getSortOrder,
    setCurrentSort,
    purchaseHistory,
    usageCount,
    getUpdatedUserPurchaseList,
  } = useUserHistories();

  const userId = Number(params.id);

  const parseSort = (
    sorter:
      | SorterResult<PurchaseHistoryDef>
      | SorterResult<PurchaseHistoryDef>[]
  ) => {
    if (!Array.isArray(sorter)) {
      const { order } = sorter;

      if (order) {
        return order === "ascend" ? SortEnum.ASC : SortEnum.DESC;
      }
    }

    return undefined;
  };

  const parseOrderBy = (
    sorter:
      | SorterResult<PurchaseHistoryDef>
      | SorterResult<PurchaseHistoryDef>[]
  ) => {
    if (!Array.isArray(sorter)) {
      const { order } = sorter;

      if (order) {
        return sorter.columnKey === "purchaseDate"
          ? "created_at"
          : sorter.columnKey;
      }
    }

    return undefined;
  };

  const handleUserTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter:
      | SorterResult<PurchaseHistoryDef>
      | SorterResult<PurchaseHistoryDef>[]
  ) => {
    if (!purchaseHistoryLoading) {
      getUpdatedUserPurchaseList({
        id: userId,
        sort: parseSort(sorter),
        orderBy: parseOrderBy(sorter) as string,
      });
    }

    if (!Array.isArray(sorter)) {
      setCurrentSort(sorter.order);
    }
  };

  const renderRechargedBy = (record: UserDef, rechargedBy: UserDef) => {
    return rechargedBy ? (
      <Link
        to={`${ManagerPathsEnum.MANAGER_RECHARGE_HISTORY}/${record.id}/manager-recharge-history`}
      >
        <Text className="link-color" title={record.first_name}>
          {record.first_name} {record.last_name}
        </Text>
      </Link>
    ) : (
      "Wonself"
    );
  };

  return (
    <TableView<PurchaseHistoryDef>
      dataSource={purchaseHistory}
      loading={purchaseHistoryLoading}
      actionTitle="Action"
      title={() => (
        <>
          <UserPurchaseFilter />
          <Row justify="space-between">
            <Col xs={24} className={styles.titleWrapper}>
              <Title level={4}>Recharge History</Title>
              <Text className={styles.usageCountWrapper}>
                <span className="font-weight-bold">Total Usage:</span>
                <span className={styles.usageCount}>{usageCount}</span>
              </Text>
            </Col>
          </Row>
        </>
      )}
      onChange={handleUserTableChange}
      className={cx([className, "tableHasTitle"])}
      scroll={{ y: TABLE_SCROLL_HEIGHT_7_PLUS_ROWS }}
      hideActionColumn
      hidePagination
      {...props}
    >
      <Table.Column
        title="ID"
        key="id"
        dataIndex="id"
        render={(id: string) => id}
      />
      <Table.Column
        title="Transaction ID"
        key="transactionId"
        dataIndex="trxID"
        render={(transactionId: string) => transactionId}
      />
      <Table.Column
        title="Purchase Date"
        key="purchaseDate"
        dataIndex="created_at"
        render={(purchaseDate: string) =>
          dayjs(purchaseDate).format(DEFAULT_DATE_FORMAT)
        }
        sorter
        sortOrder={getSortOrder("purchaseDate")}
      />
      <Table.Column
        title="Package Amount"
        key="packageAmount"
        dataIndex="amount"
        render={(packageAmount: string) => packageAmount}
      />
      <Table.Column
        title="Recharged By"
        key="rechargedBy"
        dataIndex="recharged_by"
        render={(record: UserDef, rechargedBy: UserDef) =>
          renderRechargedBy(record, rechargedBy)
        }
      />
    </TableView>
  );
};

export default UserPurchaseTable;
