import ContentLayout from "@app/components/layouts/ContentLayout/ContentLayout";

import FeedbacksTable from "../../components/FeedbacksTable/FeedbacksTable";

const FacilityFeedbacksScreen = () => {
  return (
    <ContentLayout header={{ title: "Facility Feedbacks" }}>
      <FeedbacksTable />
    </ContentLayout>
  );
};

export default FacilityFeedbacksScreen;
