import { GenderEnum } from "@app/constants/gender.constants";

export const renderGender = (gender?: string) => {
  const loweredCaseGender = gender?.toLocaleLowerCase();

  if (loweredCaseGender === GenderEnum.MALE) {
    return "Male";
  }
  if (loweredCaseGender === GenderEnum.FEMALE) {
    return "Female";
  }
  if (loweredCaseGender === GenderEnum.OTHER) {
    return "Other";
  }

  return "";
};
