import { Col, Row, Table, TablePaginationConfig, Typography } from "antd";
import { FilterValue, SorterResult } from "antd/lib/table/interface";
import cx from "classnames";
import { useParams } from "react-router-dom";

import TableView, {
  TableViewProps,
} from "@app/components/molecules/TableView/TableView";
import { TABLE_SCROLL_HEIGHT_7_PLUS_ROWS } from "@app/constants/table.constants";
import { SortEnum } from "@app/types/table.types";

import useUserHistories from "../../hooks/useUserHistories";
import { PackageSubscriptionHistoryDef } from "../../user";

const { Title } = Typography;

type PackageSubscriptionHistoryTableProps =
  TableViewProps<PackageSubscriptionHistoryDef>;
interface PackageSubscriptionHistoryTableParams {
  id?: string;
}

const PackageSubscriptionHistoryTable = ({
  className,
  ...props
}: PackageSubscriptionHistoryTableProps) => {
  const params = useParams<PackageSubscriptionHistoryTableParams>();
  const {
    packageSubscriptionHistoryLoading,
    setCurrentSort,
    packageSubscriptionHistory,
    getUpdatedPackageSubscriptionList,
  } = useUserHistories();

  const userId = Number(params.id);

  const parseSort = (
    sorter:
      | SorterResult<PackageSubscriptionHistoryDef>
      | SorterResult<PackageSubscriptionHistoryDef>[]
  ) => {
    if (!Array.isArray(sorter)) {
      const { order } = sorter;

      if (order) {
        return order === "ascend" ? SortEnum.ASC : SortEnum.DESC;
      }
    }

    return undefined;
  };

  const parseOrderBy = (
    sorter:
      | SorterResult<PackageSubscriptionHistoryDef>
      | SorterResult<PackageSubscriptionHistoryDef>[]
  ) => {
    if (!Array.isArray(sorter)) {
      const { order } = sorter;

      if (order) {
        return sorter.columnKey === "purchaseDate"
          ? "created_at"
          : sorter.columnKey;
      }
    }

    return undefined;
  };

  const handleUserTableChange = (
    pagination: TablePaginationConfig | null,
    filters: Record<string, FilterValue | null>,
    sorter:
      | SorterResult<PackageSubscriptionHistoryDef>
      | SorterResult<PackageSubscriptionHistoryDef>[]
  ) => {
    if (!packageSubscriptionHistoryLoading) {
      getUpdatedPackageSubscriptionList({
        id: userId,
        sort: parseSort(sorter),
        orderBy: parseOrderBy(sorter) as string,
      });
    }

    if (!Array.isArray(sorter)) {
      setCurrentSort(sorter.order);
    }
  };

  return (
    <TableView<PackageSubscriptionHistoryDef>
      dataSource={packageSubscriptionHistory}
      loading={packageSubscriptionHistoryLoading}
      actionTitle="Action"
      title={() => (
        <>
          <Row justify="space-between">
            <Col xs={20}>
              <Title level={4}>Package History</Title>
            </Col>
          </Row>
        </>
      )}
      onChange={handleUserTableChange}
      className={cx([className, "tableHasTitle"])}
      scroll={{ y: TABLE_SCROLL_HEIGHT_7_PLUS_ROWS }}
      hideActionColumn
      hidePagination
      {...props}
    >
      <Table.Column
        title="Package Title"
        key="title"
        dataIndex="subscription_package"
        render={subscriptionPackage => subscriptionPackage?.title}
      />
      <Table.Column
        title="Facility Name"
        key="facilityName"
        dataIndex="subscription_package"
        render={subscriptionPackage => subscriptionPackage?.facility?.name}
      />
      <Table.Column
        title="Validity(Days)"
        key="days"
        dataIndex="subscription_package"
        render={subscriptionPackage => subscriptionPackage?.days}
      />
      <Table.Column
        title="Usage(Services)"
        key="usages"
        dataIndex="subscription_package"
        render={subscriptionPackage => subscriptionPackage?.usages}
      />
      <Table.Column
        title="Amount"
        key="amount"
        dataIndex="subscription_package"
        render={subscriptionPackage => subscriptionPackage?.amount}
      />
    </TableView>
  );
};

export default PackageSubscriptionHistoryTable;
