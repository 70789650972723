import { memo, useEffect } from "react";

import { Input, Row, Col, FormInstance, message } from "antd";

import Form, { Item } from "@app/components/atoms/Form/Form";
import {
  DEFAULT_INPUT_LENGTH,
  DEFAULT_NUMBER_INPUT_PATTERN,
} from "@app/constants/input.constants";
import { useAppDispatch } from "@app/redux/store";
import { HasMessage } from "@app/types/api.types";

import { addExpense, getExpenseHistory, modifyExpense } from "../../facility";

type ExpenseFormDef = {
  facilityId: number;
  id?: number;
  title?: string;
  type?: string;
  amount?: number;
};

export interface ExpenseFormProps {
  initialValues?: ExpenseFormDef;
  editMode?: boolean;
  form?: FormInstance;
  handleClose?: () => void;
}

const ExpenseForm = ({
  initialValues,
  form,
  editMode,
  handleClose,
}: ExpenseFormProps) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (initialValues && !form?.isFieldsTouched()) {
      form?.setFieldsValue(initialValues);
    }
  }, [initialValues, form]);

  const addFacilityExpense = async () => {
    const response = await dispatch(
      addExpense({
        id: Number(initialValues?.facilityId),
        payload: {
          title: form?.getFieldValue("title"),
          type: form?.getFieldValue("type"),
          amount: form?.getFieldValue("amount"),
        },
      })
    );

    if (addExpense.fulfilled.match(response)) {
      message.success("Expense added successfully");
      dispatch(getExpenseHistory());
      handleClose?.();
    } else {
      message.error((response.payload as HasMessage)?.message);
    }
  };

  const handleAdd = () => {
    addFacilityExpense();
  };

  const updateFacilityExpense = async () => {
    const response = await dispatch(
      modifyExpense({
        id: Number(initialValues?.id),
        payload: {
          title: form?.getFieldValue("title"),
          type: form?.getFieldValue("type"),
          amount: form?.getFieldValue("amount"),
        },
      })
    );

    if (modifyExpense.fulfilled.match(response)) {
      message.success("Expense updated successfully");
      dispatch(getExpenseHistory());
      handleClose?.();
    } else {
      message.error((response.payload as HasMessage)?.message);
    }
  };

  const handleEdit = () => {
    updateFacilityExpense();
  };

  return (
    <Row justify="space-between">
      <Col xs={24}>
        <Form form={form} onFinish={editMode ? handleEdit : handleAdd}>
          <Item
            name="title"
            label="Title"
            rules={[
              {
                required: true,
                whitespace: true,
                message: "Please enter the title!",
              },
              {
                max: DEFAULT_INPUT_LENGTH,
                message: `Input length can't exceed ${DEFAULT_INPUT_LENGTH} characters!`,
              },
            ]}
          >
            <Input />
          </Item>

          <Item
            name="type"
            label="Type"
            rules={[
              {
                required: true,
                whitespace: true,
                message: "Please enter the type!",
              },
              {
                max: DEFAULT_INPUT_LENGTH,
                message: `Input length can't exceed ${DEFAULT_INPUT_LENGTH} characters!`,
              },
            ]}
          >
            <Input />
          </Item>

          <Item
            name="amount"
            label="Amount"
            rules={[
              {
                required: true,
                message: "Please enter the amount!",
              },
              {
                pattern: DEFAULT_NUMBER_INPUT_PATTERN,
                message:
                  "Input can't exceed 12 digits with 2 decimal point max!",
              },
            ]}
          >
            <Input type="number" />
          </Item>
        </Form>
      </Col>
    </Row>
  );
};

export default memo(ExpenseForm);
