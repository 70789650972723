import { useEffect } from "react";

import { Table, TablePaginationConfig, Typography } from "antd";
import { FilterValue, SorterResult } from "antd/lib/table/interface";
import dayjs from "dayjs";
import { useLocation } from "react-router-dom";
import { usePrevious } from "react-use";

import TableView, {
  TableViewProps,
} from "@app/components/molecules/TableView/TableView";
import { DEFAULT_DATE_TIME_FORMAT } from "@app/constants/time.constants";
import { SortEnum } from "@app/types/table.types";

import useFeedbackList from "../../hooks/useFeedbackList";
import { FeedbackDef } from "../../types/facility.types";

type FeedbacksTableProps = TableViewProps<FeedbackDef>;

const { Text } = Typography;

const FeedbacksTable = ({ ...props }: FeedbacksTableProps) => {
  const location = useLocation();
  const prevLocation = usePrevious(location);
  const {
    paginationData,
    loading,
    getInitialFeedbackList,
    getUpdatedFeedbackList,
    facilityId,
    getSortOrder,
    setCurrentSort,
    facilityFeedbacks,
  } = useFeedbackList();

  useEffect(() => {
    // Fetch feedback list on initial load
    if (prevLocation?.search === undefined) {
      getInitialFeedbackList();
    }
  }, [getInitialFeedbackList, prevLocation?.search]);

  const parseSort = (
    sorter: SorterResult<FeedbackDef> | SorterResult<FeedbackDef>[]
  ) => {
    if (!Array.isArray(sorter)) {
      const { order } = sorter;

      if (order) {
        return order === "ascend" ? SortEnum.ASC : SortEnum.DESC;
      }
    }

    return undefined;
  };

  const parseOrderBy = (
    sorter: SorterResult<FeedbackDef> | SorterResult<FeedbackDef>[]
  ) => {
    if (!Array.isArray(sorter)) {
      const { order } = sorter;

      if (order) {
        return sorter.columnKey === "time" ? "created_at" : sorter.columnKey;
      }
    }

    return undefined;
  };

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<FeedbackDef> | SorterResult<FeedbackDef>[]
  ) => {
    if (!loading) {
      getUpdatedFeedbackList({
        facility_id: Number(facilityId),
        page: pagination.current,
        per_page: pagination.pageSize,
        sort: parseSort(sorter),
        order_by: parseOrderBy(sorter) as string,
      });
    }

    if (!Array.isArray(sorter)) {
      setCurrentSort(sorter.order);
    }
  };

  return (
    <TableView<FeedbackDef>
      dataSource={facilityFeedbacks}
      loading={loading}
      actionTitle="Action"
      pagination={{
        current: paginationData?.currentPage,
        pageSize: paginationData?.perPage,
        total: paginationData?.total,
        showSizeChanger: true,
        pageSizeOptions: ["6", "8", "10", "15", "25"],
      }}
      onChange={handleTableChange}
      hideActionColumn
      {...props}
    >
      <Table.Column
        title="Feedback Title"
        key="title"
        dataIndex="title"
        render={(title: string) => (
          <Text style={{ width: "10rem" }} ellipsis={{ tooltip: title }}>
            {title}
          </Text>
        )}
      />
      <Table.Column
        title="User Name"
        key="name"
        dataIndex="user"
        render={(name: string, record: FeedbackDef) =>
          `${record.user.first_name} ${record.user.last_name}`
        }
      />
      <Table.Column
        title="Comment"
        key="comment"
        dataIndex="comment"
        render={(comment: string) => (
          <Text style={{ width: "10rem" }} ellipsis={{ tooltip: comment }}>
            {comment}
          </Text>
        )}
      />
      <Table.Column
        title="Rating"
        key="rating"
        dataIndex="rating"
        render={(rating: string) => Number(rating).toFixed(2)}
      />
      <Table.Column
        title="Time"
        key="time"
        dataIndex="created_at"
        render={(time: string) =>
          time ? dayjs(time).format(DEFAULT_DATE_TIME_FORMAT) : ""
        }
        sorter
        sortOrder={getSortOrder("time")}
      />
    </TableView>
  );
};

export default FeedbacksTable;
