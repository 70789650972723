import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { RootState } from "@app/redux/root-reducer";

import {
  createPackage,
  getPackageList,
  togglePackageStatus,
} from "../api/package.api";
import {
  PACKAGE_FEATURE_KEY,
  InitialStateDef,
  packageErrorHelper,
  CreatePackageDef,
  GetPackageListDef,
  TogglePackageStatusDef,
} from "../package";

const initialState: InitialStateDef = {
  error: false,
  loading: false,
  statusLoading: false,
  packageList: [],
  currentParams: {
    page: undefined,
    perPage: undefined,
    searchQuery: undefined,
    sort: undefined,
    orderBy: undefined,
  },
};

export const getAllPackages = createAsyncThunk(
  `${PACKAGE_FEATURE_KEY}/getAllPackages`,
  async (payload: undefined, { rejectWithValue, getState }) => {
    const { subscriptionPackage } = getState() as RootState;

    try {
      const response = await getPackageList(subscriptionPackage.currentParams);

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const toggleStatus = createAsyncThunk(
  `${PACKAGE_FEATURE_KEY}/toggleStatus`,
  async (payload: TogglePackageStatusDef, { rejectWithValue }) => {
    try {
      const response = await togglePackageStatus(payload);

      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const createAPackage = createAsyncThunk(
  `${PACKAGE_FEATURE_KEY}/createPackage`,
  async (payload: CreatePackageDef, { rejectWithValue }) => {
    try {
      const response = await createPackage(payload);

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const packageSlice = createSlice({
  name: PACKAGE_FEATURE_KEY,
  initialState,
  reducers: {
    updateCurrentParams(state, action: PayloadAction<GetPackageListDef>) {
      state.currentParams = {
        ...state.currentParams,
        ...action.payload,
      };
    },
    clearPackageDetails(state) {
      state.packageDetails = undefined;
    },
  },
  extraReducers: builder => {
    /**
     * GET PACKAGE LIST
     */
    builder.addCase(getAllPackages.pending, state => {
      state.error = false;
      state.loading = true;
    });
    builder.addCase(getAllPackages.fulfilled, (state, action) => {
      const { data, meta } = action.payload;

      state.loading = false;
      state.packageList = data;
      state.paginationData = {
        currentPage: meta?.current_page,
        perPage: meta?.per_page,
        total: meta?.total,
      };
    });
    builder.addCase(getAllPackages.rejected, state => {
      packageErrorHelper(state);
    });
    /**
     * TOGGLE PACKAGE STATUS
     */
    builder.addCase(toggleStatus.pending, state => {
      state.error = false;
      state.statusLoading = true;
    });
    builder.addCase(toggleStatus.fulfilled, state => {
      state.statusLoading = false;
    });
    builder.addCase(toggleStatus.rejected, state => {
      packageErrorHelper(state);
    });
    /**
     * CREATE PACKAGE
     */
    builder.addCase(createAPackage.pending, state => {
      state.error = false;
      state.loading = true;
    });
    builder.addCase(createAPackage.fulfilled, state => {
      state.loading = false;
    });
    builder.addCase(createAPackage.rejected, state => {
      packageErrorHelper(state);
    });
  },
});

export const { updateCurrentParams, clearPackageDetails } =
  packageSlice.actions;

export const packageReducer = packageSlice.reducer;
