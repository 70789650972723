import ContentLayout from "@app/components/layouts/ContentLayout/ContentLayout";

import AdminsTable from "../../components/AdminsTable/AdminsTable";

const AdminListScreen = () => {
  return (
    <ContentLayout header={{ title: "Admin List" }}>
      <AdminsTable />
    </ContentLayout>
  );
};

export default AdminListScreen;
