import { AxiosResponse } from "axios";

import { api } from "@app/api/api";

import { PackageEndpointsEnum } from "../constants/package.endpoints";
import {
  CreatePackageDef,
  GetPackageListDef,
  TogglePackageStatusDef,
} from "../package";

export const getPackageList = (
  data: GetPackageListDef
): Promise<AxiosResponse> => {
  return api.get(PackageEndpointsEnum.GET_PACKAGE_LIST, {
    params: {
      page: data.page,
      per_page: data.perPage,
      search: data.searchQuery,
      facility_id: data.facilityId,
      sort: data.sort,
      order_by: data.orderBy,
    },
  });
};

export const togglePackageStatus = (
  data: TogglePackageStatusDef
): Promise<AxiosResponse> => {
  return api.post(
    `${PackageEndpointsEnum.TOGGLE_PACKAGE_STATUS}/${data.package_id}/status`
  );
};

export const createPackage = (
  payload: CreatePackageDef
): Promise<AxiosResponse> => {
  return api.post(PackageEndpointsEnum.CREATE_PACKAGE, { ...payload });
};
