import { message } from "antd";
import { FormInstance } from "antd/es/form/Form";
import dayjs from "dayjs";

import { DEFAULT_API_DATE_FORMAT } from "@app/constants/time.constants";
import {
  getBusinessOverviewData,
  getTotalIncomeBySubscriptionsData,
} from "@app/features/dashboard/dashboard";
import useSearchParams, { SearchParamDef } from "@app/hooks/useSearchParams";
import { useAppDispatch, useAppSelector } from "@app/redux/store";

type SearchQueryParamDef = {
  dateRange?: [string, string];
};

const useBusinessOverview = (form: FormInstance) => {
  const { updateSearchParams, search } =
    useSearchParams<SearchParamDef<SearchQueryParamDef>>();
  const dispatch = useAppDispatch();
  const { loading, businessOverview, totalIncomeSubscriptions } =
    useAppSelector(state => ({
      loading: state.dashboard.loading,
      businessOverview: state.dashboard.businessOverview,
      totalIncomeSubscriptions: state.dashboard.totalIncomeSubscriptions,
    }));

  const getBusinessOverview = async (initialDateRange?: [string?, string?]) => {
    const dateRange = form.getFieldValue("dateRange") || undefined;
    let from;
    let to;

    if (
      !dateRange?.[0] &&
      !dateRange?.[1] &&
      !initialDateRange?.[0] &&
      !initialDateRange?.[1]
    ) {
      from = undefined;
      to = undefined;
    } else {
      from = initialDateRange?.[0]
        ? dayjs(initialDateRange?.[0]).format(DEFAULT_API_DATE_FORMAT)
        : dayjs(dateRange?.[0]).format(DEFAULT_API_DATE_FORMAT);
      to = initialDateRange?.[1]
        ? dayjs(initialDateRange?.[1]).format(DEFAULT_API_DATE_FORMAT)
        : dayjs(dateRange?.[1]).format(DEFAULT_API_DATE_FORMAT);
    }

    const payload = {
      from,
      to,
    };

    const response = await dispatch(getBusinessOverviewData(payload));
    const totalIncomeBySubscriptionResponse = await dispatch(
      getTotalIncomeBySubscriptionsData(payload)
    );

    if (
      !getBusinessOverviewData.fulfilled.match(response) ||
      !getTotalIncomeBySubscriptionsData.fulfilled.match(
        totalIncomeBySubscriptionResponse
      )
    ) {
      message.error("Business overview data fetching failed");
    }
  };

  return {
    getBusinessOverview,
    loading,
    businessOverview,
    totalIncomeSubscriptions,
    search,
    updateSearchParams,
  };
};

export default useBusinessOverview;
