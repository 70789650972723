import { useEffect } from "react";

import { Input, Row, Col, message } from "antd";
import { useHistory } from "react-router-dom";

import Button from "@app/components/atoms/Button/Button";
import Form, { Item, useForm } from "@app/components/atoms/Form/Form";
import {
  DEFAULT_INPUT_LENGTH,
  DEFAULT_NUMBER_INPUT_PATTERN,
} from "@app/constants/input.constants";
import { useAppDispatch } from "@app/redux/store";
import { HasMessage } from "@app/types/api.types";

import { createAmount, RechargePathsEnum } from "../../recharge";

type AmountFormDef = {
  id?: number;
  title: string;
  amount: number;
};

export interface AmountFormProps {
  initialValues?: AmountFormDef;
  loading?: boolean;
  editMode?: boolean;
}

const AmountForm = ({ initialValues, editMode, loading }: AmountFormProps) => {
  const history = useHistory();
  const [form] = useForm();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (initialValues && !form.isFieldsTouched()) {
      form.setFieldsValue(initialValues);
    }
  }, [initialValues, form, dispatch]);

  const createRechargeAmount = async () => {
    const { getFieldValue } = form;

    const response = await dispatch(
      createAmount({
        title: getFieldValue("title"),
        title_bn: getFieldValue("titleBn"),
        amount: getFieldValue("amount"),
      })
    );

    if (createAmount.fulfilled.match(response)) {
      message.success("Recharge amount created successfully");
      history.push(RechargePathsEnum.AMOUNT_LIST);
    } else {
      message.error((response.payload as HasMessage)?.message);
    }
  };

  const updateAmount = async () => {
    // TODO: Update API call
  };

  const handleUpdate = () => {
    updateAmount();
  };

  const handleCreate = () => {
    createRechargeAmount();
  };

  return (
    <Row justify="space-between">
      <Col xs={8}>
        <Form form={form} onFinish={editMode ? handleUpdate : handleCreate}>
          <Item
            name="title"
            label="Title"
            rules={[
              {
                required: true,
                whitespace: true,
                message: "Please enter the title!",
              },
              {
                max: DEFAULT_INPUT_LENGTH,
                message: `Input length can't exceed ${DEFAULT_INPUT_LENGTH} characters!`,
              },
            ]}
          >
            <Input />
          </Item>

          <Item
            name="titleBn"
            label="Title (Bengali)"
            rules={[
              {
                required: true,
                whitespace: true,
                message: "Please enter the title!",
              },
              {
                max: DEFAULT_INPUT_LENGTH,
                message: `Input length can't exceed ${DEFAULT_INPUT_LENGTH} characters!`,
              },
            ]}
          >
            <Input />
          </Item>

          <Item
            name="amount"
            label="Amount"
            rules={[
              {
                required: true,
                message: "Please enter the amount!",
              },
              {
                pattern: DEFAULT_NUMBER_INPUT_PATTERN,
                message:
                  "Input can't exceed 12 digits with 2 decimal point max!",
              },
            ]}
          >
            <Input type="number" />
          </Item>

          <Item>
            <Button disabled={loading} type="primary" htmlType="submit">
              {editMode ? "Update" : "Create"}
            </Button>
          </Item>
        </Form>
      </Col>
    </Row>
  );
};

export default AmountForm;
