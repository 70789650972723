import { ExportOutlined } from "@ant-design/icons";
import { Table, TablePaginationConfig, Tooltip } from "antd";
import { FilterValue, SorterResult } from "antd/lib/table/interface";
import { useHistory } from "react-router-dom";

import Button from "@app/components/atoms/Button/Button";
import TableView, {
  TableViewProps,
} from "@app/components/molecules/TableView/TableView";
import { renderGender } from "@app/helpers/gender.helper";
import { exportTableData } from "@app/helpers/table.helper";
import { SortEnum } from "@app/types/table.types";

import useUserList from "../../hooks/useUserList";
import { UserDef } from "../../types/user.types";
import { UserPathsEnum } from "../../user";
import UsersFilter from "../UsersFilter/UsersFilter";
import styles from "./UsersTable.module.scss";

interface UserTableProps extends TableViewProps<UserDef> {
  onAdd?: () => void;
}

const UsersTable = ({ ...props }: UserTableProps) => {
  const history = useHistory();
  const {
    userList,
    paginationData,
    loading,
    getUpdatedUserList,
    getSortOrder,
    setCurrentSort,
  } = useUserList();

  const parseSort = (
    sorter: SorterResult<UserDef> | SorterResult<UserDef>[]
  ) => {
    if (!Array.isArray(sorter)) {
      const { order } = sorter;

      if (order) {
        return order === "ascend" ? SortEnum.ASC : SortEnum.DESC;
      }
    }

    return undefined;
  };

  const parseOrderBy = (
    sorter: SorterResult<UserDef> | SorterResult<UserDef>[]
  ) => {
    if (!Array.isArray(sorter)) {
      const { order } = sorter;

      if (order) {
        return sorter.columnKey === "name" ? "first_name" : sorter.columnKey;
      }
    }

    return undefined;
  };

  const handleUserTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<UserDef> | SorterResult<UserDef>[]
  ) => {
    if (!loading) {
      getUpdatedUserList({
        page: pagination.current,
        perPage: pagination.pageSize,
        sort: parseSort(sorter),
        orderBy: parseOrderBy(sorter),
      });
    }

    if (!Array.isArray(sorter)) {
      setCurrentSort(sorter.order);
    }
  };

  const handleView = (user: UserDef) => {
    history.push(`${UserPathsEnum.USER_DETAILS}/${user.id}`, { user });
  };

  const data = userList?.map(user => ({
    ...user,
    name: `${user.first_name} ${user.last_name}`,
  }));

  const handleExport = () => {
    const header = ["User's Name", "Gender", "Phone No", "Email", "Balance"];
    const exportData = data.map(item => ({
      name: `${item.first_name} ${item.last_name}`,
      gender: renderGender(item.gender),
      phone: item.phone_number,
      email: item.email,
      balance: String(item.balance),
    }));

    exportTableData(exportData, `user_list_${Date.now()}`, [header]);
  };

  return (
    <TableView<UserDef>
      dataSource={data}
      loading={loading}
      actionTitle="Action"
      pagination={{
        current: paginationData?.currentPage,
        pageSize: paginationData?.perPage,
        total: paginationData?.total,
        showSizeChanger: true,
        pageSizeOptions: ["6", "8", "10", "15", "25"],
      }}
      title={() => (
        <div className={styles.userTableTitle}>
          <UsersFilter />

          <div>
            <Tooltip title={!data.length ? "There is no data to export" : ""}>
              <span>
                <Button
                  type="primary"
                  onClick={handleExport}
                  icon={<ExportOutlined />}
                  disabled={!data.length}
                >
                  Export Data
                </Button>
              </span>
            </Tooltip>
          </div>
        </div>
      )}
      onChange={handleUserTableChange}
      onView={handleView}
      {...props}
    >
      <Table.Column
        title="Name"
        key="name"
        dataIndex="name"
        render={(name: string) => name}
        sorter
        sortOrder={getSortOrder("name")}
      />
      <Table.Column
        title="Phone"
        key="phone"
        dataIndex="phone_number"
        render={(phone: string) => phone}
      />
      <Table.Column
        title="Email"
        key="email"
        dataIndex="email"
        render={(email: string) => email}
      />
      <Table.Column
        title="Balance"
        key="balance"
        dataIndex="balance"
        render={(balance: string) => balance}
      />
    </TableView>
  );
};

export default UsersTable;
