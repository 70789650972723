import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { Card, Col, Input, message, Row } from "antd";

import Button from "@app/components/atoms/Button/Button";
import Form, { Item, useForm } from "@app/components/atoms/Form/Form";
import { DEFAULT_INPUT_LENGTH } from "@app/constants/input.constants";
import { LoginRequestDef } from "@app/features/auth/auth";
import { useAppDispatch, useAppSelector } from "@app/redux/store";

import useRedirectAfterLogin from "../../hooks/useRedirectAfterLogin";
import { login } from "../../redux/auth.slice";
import styles from "./LoginScreen.module.scss";

const LoginScreen = () => {
  const [form] = useForm();
  const dispatch = useAppDispatch();
  const loading = useAppSelector(state => state.auth.loading);
  useRedirectAfterLogin();

  const handleFinish = async (values: LoginRequestDef) => {
    const response = await dispatch(login(values));
    if (login.fulfilled.match(response)) {
      message.success("Login successful");
    } else {
      message.error("Invalid Credentials");
    }
  };

  return (
    <Row justify="center" align="middle" className={styles.container}>
      <Col xs={24} sm={12} lg={6}>
        <Card title="Login">
          <Form form={form} onFinish={handleFinish}>
            <Item
              name="email"
              label="Email"
              rules={[
                {
                  type: "email",
                  required: true,
                  whitespace: true,
                  message: "Please input your email!",
                },
                {
                  max: DEFAULT_INPUT_LENGTH,
                  message: `Input length can't exceed ${DEFAULT_INPUT_LENGTH} characters!`,
                },
              ]}
            >
              <Input
                prefix={<UserOutlined />}
                type="text"
                placeholder="Enter your email"
              />
            </Item>
            <Item
              name="password"
              label="Password"
              rules={[
                {
                  required: true,
                  whitespace: true,
                  message: "Please input your password!",
                },
                {
                  max: DEFAULT_INPUT_LENGTH,
                  message: `Input length can't exceed ${DEFAULT_INPUT_LENGTH} characters!`,
                },
              ]}
            >
              <Input.Password
                prefix={<LockOutlined />}
                placeholder="Enter your password"
              />
            </Item>
            <Item>
              <Button type="primary" htmlType="submit" loading={loading}>
                Login
              </Button>
            </Item>
          </Form>
        </Card>
      </Col>
    </Row>
  );
};

export default LoginScreen;
