import ContentLayout from "@app/components/layouts/ContentLayout/ContentLayout";

import FacilityForm from "../../components/FacilityForm/FacilityForm";

const FacilityCreateScreen = () => {
  return (
    <ContentLayout header={{ title: "Facility Create" }}>
      <FacilityForm />
    </ContentLayout>
  );
};

export default FacilityCreateScreen;
