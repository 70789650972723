/* eslint-disable no-nested-ternary */
import { useEffect } from "react";

import { Input, Row, Col, message } from "antd";
import { useHistory } from "react-router-dom";
import { useUnmount } from "react-use";

import Button from "@app/components/atoms/Button/Button";
import Form, { Item, useForm } from "@app/components/atoms/Form/Form";
import {
  DEFAULT_INPUT_LENGTH,
  DEFAULT_BD_PHONE_NO_PATTERN,
} from "@app/constants/input.constants";
import { useAppDispatch } from "@app/redux/store";
import { HasMessage } from "@app/types/api.types";

import {
  registerManager,
  ManagerPathsEnum,
  clearManagerDetails,
  modifyManager,
} from "../../manager";

type ManagerFormDef = {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  cardId: string;
};

export interface ManagerFormProps {
  initialValues?: ManagerFormDef;
  loading?: boolean;
  editMode?: boolean;
}

const ManagerForm = ({
  initialValues,
  editMode,
  loading,
}: ManagerFormProps) => {
  const history = useHistory();
  const [form] = useForm();
  const dispatch = useAppDispatch();
  const { getFieldValue } = form;
  const getCleanedPrefixedBdPhoneNumber = () =>
    // required rectifications of BD phone number prefix for handling api validation exceptions
    getFieldValue("phone")
      ?.replace(/^(\+|00)(88)?/, "+88")
      ?.replace(/^01/, "+8801")
      ?.replace(/[-\s]|^00/g, "")
      ?.replace(/^(\+)(.+)/, "$1$2");

  useUnmount(() => {
    dispatch(clearManagerDetails());
  });

  useEffect(() => {
    if (initialValues && !form.isFieldsTouched()) {
      form.setFieldsValue(initialValues);
    }
  }, [initialValues, form]);

  const createManager = async () => {
    const response = await dispatch(
      registerManager({
        first_name: getFieldValue("firstName"),
        last_name: getFieldValue("lastName"),
        email: getFieldValue("email"),
        phone_number: getCleanedPrefixedBdPhoneNumber(),
        card_id: getFieldValue("cardId"),
      })
    );

    if (registerManager.fulfilled.match(response)) {
      message.success("Manager created successfully");
      history.push(ManagerPathsEnum.MANAGER_LIST);
    } else {
      message.error((response.payload as HasMessage)?.message);
    }
  };

  const updateManager = async () => {
    const response = await dispatch(
      modifyManager({
        id: getFieldValue("id"),
        payload: {
          first_name: getFieldValue("firstName"),
          last_name: getFieldValue("lastName"),
          phone_number:
            getFieldValue("phone") !== initialValues?.phone
              ? getCleanedPrefixedBdPhoneNumber()
              : "",
          card_id:
            getFieldValue("cardId") !== initialValues?.cardId
              ? getFieldValue("cardId")
              : undefined,
        },
      })
    );

    if (modifyManager.fulfilled.match(response)) {
      message.success("Manager updated successfully");
      history.push(ManagerPathsEnum.MANAGER_LIST);
    } else {
      message.error((response.payload as HasMessage)?.message);
    }
  };

  const handleUpdate = () => {
    updateManager();
  };

  const handleCreate = () => {
    createManager();
  };

  return (
    <Row justify="space-between">
      <Col xs={8}>
        <Form form={form} onFinish={editMode ? handleUpdate : handleCreate}>
          <Item
            name="firstName"
            label="First Name"
            rules={[
              {
                required: true,
                whitespace: true,
                message: "Please enter the first name!",
              },
              {
                max: DEFAULT_INPUT_LENGTH,
                message: `Input length can't exceed ${DEFAULT_INPUT_LENGTH} characters!`,
              },
            ]}
          >
            <Input placeholder="Enter your first name" />
          </Item>

          <Item
            name="lastName"
            label="Last Name"
            rules={[
              {
                required: true,
                whitespace: true,
                message: "Please enter the last name!",
              },
              {
                max: DEFAULT_INPUT_LENGTH,
                message: `Input length can't exceed ${DEFAULT_INPUT_LENGTH} characters!`,
              },
            ]}
          >
            <Input placeholder="Enter your last name" />
          </Item>

          <Item
            name="email"
            label="E-mail"
            rules={[
              {
                type: "email",
                message: "The input is not valid e-mail!",
              },
              {
                required: true,
                message: "Please enter the e-mail!",
              },
              {
                max: DEFAULT_INPUT_LENGTH,
                message: `Input length can't exceed ${DEFAULT_INPUT_LENGTH} characters!`,
              },
            ]}
          >
            <Input
              type="email"
              placeholder="Enter your valid e-mail ID"
              disabled={editMode}
            />
          </Item>

          <Item
            name="phone"
            label="Phone Number"
            rules={[
              {
                required: true,
                message: "Please enter the phone number!",
              },
              {
                pattern: DEFAULT_BD_PHONE_NO_PATTERN,
                message: `Invalid phone no. or pattern!`,
              },
            ]}
          >
            <Input
              type="tel"
              defaultValue="+88"
              placeholder="Enter your valid mobile no."
            />
          </Item>

          <Item
            name="cardId"
            label="Card ID"
            rules={[
              {
                required: true,
                message: "Please enter the card id!",
              },
              {
                max: DEFAULT_INPUT_LENGTH,
                message: `Input length can't exceed ${DEFAULT_INPUT_LENGTH} characters!`,
              },
            ]}
          >
            <Input placeholder="Enter your manager's card ID" />
          </Item>

          <Item>
            <Button disabled={loading} type="primary" htmlType="submit">
              {editMode ? "Update" : "Create"}
            </Button>
          </Item>
        </Form>
      </Col>
    </Row>
  );
};

export default ManagerForm;
