import ContentLayout from "@app/components/layouts/ContentLayout/ContentLayout";

import AmountForm from "../../components/AmountForm/AmountForm";

const AmountCreationScreen = () => {
  return (
    <ContentLayout header={{ title: "Create New Amount" }}>
      <AmountForm />
    </ContentLayout>
  );
};

export default AmountCreationScreen;
