import { memo } from "react";

import { Select } from "antd";
import { useForm } from "antd/lib/form/Form";
import dayjs from "dayjs";

import PageFilter, {
  FilterItem,
} from "@app/components/molecules/PageFilter/PageFilter";
import {
  DEFAULT_API_DATE_FORMAT,
  DEFAULT_DATE_RANGE,
} from "@app/constants/time.constants";

import useExpenseHistory from "../../hooks/useExpenseHistory";
import styles from "./ExpenseFilter.module.scss";

export interface ExpenseFilterDef {
  dateRange?: string;
}

export interface ExpenseFilterProps {
  facilityId: number;
}

const { Option } = Select;

const ExpenseFilter = ({ facilityId }: ExpenseFilterProps) => {
  const [form] = useForm();
  const { loading, updateSearchParams, getUpdatedExpenseList } =
    useExpenseHistory();

  const search = () => {
    const currentDate = dayjs().format(DEFAULT_API_DATE_FORMAT);
    const dateRange = form.getFieldValue("dateRange") || DEFAULT_DATE_RANGE;

    updateSearchParams({
      dateRange,
      page: 1,
      orderBy: undefined,
      sort: undefined,
    }); // Resets search params
    getUpdatedExpenseList({
      from: dayjs(currentDate)
        .subtract(dateRange - 1, "days")
        .format(DEFAULT_API_DATE_FORMAT),
      to: currentDate,
      facilityId: String(facilityId),
      page: 1,
      orderBy: undefined,
      sort: undefined,
    });
  };

  const handleSearch = async () => {
    form.submit();

    if (!loading) {
      search();
    }
  };

  return (
    <PageFilter<ExpenseFilterDef> parseNumbers={["dateRange"]} form={form}>
      <FilterItem className={styles.filterLabel} name="dateRange">
        <Select
          placeholder="Select a date range"
          onSelect={handleSearch}
          className={styles.dateFilter}
          defaultValue={7}
        >
          <Option value={7}>Last 7 days</Option>
          <Option value={30}>Last 30 days</Option>
          <Option value={90}>Last 90 days</Option>
        </Select>
      </FilterItem>
    </PageFilter>
  );
};

export default memo(ExpenseFilter);
