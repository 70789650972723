import { ReactChild } from "react";

import { Dropdown as AntdDropdown, DropDownProps } from "antd";

interface DropdownAtomProps extends DropDownProps {
  children: ReactChild;
}

const Dropdown = ({ children, ...props }: DropdownAtomProps) => {
  return <AntdDropdown {...props}>{children}</AntdDropdown>;
};

export default Dropdown;
