import { Col, Row } from "antd";

import ContentLayout from "@app/components/layouts/ContentLayout/ContentLayout";

import PackageSubscriptionHistoryTable from "../../components/SubscriptionPackageHistoryTable/PackageSubscriptionHistoryTable";
import UserInfo from "../../components/UserInfo/UserInfo";
import UserPurchaseTable from "../../components/UserPurchaseTable/UserPurchaseTable";
import styles from "./UserDetailsScreen.module.scss";

const UserDetailsScreen = () => {
  return (
    <ContentLayout header={{ title: "User Details" }}>
      <Row>
        <Col xs={24}>
          <UserInfo />
        </Col>

        <Col xs={24}>
          <UserPurchaseTable className={styles.userPurchaseTable} />
        </Col>

        <Col xs={24}>
          <PackageSubscriptionHistoryTable
            className={styles.PackageSubscriptionHistoryTable}
          />
        </Col>
      </Row>
    </ContentLayout>
  );
};

export default UserDetailsScreen;
