import ContentLayout from "@app/components/layouts/ContentLayout/ContentLayout";

import FacilitiesTable from "../../components/FacilitiesTable/FacilitiesTable";

const FacilityListScreen = () => {
  return (
    <ContentLayout header={{ title: "Facility List" }}>
      <FacilitiesTable />
    </ContentLayout>
  );
};

export default FacilityListScreen;
