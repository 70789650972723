import ContentLayout from "@app/components/layouts/ContentLayout/ContentLayout";

import ManagersTable from "../../components/ManagersTable/ManagersTable";

const ManagerListScreen = () => {
  return (
    <ContentLayout header={{ title: "Manager List" }}>
      <ManagersTable />
    </ContentLayout>
  );
};

export default ManagerListScreen;
