import { Redirect } from "react-router-dom";

import { flatten } from "@app/helpers/route.helper";
import NestedRouteWrapper from "@app/routes/NestedRouteWrapper";

import { ManagerPathsEnum } from "../manager";
import { MANAGER_ROUTES } from "./manager.routes";

const ManagerRoutes = () => {
  const routesWithComponents = flatten(MANAGER_ROUTES);

  return (
    <NestedRouteWrapper
      routesWithComponents={routesWithComponents}
      additionalComponent={
        /* Redirect from blank route */
        <Redirect
          exact
          from={ManagerPathsEnum.MANAGER}
          to={ManagerPathsEnum.CREATE_MANAGER}
        />
      }
    />
  );
};

export default ManagerRoutes;
