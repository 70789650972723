import { memo } from "react";

import { useForm } from "antd/lib/form/Form";
import Search from "antd/lib/input/Search";

import PageFilter, {
  FilterItem,
} from "@app/components/molecules/PageFilter/PageFilter";

import usePackageList from "../../hooks/usePackageList";
import styles from "./PackagesFilter.module.scss";

export interface PackagesFilterProps {
  search?: string;
}

const PackagesFilter = () => {
  const [form] = useForm();
  const { loading, updateSearchParams, getUpdatedPackageList } =
    usePackageList();

  const searchPackages = () => {
    updateSearchParams({ page: 1, orderBy: undefined, sort: undefined }); // Resets search params
    getUpdatedPackageList({
      searchQuery: form.getFieldValue("search") || undefined,
      page: 1,
      orderBy: undefined,
      sort: undefined,
    });
  };

  const handleSearch = async () => {
    form.submit();

    if (!loading) {
      searchPackages();
    }
  };

  return (
    <PageFilter<PackagesFilterProps> parseNumbers={["search"]} form={form}>
      <FilterItem className={styles.filterLabel} name="search">
        <Search
          placeholder="Search by package title"
          enterButton
          onSearch={handleSearch}
        />
      </FilterItem>
    </PageFilter>
  );
};

export default memo(PackagesFilter);
