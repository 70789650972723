import { useEffect, useRef } from "react";

import { Col, message, Row, Table, TablePaginationConfig } from "antd";
import { FilterValue, SorterResult } from "antd/lib/table/interface";
import dayjs from "dayjs";
import { useLocation } from "react-router-dom";
import { useMount, usePrevious, useUnmount } from "react-use";
import { clearInterval, setInterval } from "worker-timers";

import TableView, {
  TableViewProps,
} from "@app/components/molecules/TableView/TableView";
import {
  DEFAULT_DATE_TIME_FORMAT,
  DASHBOARD_POLLING_INTERVAL,
} from "@app/constants/time.constants";
import { EnvironmentQualityDef } from "@app/features/facility/facility";
import { renderGender } from "@app/helpers/gender.helper";
import useSearchParams, { SearchParamDef } from "@app/hooks/useSearchParams";
import { useAppDispatch, useAppSelector } from "@app/redux/store";
import { SortEnum } from "@app/types/table.types";

import {
  getFacilityEnvironmentNotifications,
  GetEnvironmentNotificationsDef,
} from "../../dashboard";

type EnvironmentQualityTableProps = TableViewProps<EnvironmentQualityDef>;

const EnvironmentQualityNotification = ({
  ...props
}: EnvironmentQualityTableProps) => {
  const location = useLocation();
  const prevLocation = usePrevious(location);
  const { getSortOrder, setCurrentSort, search } =
    useSearchParams<SearchParamDef>();
  const intervalRef = useRef<number>();
  const dispatch = useAppDispatch();
  const { loading, environmentNotifications } = useAppSelector(state => ({
    loading: state.dashboard.environmentNotificationsLoading,
    environmentNotifications: state.dashboard.environmentNotifications,
  }));

  const getEnvironmentQualityNotifications = async (
    payload?: GetEnvironmentNotificationsDef
  ) => {
    const response = await dispatch(
      getFacilityEnvironmentNotifications({ ...payload })
    );

    if (!getFacilityEnvironmentNotifications.fulfilled.match(response)) {
      message.error("Environment quality notification fetching failed!");
    }
  };

  const startNotificationPolling = (
    payload?: GetEnvironmentNotificationsDef
  ) => {
    getEnvironmentQualityNotifications(payload);

    if (intervalRef.current !== undefined) {
      clearInterval(intervalRef.current);
    }

    // Start environment notification polling
    intervalRef.current = setInterval(() => {
      getEnvironmentQualityNotifications(payload);
    }, DASHBOARD_POLLING_INTERVAL);
  };

  const parseSearchSort = () => {
    if (!search.sort) {
      return undefined;
    }

    return search.sort;
  };

  const parseSearchOrderBy = () => {
    if (!search.sort) {
      return undefined;
    }

    return search.orderBy === "time" ? "created_at" : search.orderBy;
  };

  useEffect(() => {
    // Handles navigation reset
    if (prevLocation?.search && location.search === "") {
      startNotificationPolling();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search, prevLocation?.search]);

  useMount(() => {
    startNotificationPolling({
      sort: parseSearchSort(),
      order_by: parseSearchOrderBy(),
    });
  });

  useUnmount(() => {
    if (intervalRef.current !== undefined) {
      clearInterval(intervalRef.current);
      intervalRef.current = undefined;
    }
  });

  const parseSort = (
    sorter:
      | SorterResult<EnvironmentQualityDef>
      | SorterResult<EnvironmentQualityDef>[]
  ) => {
    if (!Array.isArray(sorter)) {
      const { order } = sorter;

      if (order) {
        return order === "ascend" ? SortEnum.ASC : SortEnum.DESC;
      }
    }

    return undefined;
  };

  const parseOrderBy = (
    sorter:
      | SorterResult<EnvironmentQualityDef>
      | SorterResult<EnvironmentQualityDef>[]
  ) => {
    if (!Array.isArray(sorter)) {
      const { order } = sorter;

      if (order) {
        return sorter.columnKey === "time" ? "created_at" : sorter.columnKey;
      }
    }

    return undefined;
  };

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter:
      | SorterResult<EnvironmentQualityDef>
      | SorterResult<EnvironmentQualityDef>[]
  ) => {
    if (!loading) {
      startNotificationPolling({
        sort: parseSort(sorter),
        order_by: parseOrderBy(sorter) as string,
      });
    }

    if (!Array.isArray(sorter)) {
      setCurrentSort(sorter.order);
    }
  };

  return (
    <TableView<EnvironmentQualityDef>
      dataSource={environmentNotifications}
      loading={loading}
      actionTitle="Action"
      onChange={handleTableChange}
      title={() => (
        <Row justify="start">
          <Col>
            <h2>Facility Environment Notifications</h2>
          </Col>
        </Row>
      )}
      hideActionColumn
      hidePagination
      {...props}
    >
      <Table.Column
        title="Facility Name"
        key="facilityName"
        dataIndex="facilityName"
        render={(facilityName: string, record: EnvironmentQualityDef) =>
          record?.facility?.name
        }
      />
      <Table.Column
        title="Section"
        key="section"
        dataIndex="section"
        render={(section: string) => renderGender(section)}
      />
      <Table.Column
        title="Air Quality"
        key="airQuality"
        dataIndex="air_quality"
        render={(airQuality: string) => (
          <span>{airQuality ? Number(airQuality) : ""}</span>
        )}
      />
      <Table.Column
        title="Humidity"
        key="humidity"
        dataIndex="humidity"
        render={(humidity: string) => (humidity ? `${Number(humidity)}%` : "")}
      />
      <Table.Column
        title="Temperature"
        key="temperature"
        dataIndex="temperature"
        render={(temperature: string) =>
          temperature ? `${Number(temperature)}°c` : ""
        }
      />
      <Table.Column
        title="Time"
        key="time"
        dataIndex="created_at"
        render={(date: string) => dayjs(date).format(DEFAULT_DATE_TIME_FORMAT)}
        sorter
        sortOrder={getSortOrder("time")}
      />
    </TableView>
  );
};

export default EnvironmentQualityNotification;
