import { CSSProperties, memo } from "react";

import {
  Card,
  Statistic as AntDStatistic,
  StatisticProps as AntDStatisticProps,
} from "antd";

import styles from "./Statistic.module.scss";

export interface StaticsProps extends AntDStatisticProps {
  backgroundColor?: CSSProperties["color"];
  color?: CSSProperties["color"];
}

const Statistic = ({
  title,
  value,
  backgroundColor = "gray",
  color = "white",
  suffix,
}: StaticsProps) => {
  return (
    <Card style={{ backgroundColor }} className={styles.statisticWrapper}>
      <AntDStatistic
        title={title}
        value={value}
        valueStyle={{ color }}
        suffix={suffix}
      />
    </Card>
  );
};

export default memo(Statistic);
