import ContentLayout from "@app/components/layouts/ContentLayout/ContentLayout";

import UsageHistory from "../../components/UsageHistory/UsageHistory";

const FacilityUsageHistoryScreen = () => {
  return (
    <ContentLayout header={{ title: "Facility Usage History" }}>
      <UsageHistory />
    </ContentLayout>
  );
};

export default FacilityUsageHistoryScreen;
