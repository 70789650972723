import ContentLayout from "@app/components/layouts/ContentLayout/ContentLayout";

import PackageForm from "../../components/PackageForm/PackageForm";

const PackageCreationScreen = () => {
  return (
    <ContentLayout header={{ title: "Create New Package" }}>
      <PackageForm />
    </ContentLayout>
  );
};

export default PackageCreationScreen;
