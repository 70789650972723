import { memo, ReactNode } from "react";

import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  LogoutOutlined,
  UserOutlined,
  DownOutlined,
} from "@ant-design/icons";
import {
  Avatar,
  Layout,
  Menu,
  message,
  PageHeader,
  PageHeaderProps,
} from "antd";
import { Route } from "antd/lib/breadcrumb/Breadcrumb";
import cx from "classnames";
import { Link, useHistory } from "react-router-dom";

import Dropdown from "@app/components/atoms/Dropdown/Dropdown";
import { AuthPathsEnum, logout } from "@app/features/auth/auth";
import { useAppDispatch, useAppSelector } from "@app/redux/store";
import { HasMessage } from "@app/types/api.types";

import useSidebarLayout from "../SidebarLayout/hooks/useSidebarLayout";
import styles from "./ContentLayout.module.scss";

const { Content } = Layout;

type ContentLayoutProps = {
  header: PageHeaderProps;
  filters?: ReactNode;
  children: ReactNode;
  noContentStyle?: boolean;
};

const ContentLayout = memo(
  ({ header, filters, children, noContentStyle }: ContentLayoutProps) => {
    const history = useHistory();
    const { isSidebarCollapsed, setIsSidebarCollapsed } = useSidebarLayout();
    const dispatch = useAppDispatch();
    const { user } = useAppSelector(state => ({
      user: state.auth.user,
    }));

    const handleLogout = async () => {
      const response = await dispatch(logout());

      if (logout.fulfilled.match(response)) {
        message.success("Logout successful");
        history.replace(AuthPathsEnum.LOGIN);
      } else {
        message.error((response.payload as HasMessage)?.message);
      }
    };

    const menuOverlay = (
      <Menu
        items={[
          {
            key: "account",
            label: <Link to={AuthPathsEnum.MY_ACCOUNT}>My Account</Link>,
          },
          {
            type: "divider",
          },
          /* Logout Menu */
          {
            key: "logout",
            label: (
              <div className={styles.logoutMenu}>
                <LogoutOutlined />
                <span>Logout</span>
              </div>
            ),
            onClick: handleLogout,
          },
        ]}
      />
    );

    // Add custom breadcrumb item render to support Link from react-router-dom
    const renderBreadcrumbItem = (
      route: Route,
      params: unknown,
      routes: Route[]
    ) => {
      const last = routes.indexOf(route) === routes.length - 1;
      return last ? (
        <span>{route.breadcrumbName}</span>
      ) : (
        <Link to={route.path}>{route.breadcrumbName}</Link>
      );
    };

    const renderSidebarTrigger = () => {
      const props = {
        onClick: () => setIsSidebarCollapsed?.(prevState => !prevState),
        className: styles.trigger,
      };

      return isSidebarCollapsed ? (
        <MenuUnfoldOutlined {...props} />
      ) : (
        <MenuFoldOutlined {...props} />
      );
    };

    const renderUserAvatarMenu = () => {
      return (
        <Dropdown
          overlay={menuOverlay}
          placement="bottomRight"
          arrow
          trigger={["click"]}
          overlayClassName={styles.menuOverlayWrapper}
        >
          <div className={styles.avatarDropdown}>
            <Avatar icon={<UserOutlined />} />
            <span>{user?.name}</span>
            <DownOutlined />
          </div>
        </Dropdown>
      );
    };

    return (
      <Layout className={styles.container}>
        <PageHeader
          ghost={false}
          {...header}
          breadcrumb={{
            itemRender: renderBreadcrumbItem,
            ...header.breadcrumb,
          }}
          className={cx(styles.pageHeader, header.className)}
          extra={renderUserAvatarMenu()}
        >
          {renderSidebarTrigger()}
        </PageHeader>
        {filters && (
          <div className={cx(styles.filters, styles.content)}>{filters}</div>
        )}
        <Content className={cx({ [styles.content]: !noContentStyle })}>
          {children}
        </Content>
      </Layout>
    );
  }
);

export default ContentLayout;
