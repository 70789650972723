import dayjs from "dayjs";
import { useParams } from "react-router-dom";

import { DEFAULT_PAGE_SIZE } from "@app/constants/pagination.constants";
import {
  DEFAULT_API_DATE_FORMAT,
  DEFAULT_DATE_RANGE,
} from "@app/constants/time.constants";
import useSearchParams, { SearchParamDef } from "@app/hooks/useSearchParams";
import { useAppDispatch, useAppSelector } from "@app/redux/store";

import { updateCurrentParams, getExpenseHistory } from "../facility";
import {
  GetFacilityExpenseHistoryDef,
  SearchQueryParamDef,
} from "../types/facility.types";

type ExpenseHistoryParams = {
  id?: string;
};

const useExpenseHistory = () => {
  const dispatch = useAppDispatch();
  const { updateSearchParams, search } =
    useSearchParams<SearchParamDef<SearchQueryParamDef>>();
  const { id } = useParams<ExpenseHistoryParams>();
  const { expenseList, loading, paginationData } = useAppSelector(state => ({
    expenseList: state.facility.expenseList,
    loading: state.facility.loading,
    paginationData: state.facility.paginationData,
  }));

  const parseSort = () => {
    if (!search.sort) {
      return undefined;
    }

    return search.sort;
  };

  const parseOrderBy = () => {
    if (!search.sort) {
      return undefined;
    }

    return search.orderBy === "date" ? "created_at" : search.orderBy;
  };

  const getInitialExpenseList = () => {
    const currentDate = dayjs().format(DEFAULT_API_DATE_FORMAT);

    const params: GetFacilityExpenseHistoryDef = {
      from: dayjs(currentDate)
        .subtract(
          Number(search.dateRange) - 1 || DEFAULT_DATE_RANGE - 1,
          "days"
        )
        .format(DEFAULT_API_DATE_FORMAT),
      to: currentDate,
      facilityId: String(id),
      orderBy: parseOrderBy(),
      sort: parseSort(),
      page: search.page ?? undefined,
      perPage: search.pageSize ?? DEFAULT_PAGE_SIZE,
    };

    dispatch(updateCurrentParams(params));
    dispatch(getExpenseHistory());
  };

  const getUpdatedExpenseList = (params: GetFacilityExpenseHistoryDef) => {
    dispatch(updateCurrentParams(params));
    dispatch(getExpenseHistory());
  };

  return {
    getInitialExpenseList,
    getUpdatedExpenseList,
    expenseList,
    loading,
    paginationData,
    updateSearchParams,
    facilityId: id,
  };
};

export default useExpenseHistory;
