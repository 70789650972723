import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { LoginRequestDef, AUTH_FEATURE_KEY } from "@app/features/auth/auth";

import { authLogin, authLogout, updateProfile } from "../api/auth.api";
import {
  saveTokens,
  clearTokens,
  authErrorHelper,
} from "../helpers/auth.helpers";
import { InitialStateDef, UpdateProfileDef } from "../types/auth.types";

const initialState: InitialStateDef = {
  user: null,
  isAuthenticated: false,
  error: false,
  loading: false,
  accountLoading: false,
};

export const login = createAsyncThunk(
  `${AUTH_FEATURE_KEY}/login`,
  async (values: LoginRequestDef, { rejectWithValue }) => {
    try {
      const response = await authLogin(values);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const logout = createAsyncThunk(
  `${AUTH_FEATURE_KEY}/logout`,
  async (values: undefined, { rejectWithValue }) => {
    try {
      const response = await authLogout();

      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const modifyProfile = createAsyncThunk(
  `${AUTH_FEATURE_KEY}/updateProfile`,
  async (payload: UpdateProfileDef, { rejectWithValue }) => {
    try {
      const response = await updateProfile(payload);

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const authSlice = createSlice({
  name: AUTH_FEATURE_KEY,
  initialState,
  reducers: {
    clearUser(state) {
      state.user = null;
      state.isAuthenticated = false;
      clearTokens();
    },
  },
  extraReducers: builder => {
    /**
     * LOGIN
     */
    builder.addCase(login.pending, state => {
      state.error = false;
      state.loading = true;
    });
    builder.addCase(login.fulfilled, (state, action) => {
      const { token, user } = action.payload;

      state.loading = false;
      state.isAuthenticated = true;
      state.user = user;

      if (token) {
        saveTokens({ token: token?.access_token });
      }
    });
    builder.addCase(login.rejected, state => {
      authErrorHelper(state);
      clearTokens();
    });
    /**
     * LOGOUT
     */
    builder.addCase(logout.pending, state => {
      state.error = false;
      state.loading = true;
    });
    builder.addCase(logout.fulfilled, state => {
      clearTokens();

      state.loading = false;
      state.isAuthenticated = false;
      state.user = null;
    });
    builder.addCase(logout.rejected, state => {
      authErrorHelper(state);
    });
    /**
     * UPDATE PROFILE
     */
    builder.addCase(modifyProfile.pending, state => {
      state.error = false;
      state.accountLoading = true;
    });
    builder.addCase(modifyProfile.fulfilled, (state, action) => {
      state.accountLoading = false;
      state.user = action.payload;
    });
    builder.addCase(modifyProfile.rejected, state => {
      authErrorHelper(state);
    });
  },
});

export const { clearUser } = authSlice.actions;

export const authReducer = authSlice.reducer;
