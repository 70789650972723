import { useEffect } from "react";

import { Input, Row, Col, message, Select } from "antd";
import { RuleObject } from "antd/lib/form";
import { useHistory } from "react-router-dom";

import Button from "@app/components/atoms/Button/Button";
import Form, { Item, useForm } from "@app/components/atoms/Form/Form";
import {
  DEFAULT_INPUT_LENGTH,
  DEFAULT_NUMBER_INPUT_PATTERN,
} from "@app/constants/input.constants";
import { getAllFacilities } from "@app/features/facility/facility";
import { useAppSelector, useAppDispatch } from "@app/redux/store";
import { HasMessage } from "@app/types/api.types";

import { createAPackage, PackagePathsEnum } from "../../package";

type PackageFormDef = {
  title: string;
  titleBn: string;
  facilityId: number;
  usages: string;
  days: string;
  amount: string;
};

export interface PackageFormProps {
  initialValues?: PackageFormDef;
  loading?: boolean;
  editMode?: boolean;
}

// const handleFacilityChange = (value: string) => {
//   return value;
// };

const PackageForm = ({
  initialValues,
  editMode,
  loading,
}: PackageFormProps) => {
  const history = useHistory();
  const [form] = useForm();
  const dispatch = useAppDispatch();

  const { facilityList } = useAppSelector(state => ({
    facilityList: state.facility?.facilityList,
  }));

  useEffect(() => {
    dispatch(getAllFacilities());
    if (initialValues && !form.isFieldsTouched()) {
      form.setFieldsValue(initialValues);
    }
  }, [initialValues, form, dispatch]);

  const createRechargeAmount = async () => {
    const { getFieldValue } = form;

    const response = await dispatch(
      createAPackage({
        title: getFieldValue("title"),
        title_bn: getFieldValue("titleBn"),
        facility_id: getFieldValue("facilityId"),
        usages: getFieldValue("usages"),
        days: getFieldValue("days"),
        amount: getFieldValue("amount"),
      })
    );

    if (createAPackage.fulfilled.match(response)) {
      message.success("Recharge amount created successfully");
      history.push(PackagePathsEnum.PACKAGE_LIST);
    } else {
      message.error((response.payload as HasMessage)?.message);
    }
  };

  const handleCreate = () => {
    createRechargeAmount();
  };

  const validateInputNumber = (_: RuleObject, value: number) => {
    if (value >= 0) {
      return Promise.resolve();
    }
    return Promise.reject(new Error("Value must be a positive number"));
  };

  const handleFacilitySearch = (value: string) => {
    return value;
  };

  return (
    <Row justify="space-between">
      <Col xs={8}>
        <Form form={form} onFinish={handleCreate}>
          <Item
            name="title"
            label="Title"
            rules={[
              {
                required: true,
                whitespace: true,
                message: "Please enter the title!",
              },
              {
                max: DEFAULT_INPUT_LENGTH,
                message: `Input length can't exceed ${DEFAULT_INPUT_LENGTH} characters!`,
              },
            ]}
          >
            <Input placeholder="Please enter a title in English" />
          </Item>

          <Item
            name="titleBn"
            label="Title (Bengali)"
            rules={[
              {
                required: true,
                whitespace: true,
                message: "Please enter the title!",
              },
              {
                max: DEFAULT_INPUT_LENGTH,
                message: `Input length can't exceed ${DEFAULT_INPUT_LENGTH} characters!`,
              },
            ]}
          >
            <Input placeholder="Please enter a title in Bangla" />
          </Item>

          <Item
            name="facilityId"
            label="Facility Name"
            rules={[
              {
                required: true,
                message: "Please select at least 1 facility!",
              },
            ]}
          >
            <Select
              showSearch
              allowClear
              placeholder="Please select a Facility"
              optionFilterProp="children"
              onSearch={handleFacilitySearch}
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              options={facilityList.map(item => ({
                label: item.name,
                value: item.id,
              }))}
            />
          </Item>

          <Item
            name="days"
            label="Validity (Days)"
            rules={[
              { required: true, message: "Please enter a number" },
              { validator: validateInputNumber },
            ]}
          >
            <Input
              type="number"
              min={1}
              placeholder="Please enter the day count for validity"
            />
          </Item>

          <Item
            name="usages"
            label="Usage (Service Count)"
            rules={[
              { required: true, message: "Please enter a number" },
              { validator: validateInputNumber },
            ]}
          >
            <Input
              type="number"
              min={1}
              placeholder="Please enter a valid service count"
            />
          </Item>

          <Item
            name="amount"
            label="Amount"
            rules={[
              {
                required: true,
                message: "Please enter the amount!",
              },
              {
                pattern: DEFAULT_NUMBER_INPUT_PATTERN,
                message:
                  "Input can't exceed 12 digits with 2 decimal point max!",
              },
            ]}
          >
            <Input type="number" placeholder="Please enter a valid amount" />
          </Item>
          <Item>
            <Button disabled={loading} type="primary" htmlType="submit">
              {editMode ? "Update" : "Create"}
            </Button>
          </Item>
        </Form>
      </Col>
    </Row>
  );
};

export default PackageForm;
