import {
  EnvironmentOutlined,
  FileAddOutlined,
  UnorderedListOutlined,
} from "@ant-design/icons";

import { RouteItemDef } from "@app/types/route.types";

import { FacilityPathsEnum } from "../constants/facility.paths";
import FacilityCreateScreen from "../screens/FacilityCreateScreen/FacilityCreateScreen";
import FacilityEditScreen from "../screens/FacilityEditScreen/FacilityEditScreen";
import FacilityEnvironmentQualityScreen from "../screens/FacilityEnvironmentQualityScreen/FacilityEnvironmentQualityScreen";
import FacilityExpenseHistoryScreen from "../screens/FacilityExpenseHistoryScreen/FacilityExpenseHistoryScreen";
import FacilityFeedbacksScreen from "../screens/FacilityFeedbacksScreen/FacilityFeedbacksScreen";
import FacilityListScreen from "../screens/FacilityListScreen/FacilityListScreen";
import FacilityUsageHistoryScreen from "../screens/FacilityUsageHistoryScreen/FacilityUsageHistoryScreen";
import FacilityRoutes from "./FacilityRoutes";

const FACILITY_SCREEN: RouteItemDef = {
  id: "facility",
  path: FacilityPathsEnum.FACILITY,
  navigationTitle: "Facility",
  component: FacilityRoutes,
  sidebarIcon: EnvironmentOutlined,
  nestedRoutes: [
    /* Facility list */
    {
      id: "facilityList",
      path: FacilityPathsEnum.FACILITY_LIST,
      navigationTitle: "Facility List",
      component: FacilityListScreen,
      sidebarIcon: UnorderedListOutlined,
    },
    /* Facility Create */
    {
      id: "facilityCreate",
      path: FacilityPathsEnum.FACILITY_CREATE,
      navigationTitle: "Facility Create",
      component: FacilityCreateScreen,
      sidebarIcon: FileAddOutlined,
    },
    /* Facility Edit */
    {
      id: "facilityEdit",
      path: `${FacilityPathsEnum.FACILITY_EDIT}/:id`,
      navigationTitle: "Edit Facility",
      component: FacilityEditScreen,
      hideInNavigation: true,
    },
    /* Facility Expense History */
    {
      id: "facilityExpenseHistory",
      path: `${FacilityPathsEnum.FACILITY_EXPENSE_HISTORY}/:id/expense-history`,
      component: FacilityExpenseHistoryScreen,
      hideInNavigation: true,
    },
    /* Facility Usage History */
    {
      id: "facilityUsageHistory",
      path: `${FacilityPathsEnum.FACILITY_USAGE_HISTORY}/:id/usage-history`,
      component: FacilityUsageHistoryScreen,
      hideInNavigation: true,
    },
    /* Facility Feedbacks */
    {
      id: "facilityFeedbacks",
      path: `${FacilityPathsEnum.FACILITY_FEEDBACKS}/:id/feedbacks`,
      component: FacilityFeedbacksScreen,
      hideInNavigation: true,
    },
    /* Facility Feedbacks */
    {
      id: "facilityEnvironment",
      path: `${FacilityPathsEnum.FACILITY_ENVIRONMENT_QUALITY}/:id/environment-quality`,
      component: FacilityEnvironmentQualityScreen,
      hideInNavigation: true,
    },
  ],
};

export const FACILITY_ROUTES = [FACILITY_SCREEN];
