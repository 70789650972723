import { DEFAULT_PAGE_SIZE } from "@app/constants/pagination.constants";
import useSearchParams from "@app/hooks/useSearchParams";
import { useAppDispatch, useAppSelector } from "@app/redux/store";

import { getAllAdmins, updateCurrentParams } from "../redux/admin.slice";
import { GetAdminListDef, SearchQueryParamDef } from "../types/admin.types";

const useAdminList = () => {
  const dispatch = useAppDispatch();
  const { search, updateSearchParams } = useSearchParams<SearchQueryParamDef>();

  const { adminList, loading, paginationData } = useAppSelector(state => ({
    adminList: state.admin.adminList,
    loading: state.admin.loading,
    paginationData: state.admin.paginationData,
  }));

  const parseSort = () => {
    if (search.sort) {
      return search.sort;
    }

    return undefined;
  };

  const parseOrderBy = () => {
    if (search.sort) {
      return search.orderBy === "phone" ? "phone_number" : search.orderBy;
    }

    return undefined;
  };

  const getInitialAdminList = (reset?: boolean) => {
    const params = {
      searchQuery: !reset ? search.search ?? undefined : undefined,
      orderBy: !reset ? parseOrderBy() : undefined,
      sort: !reset ? parseSort() : undefined,
      page: !reset ? search.page ?? undefined : 1,
      perPage: !reset
        ? search.pageSize ?? DEFAULT_PAGE_SIZE
        : DEFAULT_PAGE_SIZE,
    };

    dispatch(updateCurrentParams(params));
    dispatch(getAllAdmins());
  };

  const getUpdatedAdminList = (params: GetAdminListDef) => {
    dispatch(updateCurrentParams(params));
    dispatch(getAllAdmins());
  };

  return {
    getInitialAdminList,
    getUpdatedAdminList,
    adminList,
    loading,
    paginationData,
    updateSearchParams,
  };
};

export default useAdminList;
