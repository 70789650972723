import ContentLayout from "@app/components/layouts/ContentLayout/ContentLayout";

import ManagerRechargeHistory from "../../components/ManagerRechargeHistoryTable/ManagerRechargeHistoryTable";

const ManagerRechargeHistoryScreen = () => {
  return (
    <ContentLayout header={{ title: "Recharge History" }}>
      <ManagerRechargeHistory />
    </ContentLayout>
  );
};

export default ManagerRechargeHistoryScreen;
