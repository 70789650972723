import { memo, useEffect, useRef } from "react";

import { Col, Row } from "antd";
import { useForm } from "antd/lib/form/Form";
import dayjs from "dayjs";
import { useLocation } from "react-router-dom";
import { useMount, usePrevious, useUnmount } from "react-use";
import { clearInterval, setInterval } from "worker-timers";

import { RangePicker } from "@app/components/atoms/DateTimePicker/DateTimePicker";
import Statistic from "@app/components/atoms/Statistic/Statistic";
import PageFilter, {
  FilterItem,
} from "@app/components/molecules/PageFilter/PageFilter";
import {
  DEFAULT_API_DATE_FORMAT,
  DEFAULT_DATE_FORMAT,
  DEFAULT_DATE_RANGE,
  DASHBOARD_POLLING_INTERVAL,
} from "@app/constants/time.constants";

import useBusinessOverview from "../../hooks/useBusinessOverview";
import styles from "./BusinessOverview.module.scss";

export interface BusinessOverviewFilterDef {
  dateRange?: string;
}

const BusinessOverview = () => {
  const [form] = useForm();
  const location = useLocation();
  const prevLocation = usePrevious(location);
  const intervalRef = useRef<number>();

  const {
    getBusinessOverview,
    search,
    loading,
    businessOverview,
    totalIncomeSubscriptions,
    updateSearchParams,
  } = useBusinessOverview(form);

  const currentDate = dayjs();

  const startBusinessOverviewPolling = (payload?: [string?, string?]) => {
    getBusinessOverview(payload);

    if (intervalRef.current !== undefined) {
      clearInterval(intervalRef.current);
    }

    // Start environment notification polling
    intervalRef.current = setInterval(() => {
      getBusinessOverview(payload);
    }, DASHBOARD_POLLING_INTERVAL);
  };

  useEffect(() => {
    // Handles navigation reset
    if (prevLocation?.search && location.search === "") {
      const from = dayjs(currentDate)
        .subtract(DEFAULT_DATE_RANGE - 1, "days")
        .format(DEFAULT_API_DATE_FORMAT);
      const to = currentDate.format(DEFAULT_API_DATE_FORMAT);

      updateSearchParams({
        dateRange: [from, to],
      });

      startBusinessOverviewPolling([from, to]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentDate, location.search, prevLocation?.search]);

  useMount(() => {
    const from = dayjs(currentDate).subtract(DEFAULT_DATE_RANGE - 1, "days");
    const to = currentDate;

    if (!search.dateRange?.[0] && !search.dateRange?.[1]) {
      form.setFieldsValue({
        dateRange: [from, to],
      });

      updateSearchParams({
        dateRange: [
          from.format(DEFAULT_API_DATE_FORMAT),
          to.format(DEFAULT_API_DATE_FORMAT),
        ],
      });
    }

    startBusinessOverviewPolling([
      search.dateRange?.[0],
      search.dateRange?.[1],
    ]);
  });

  useUnmount(() => {
    if (intervalRef.current !== undefined) {
      clearInterval(intervalRef.current);
      intervalRef.current = undefined;
    }
  });

  const handleSearch = async () => {
    if (!loading) {
      startBusinessOverviewPolling();
    }
  };

  const getActualTotalIncome = () =>
    Number(businessOverview?.total_income) +
    Number(totalIncomeSubscriptions?.total_income_subscriptions);

  const getTotalProfit = () =>
    Number(businessOverview?.total_profit) +
    Number(totalIncomeSubscriptions?.total_income_subscriptions);

  return (
    <div className={styles.businessOverviewWrapper}>
      <h2 className={styles.title}>Business Overview</h2>

      <PageFilter<BusinessOverviewFilterDef>
        parseDates
        form={form}
        submitOnChange
        onSubmit={handleSearch}
        columns={3}
      >
        <FilterItem name="dateRange">
          <RangePicker format={DEFAULT_DATE_FORMAT} inputReadOnly />
        </FilterItem>
      </PageFilter>

      <Row gutter={[12, 12]}>
        <Col xs={6}>
          <Statistic
            title="Total Income"
            value={getActualTotalIncome() || 0}
            suffix="BDT"
            backgroundColor="#0050b3"
          />
        </Col>

        <Col xs={6}>
          <Statistic
            title="Total Expense"
            value={businessOverview?.total_expense}
            suffix="BDT"
            backgroundColor="red"
          />
        </Col>

        <Col xs={6}>
          <Statistic
            title="Total Profit"
            value={getTotalProfit() || 0}
            suffix="BDT"
            backgroundColor="green"
          />
        </Col>

        <Col xs={6}>
          <Statistic
            title="Total Income by Pay As You Go"
            value={businessOverview?.total_income_subscribed}
            suffix="BDT"
            backgroundColor="royalblue"
          />
        </Col>

        <Col xs={6}>
          <Statistic
            title="Total Income by Anonymous Users"
            value={businessOverview?.total_income_anonymous}
            suffix="BDT"
            backgroundColor="royalblue"
          />
        </Col>

        <Col xs={6}>
          <Statistic
            title="Total New App Users "
            value={businessOverview?.total_new_users}
            backgroundColor="skyblue"
          />
        </Col>

        <Col xs={6}>
          <Statistic
            title="Total New App Users (Male)"
            value={businessOverview?.total_new_users_male}
            backgroundColor="pink"
          />
        </Col>

        <Col xs={6}>
          <Statistic
            title="Total New App Users (Female)"
            value={businessOverview?.total_new_users_female}
            backgroundColor="pink"
          />
        </Col>

        <Col xs={6}>
          <Statistic
            title="Total New App Users (Other)"
            value={businessOverview?.total_new_users_other}
            backgroundColor="pink"
          />
        </Col>

        <Col xs={6}>
          <Statistic
            title="Total Usages (Male)"
            value={businessOverview?.total_usages_male}
            backgroundColor="orange"
          />
        </Col>

        <Col xs={6}>
          <Statistic
            title="Total Usages (Female)"
            value={businessOverview?.total_usages_female}
            backgroundColor="orange"
          />
        </Col>

        <Col xs={6}>
          <Statistic
            title="Total Income by package"
            value={totalIncomeSubscriptions?.total_income_subscriptions}
            suffix="BDT"
            backgroundColor="orchid"
          />
        </Col>
      </Row>
    </div>
  );
};

export default memo(BusinessOverview);
