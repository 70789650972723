import { Redirect } from "react-router-dom";

import { flatten } from "@app/helpers/route.helper";
import NestedRouteWrapper from "@app/routes/NestedRouteWrapper";

import { UserPathsEnum } from "../user";
import { USER_ROUTES } from "./user.routes";

const UserRoutes = () => {
  const routesWithComponents = flatten(USER_ROUTES);

  return (
    <NestedRouteWrapper
      routesWithComponents={routesWithComponents}
      additionalComponent={
        /* Redirect from blank route */
        <Redirect
          exact
          from={UserPathsEnum.USER}
          to={UserPathsEnum.USER_LIST}
        />
      }
    />
  );
};

export default UserRoutes;
