import { UserTypeEnum } from "@app/constants/user.constants";

export const renderUserType = (userType?: string) => {
  const loweredCaseGender = userType?.toLocaleLowerCase();

  if (loweredCaseGender === UserTypeEnum.USER) {
    return "User";
  }
  if (loweredCaseGender === UserTypeEnum.MANAGER) {
    return "Manager";
  }
  if (loweredCaseGender === UserTypeEnum.PACKAGE_USER) {
    return "Package User";
  }

  return "";
};
