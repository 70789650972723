import { Col, message, Row } from "antd";
import { Link, useParams } from "react-router-dom";
import { useMount } from "react-use";

import Button from "@app/components/atoms/Button/Button";
import ContentLayout from "@app/components/layouts/ContentLayout/ContentLayout";
import { useAppDispatch, useAppSelector } from "@app/redux/store";

import FacilityDeviceTable from "../../components/FacilityDeviceTable/FacilityDeviceTable";
import FacilityForm, {
  FacilityFormProps,
} from "../../components/FacilityForm/FacilityForm";
import FacilityServiceForm from "../../components/FacilityServiceForm/FacilityServiceForm";
import FacilityStats from "../../components/FacilityStats/FacilityStats";
import { getAFacility, FacilityPathsEnum } from "../../facility";
import styles from "./FacilityEditScreen.module.scss";

interface FacilityScreenParams {
  id?: string;
}

const FacilityEditScreen = () => {
  const params = useParams<FacilityScreenParams>();
  const dispatch = useAppDispatch();
  const { facilityDetails, loading } = useAppSelector(state => ({
    facilityDetails: state.facility.facilityDetails,
    loading: state.facility.loading,
  }));

  useMount(async () => {
    const response = await dispatch(getAFacility({ id: Number(params.id) }));

    if (!getAFacility.fulfilled.match(response)) {
      message.error("Facility fetching failed");
    }
  });

  const initialFacilityDetails: FacilityFormProps["initialValues"] =
    facilityDetails && {
      id: facilityDetails.id,
      name: facilityDetails.name,
      nameBn: facilityDetails.name_bn,
      address: facilityDetails.address,
      addressBn: facilityDetails.address_bn,
      description: facilityDetails.additional_note,
      howToGo: facilityDetails.navigation_note,
      howToGoBn: facilityDetails.navigation_note_bn,
      latitude: String(facilityDetails.lat),
      longitude: String(facilityDetails.lng),
      disableFriendly: facilityDetails.disable_friendly,
      status: facilityDetails.status,
      rating: facilityDetails.rating,
    };

  return (
    <ContentLayout header={{ title: "Facility Details" }}>
      <Row>
        <Col xs={24} className={styles.facilityStatsWrapper}>
          <FacilityStats facilityId={Number(facilityDetails?.id)} />
        </Col>

        <Col xs={24}>
          <FacilityForm
            editMode
            initialValues={initialFacilityDetails}
            loading={loading}
          />
        </Col>

        <Col xs={24} className={styles.facilityServiceFormWrapper}>
          <FacilityServiceForm />
        </Col>

        <Col xs={24} className={styles.facilityDeviceTableWrapper}>
          <FacilityDeviceTable
            deviceList={facilityDetails?.devices ?? []}
            facilityId={facilityDetails?.id}
            loading={loading}
          />
        </Col>

        <Col xs={24} className={styles.historyButtonsWrapper}>
          <div className="title">
            <span className="font-weight-bold">Facility History</span>
          </div>

          <div className="buttonGroup">
            <Link
              to={`${FacilityPathsEnum.FACILITY_EXPENSE_HISTORY}/${facilityDetails?.id}/expense-history`}
            >
              <Button type="primary">View Expense History</Button>
            </Link>

            <Link
              to={`${FacilityPathsEnum.FACILITY_USAGE_HISTORY}/${facilityDetails?.id}/usage-history`}
            >
              <Button type="primary">View Usage History</Button>
            </Link>
          </div>
        </Col>
      </Row>
    </ContentLayout>
  );
};

export default FacilityEditScreen;
