import ContentLayout from "@app/components/layouts/ContentLayout/ContentLayout";

import EnvironmentQualityTable from "../../components/EnvironmentQualityTable/EnvironmentQualityTable";

const FacilityEnvironmentQualityScreen = () => {
  return (
    <ContentLayout header={{ title: "Facility Environment Quality" }}>
      <EnvironmentQualityTable />
    </ContentLayout>
  );
};

export default FacilityEnvironmentQualityScreen;
