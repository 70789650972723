import { AxiosResponse } from "axios";

import { api } from "@app/api/api";
import { DEFAULT_PER_PAGE_300 } from "@app/constants/pagination.constants";

import { FacilityEndpointsEnum } from "../constants/facility.endpoints";
import {
  AddDeviceToFacilityDef,
  AddServiceToFacilityDef,
  CreateFacilityExpenseDef,
  CreateFacilityDef,
  DeleteExpenseDef,
  GetFacilityDetailsDef,
  GetFacilityExpenseHistoryDef,
  GetFacilityListDef,
  GetFacilityUsageHistoryDef,
  ModifyFacilityDef,
  RemoveServiceFromFacilityDef,
  ToggleFacilityStatusDef,
  GetFacilityExpenseDetailsDef,
  UpdateFacilityExpenseDef,
  GetFacilityTotalIncomeDef,
  GetFacilityTotalExpenseDef,
  GetFacilityFeedbackListDef,
  GetFacilityEnvironmentQualityListDef,
  RemoveDeviceFromFacilityDef,
  GetFacilityTotalUsageDef,
  GetFacilitySubscriptionIncomeDef,
} from "../types/facility.types";

export const getFacilityList = (
  data: GetFacilityListDef
): Promise<AxiosResponse> => {
  return api.get(FacilityEndpointsEnum.GET_FACILITY_LIST, {
    params: {
      page: data.page,
      per_page: data.perPage ?? DEFAULT_PER_PAGE_300,
      name: data.name,
      sort: data.sort,
      order_by: data.orderBy,
    },
  });
};

export const createFacility = (
  data: CreateFacilityDef
): Promise<AxiosResponse> => {
  return api.post(FacilityEndpointsEnum.CREATE_FACILITY, {
    ...data,
  });
};

export const getFacilityDetails = (
  data: GetFacilityDetailsDef
): Promise<AxiosResponse> => {
  return api.get(`${FacilityEndpointsEnum.GET_FACILITY_DETAILS}/${data.id}`);
};

export const getServiceList = (): Promise<AxiosResponse> => {
  return api.get(FacilityEndpointsEnum.GET_SERVICE_LIST);
};

export const updateFacility = (
  data: ModifyFacilityDef
): Promise<AxiosResponse> => {
  return api.patch(`${FacilityEndpointsEnum.UPDATE_FACILITY}/${data.id}`, {
    ...data.payload,
  });
};

export const addServiceToFacility = (
  data: AddServiceToFacilityDef
): Promise<AxiosResponse> => {
  return api.post(FacilityEndpointsEnum.ADD_SERVICE, {
    ...data,
  });
};

export const removeServiceFromFacility = (
  data: RemoveServiceFromFacilityDef
): Promise<AxiosResponse> => {
  return api.delete(
    `${FacilityEndpointsEnum.REMOVE_SERVICE}/${data.facilityServiceId}`
  );
};

export const addDeviceToFacility = (
  data: AddDeviceToFacilityDef
): Promise<AxiosResponse> => {
  return api.post(FacilityEndpointsEnum.ADD_DEVICE, {
    ...data,
  });
};

export const removeDeviceFromFacility = (
  data: RemoveDeviceFromFacilityDef
): Promise<AxiosResponse> => {
  return api.delete(`${FacilityEndpointsEnum.REMOVE_DEVICE}/${data.id}`);
};

export const toggleFacilityStatus = (
  data: ToggleFacilityStatusDef
): Promise<AxiosResponse> => {
  return api.post(
    `${FacilityEndpointsEnum.TOGGLE_FACILITY_STATUS}/${data.facility_id}/status`
  );
};

export const getFacilityExpenseHistory = (
  data: GetFacilityExpenseHistoryDef
): Promise<AxiosResponse> => {
  return api.get(FacilityEndpointsEnum.GET_FACILITY_EXPENSE_HISTORY, {
    params: {
      from: data.from,
      to: data.to,
      facility_ids: data.facilityId,
      page: data.page,
      per_page: data.perPage,
      sort: data.sort,
      order_by: data.orderBy,
    },
  });
};

export const deleteExpense = (
  data: DeleteExpenseDef
): Promise<AxiosResponse> => {
  return api.delete(
    `${FacilityEndpointsEnum.DELETE_FACILITY_EXPENSE}/${data.id}`
  );
};

export const createFacilityExpense = (
  data: CreateFacilityExpenseDef
): Promise<AxiosResponse> => {
  return api.post(
    `${FacilityEndpointsEnum.CREATE_FACILITY_EXPENSE}/${data.id}/expenses`,
    {
      ...data.payload,
    }
  );
};

export const getFacilityExpenseDetails = (
  data: GetFacilityExpenseDetailsDef
): Promise<AxiosResponse> => {
  return api.get(
    `${FacilityEndpointsEnum.GET_FACILITY_EXPENSE_DETAILS}/${data.id}`
  );
};

export const updateFacilityExpense = (
  data: UpdateFacilityExpenseDef
): Promise<AxiosResponse> => {
  return api.patch(
    `${FacilityEndpointsEnum.UPDATE_FACILITY_EXPENSE}/${data.id}`,
    {
      ...data.payload,
    }
  );
};

export const getFacilityUsageHistory = (
  data: GetFacilityUsageHistoryDef
): Promise<AxiosResponse> => {
  return api.get(FacilityEndpointsEnum.GET_FACILITY_USAGE_HISTORY, {
    params: {
      from: data.from,
      to: data.to,
      facility_id: data.facilityId,
      page: data.page,
      per_page: data.perPage,
      sort: data.sort,
      order_by: data.orderBy,
      gender: data.gender,
      user_type: data.userType,
    },
  });
};

export const getFacilityTotalIncome = (
  data: GetFacilityTotalIncomeDef
): Promise<AxiosResponse> => {
  return api.get(FacilityEndpointsEnum.GET_FACILITY_TOTAL_INCOME, {
    params: {
      ...data,
    },
  });
};

export const getFacilitySubscriptionIncome = (
  data: GetFacilitySubscriptionIncomeDef
): Promise<AxiosResponse> => {
  return api.get(FacilityEndpointsEnum.GET_FACILITY_TOTAL_SUBSCRIPTION_INCOME, {
    params: {
      ...data,
    },
  });
};

export const getFacilityTotalUsage = (
  data: GetFacilityTotalUsageDef
): Promise<AxiosResponse> => {
  return api.get(FacilityEndpointsEnum.GET_FACILITY_TOTAL_USAGE, {
    params: {
      ...data,
    },
  });
};

export const getFacilityFeedbackList = (
  data: GetFacilityFeedbackListDef
): Promise<AxiosResponse> => {
  return api.get(FacilityEndpointsEnum.GET_FACILITY_FEEDBACKS_LIST, {
    params: {
      ...data,
    },
  });
};

export const getFacilityTotalExpense = (
  data: GetFacilityTotalExpenseDef
): Promise<AxiosResponse> => {
  return api.get(FacilityEndpointsEnum.GET_FACILITY_TOTAL_EXPENSE, {
    params: {
      ...data,
    },
  });
};

export const getFacilityEnvironmentQualityList = (
  data: GetFacilityEnvironmentQualityListDef
): Promise<AxiosResponse> => {
  return api.get(FacilityEndpointsEnum.GET_FACILITY_ENVIRONMENT_QUALITY_LIST, {
    params: {
      ...data,
    },
  });
};

export const clearAllFacilityEnvironmentQuality =
  (): Promise<AxiosResponse> => {
    return api.delete(
      FacilityEndpointsEnum.CLEAR_ALL_FACILITY_ENVIRONMENT_QUALITY
    );
  };
