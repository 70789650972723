import { DEFAULT_PAGE_SIZE } from "@app/constants/pagination.constants";
import useSearchParams from "@app/hooks/useSearchParams";
import { useAppDispatch, useAppSelector } from "@app/redux/store";
import { SortEnum } from "@app/types/table.types";

import { updateCurrentParams, getAllFacilities } from "../facility";
import {
  GetFacilityListDef,
  SearchQueryParamDef,
} from "../types/facility.types";

const useFacilityList = () => {
  const dispatch = useAppDispatch();
  const { updateSearchParams, search } = useSearchParams<SearchQueryParamDef>();
  const { facilityList, loading, paginationData } = useAppSelector(state => ({
    facilityList: state.facility.facilityList,
    loading: state.facility.loading,
    paginationData: state.facility.paginationData,
  }));

  const parseSort = () => {
    if (search.sort) {
      return search.sort;
    }

    return undefined;
  };

  const parseOrderBy = () => {
    if (search.sort) {
      return search.orderBy;
    }

    return undefined;
  };

  const getInitialFacilityList = (reset?: boolean) => {
    const params: GetFacilityListDef = {
      name: !reset ? search.search ?? undefined : undefined,
      orderBy: parseOrderBy() ?? "is_active",
      sort: parseSort() ?? SortEnum.DESC,
      page: !reset ? search.page : 1,
      perPage: !reset
        ? search.pageSize ?? DEFAULT_PAGE_SIZE
        : DEFAULT_PAGE_SIZE,
    };

    dispatch(updateCurrentParams(params));
    dispatch(getAllFacilities());
  };

  const getUpdatedFacilityList = (params: GetFacilityListDef) => {
    dispatch(updateCurrentParams(params));
    dispatch(getAllFacilities());
  };

  return {
    getInitialFacilityList,
    getUpdatedFacilityList,
    facilityList,
    loading,
    paginationData,
    updateSearchParams,
  };
};

export default useFacilityList;
