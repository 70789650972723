import axios, { AxiosError, AxiosRequestConfig } from "axios";
import dayjs from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";

import { ENV } from "@app/constants/env";
import {
  AuthEndpointsEnum,
  clearUser,
  getTokens,
} from "@app/features/auth/auth";
import store from "@app/redux/store";

dayjs.extend(isSameOrAfter);

/**
 * All the endpoint that do not require an access token
 */
const anonymousEndpoints = [AuthEndpointsEnum.LOGIN.toString()];

/**
 * "Wrapper" around getTokens
 * can be changed to have refresh functionality if api supports it
 */
export const getRefreshedToken = () => {
  const { accessToken, expiresAt } = getTokens();

  const isTokenExpired = dayjs().isSameOrAfter(expiresAt);

  return { accessToken, isTokenExpired };
};

/**
 * Adds authorization headers to API calls
 * @param {AxiosRequestConfig} request
 */
const authInterceptor = async (request: AxiosRequestConfig) => {
  const isAnonymous = anonymousEndpoints.some(endpoint =>
    request.url?.startsWith(endpoint)
  );

  const { accessToken } = getRefreshedToken();

  if (accessToken) {
    request.headers.Authorization = `Bearer ${accessToken}`;
    return request;
  }

  if (!accessToken && !isAnonymous) {
    // TODO: handle when UNAUTHORIZED;
    // return Promise.reject(ApiStatusCodes.UNAUTHORIZED);
    return request;
  }

  return request;
};

const authErrorInterceptor = (error: AxiosError) => {
  if (error.response?.status === 401) {
    store.dispatch(clearUser()); // Clear user session
  }

  return Promise.reject(error);
};

/** Setup an API instance */
export const api = axios.create({
  baseURL: ENV.API_HOST,
  headers: { "Content-Type": "application/json" },
});

api.interceptors.request.use(authInterceptor);
api.interceptors.response.use(undefined, authErrorInterceptor);
