import { ReactElement } from "react";

import generatePicker from "antd/es/date-picker/generatePicker";
import { Dayjs } from "dayjs";
import { PickerSharedProps } from "rc-picker/lib/Picker";
import dayjsGenerateConfig from "rc-picker/lib/generate/dayjs";

import "antd/es/date-picker/style/index";

interface DateTimePickerProps extends PickerSharedProps<Dayjs> {
  picker?: "date" | "time";
  className?: string;
}

const AntdDatePicker = generatePicker<Dayjs>(dayjsGenerateConfig as never);

const DateTimePicker = ({ ...props }: DateTimePickerProps): ReactElement => {
  return <AntdDatePicker {...props} />;
};

export const { RangePicker } = AntdDatePicker;

export default DateTimePicker;
