import {
  ComponentType,
  ForwardRefExoticComponent,
  ReactElement,
  SVGProps,
} from "react";

import type { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";
import Icon from "@ant-design/icons/lib/components/Icon";

interface CustomIconProps extends Partial<CustomIconComponentProps> {
  component:
    | ComponentType<CustomIconComponentProps | SVGProps<SVGSVGElement>>
    | ForwardRefExoticComponent<CustomIconComponentProps>;
}

export const CustomIcon = ({
  component,
  ...props
}: CustomIconProps): ReactElement => <Icon component={component} {...props} />;

export const getIconReference = (icon: CustomIconProps["component"]) => {
  const reference = () => <CustomIcon component={icon} />;
  return reference;
};
