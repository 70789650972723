import ContentLayout from "@app/components/layouts/ContentLayout/ContentLayout";
import { useAppSelector } from "@app/redux/store";

import AdminForm from "../../components/AdminForm/AdminForm";

const AdminCreateScreen = () => {
  const { loading } = useAppSelector(state => ({
    loading: state.admin.loading,
  }));

  return (
    <ContentLayout header={{ title: "Create Admin" }}>
      <AdminForm loading={loading} />
    </ContentLayout>
  );
};

export default AdminCreateScreen;
