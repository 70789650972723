import { AxiosResponse } from "axios";

import { api } from "@app/api/api";

import { RechargeEndpointsEnum } from "../constants/recharge.endpoints";
import { DeleteRechargeAmountDef, CreateRechargeAmountDef } from "../recharge";

export const getAmountList = (): Promise<AxiosResponse> => {
  return api.get(RechargeEndpointsEnum.GET_AMOUNT_LIST);
};

export const deleteAmount = (
  payload: DeleteRechargeAmountDef
): Promise<AxiosResponse> => {
  return api.delete(
    `${RechargeEndpointsEnum.DELETE_RECHARGE_AMOUNT}/${payload.id}`
  );
};

export const createRechargeAmount = (
  payload: CreateRechargeAmountDef
): Promise<AxiosResponse> => {
  return api.post(RechargeEndpointsEnum.CREATE_RECHARGE_AMOUNT, { ...payload });
};
