import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  InitialStateDef,
  GetFacilityListDef,
  facilityErrorHelper,
  CreateFacilityDef,
  GetFacilityDetailsDef,
  ModifyFacilityDef,
  AddServiceToFacilityDef,
  FacilityServiceDef,
  RemoveServiceFromFacilityDef,
  AddDeviceToFacilityDef,
  ToggleFacilityStatusDef,
  GetFacilityExpenseHistoryDef,
  DeleteExpenseDef,
  CreateFacilityExpenseDef,
  GetFacilityExpenseDetailsDef,
  UpdateFacilityExpenseDef,
  GetFacilityUsageHistoryDef,
  GetFacilityTotalExpenseDef,
  FacilityStatsDef,
  GetFacilityTotalIncomeDef,
  GetFacilityFeedbackListDef,
  GetFacilityEnvironmentQualityListDef,
  RemoveDeviceFromFacilityDef,
  GetFacilityTotalUsageDef,
  GetFacilitySubscriptionIncomeDef,
} from "@app/features/facility/facility";
import { RootState } from "@app/redux/root-reducer";

import {
  addDeviceToFacility,
  addServiceToFacility,
  createFacility,
  createFacilityExpense,
  deleteExpense,
  getFacilityDetails,
  getFacilityExpenseDetails,
  getFacilityExpenseHistory,
  getFacilityList,
  getFacilityUsageHistory,
  getFacilityTotalExpense,
  getFacilityTotalIncome,
  getServiceList,
  removeServiceFromFacility,
  toggleFacilityStatus,
  updateFacility,
  updateFacilityExpense,
  getFacilityFeedbackList,
  getFacilityEnvironmentQualityList,
  removeDeviceFromFacility,
  clearAllFacilityEnvironmentQuality,
  getFacilityTotalUsage,
  getFacilitySubscriptionIncome,
} from "../api/facility.api";
import { FACILITY_FEATURE_KEY } from "../constants/facility.keys";

const initialState: InitialStateDef = {
  facilityList: [],
  expenseList: [],
  usageList: [],
  error: false,
  loading: false,
  serviceLoading: false,
  statusLoading: false,
  statsLoading: false,
  currentParams: {},
  facilityStats: {},
  facilityFeedbacks: [],
  environmentQualityList: [],
};

export const getAllFacilities = createAsyncThunk(
  `${FACILITY_FEATURE_KEY}/getAllFacilities`,
  async (values: undefined, { rejectWithValue, getState }) => {
    const { facility } = getState() as RootState;

    try {
      const response = await getFacilityList(
        facility.currentParams as GetFacilityListDef
      );

      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const entryAFacility = createAsyncThunk(
  `${FACILITY_FEATURE_KEY}/entryAFacility`,
  async (payload: CreateFacilityDef, { rejectWithValue }) => {
    try {
      const response = await createFacility(payload);

      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getAFacility = createAsyncThunk(
  `${FACILITY_FEATURE_KEY}/getAFacility`,
  async (payload: GetFacilityDetailsDef, { rejectWithValue }) => {
    try {
      const response = await getFacilityDetails(payload);

      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const modifyAFacility = createAsyncThunk(
  `${FACILITY_FEATURE_KEY}/modifyAFacility`,
  async (payload: ModifyFacilityDef, { rejectWithValue }) => {
    try {
      const response = await updateFacility(payload);

      return response.data.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getAllServices = createAsyncThunk(
  `${FACILITY_FEATURE_KEY}/getAllServices`,
  async (payload: undefined, { rejectWithValue }) => {
    try {
      const response = await getServiceList();

      return response.data.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const addService = createAsyncThunk(
  `${FACILITY_FEATURE_KEY}/addService`,
  async (payload: AddServiceToFacilityDef, { rejectWithValue }) => {
    try {
      const response = await addServiceToFacility(payload);

      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const removeService = createAsyncThunk(
  `${FACILITY_FEATURE_KEY}/removeService`,
  async (payload: RemoveServiceFromFacilityDef, { rejectWithValue }) => {
    try {
      const response = await removeServiceFromFacility(payload);

      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const addDevice = createAsyncThunk(
  `${FACILITY_FEATURE_KEY}/addDevice`,
  async (payload: AddDeviceToFacilityDef, { rejectWithValue }) => {
    try {
      const response = await addDeviceToFacility(payload);

      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteDevice = createAsyncThunk(
  `${FACILITY_FEATURE_KEY}/deleteDevice`,
  async (payload: RemoveDeviceFromFacilityDef, { rejectWithValue }) => {
    try {
      const response = await removeDeviceFromFacility(payload);

      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const toggleStatus = createAsyncThunk(
  `${FACILITY_FEATURE_KEY}/toggleStatus`,
  async (payload: ToggleFacilityStatusDef, { rejectWithValue }) => {
    try {
      const response = await toggleFacilityStatus(payload);

      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getExpenseHistory = createAsyncThunk(
  `${FACILITY_FEATURE_KEY}/getExpenseHistory`,
  async (values: undefined, { rejectWithValue, getState }) => {
    const { facility } = getState() as RootState;

    try {
      const response = await getFacilityExpenseHistory(
        facility.currentParams as GetFacilityExpenseHistoryDef
      );

      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const removeExpense = createAsyncThunk(
  `${FACILITY_FEATURE_KEY}/removeExpense`,
  async (payload: DeleteExpenseDef, { rejectWithValue }) => {
    try {
      const response = await deleteExpense(payload);

      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const addExpense = createAsyncThunk(
  `${FACILITY_FEATURE_KEY}/addExpense`,
  async (payload: CreateFacilityExpenseDef, { rejectWithValue }) => {
    try {
      const response = await createFacilityExpense(payload);

      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getExpenseDetails = createAsyncThunk(
  `${FACILITY_FEATURE_KEY}/getExpenseDetails`,
  async (payload: GetFacilityExpenseDetailsDef, { rejectWithValue }) => {
    try {
      const response = await getFacilityExpenseDetails(payload);

      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const modifyExpense = createAsyncThunk(
  `${FACILITY_FEATURE_KEY}/modifyExpense`,
  async (payload: UpdateFacilityExpenseDef, { rejectWithValue }) => {
    try {
      const response = await updateFacilityExpense(payload);

      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getUsageHistory = createAsyncThunk(
  `${FACILITY_FEATURE_KEY}/getUsageHistory`,
  async (values: undefined, { rejectWithValue, getState }) => {
    const { facility } = getState() as RootState;

    try {
      const response = await getFacilityUsageHistory(
        facility.currentParams as GetFacilityUsageHistoryDef
      );

      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const getFacilityExpense = createAsyncThunk(
  `${FACILITY_FEATURE_KEY}/getFacilityStats`,
  async (payload: GetFacilityTotalExpenseDef, { rejectWithValue }) => {
    try {
      const response = await getFacilityTotalExpense(payload);

      return response.data.total_expense;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getFacilityIncome = createAsyncThunk(
  `${FACILITY_FEATURE_KEY}/getFacilityIncome`,
  async (payload: GetFacilityTotalIncomeDef, { rejectWithValue }) => {
    try {
      const response = await getFacilityTotalIncome(payload);

      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getSubscriptionIncome = createAsyncThunk(
  `${FACILITY_FEATURE_KEY}/getFacilitySubscriptionIncome`,
  async (payload: GetFacilitySubscriptionIncomeDef, { rejectWithValue }) => {
    try {
      const response = await getFacilitySubscriptionIncome(payload);

      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getFacilityUsage = createAsyncThunk(
  `${FACILITY_FEATURE_KEY}/getFacilityUsage`,
  async (payload: GetFacilityTotalUsageDef, { rejectWithValue }) => {
    try {
      const response = await getFacilityTotalUsage(payload);

      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getFacilityFeedbacks = createAsyncThunk(
  `${FACILITY_FEATURE_KEY}/getFacilityFeedbacks`,
  async (payload: GetFacilityFeedbackListDef, { rejectWithValue }) => {
    try {
      const response = await getFacilityFeedbackList(payload);

      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getAllEnvironmentQuality = createAsyncThunk(
  `${FACILITY_FEATURE_KEY}/getAllEnvironmentQuality`,
  async (payload: undefined, { rejectWithValue, getState }) => {
    try {
      const { facility } = getState() as RootState;

      const response = await getFacilityEnvironmentQualityList(
        facility.currentParams as GetFacilityEnvironmentQualityListDef
      );

      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const clearAllEnvironmentQuality = createAsyncThunk(
  `${FACILITY_FEATURE_KEY}/clearAllEnvironmentQuality`,
  async (payload: undefined, { rejectWithValue }) => {
    try {
      const response = await clearAllFacilityEnvironmentQuality();

      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

const facilitySlice = createSlice({
  name: FACILITY_FEATURE_KEY,
  initialState,
  reducers: {
    updateCurrentParams(
      state,
      action: PayloadAction<
        GetFacilityListDef | GetFacilityEnvironmentQualityListDef
      >
    ) {
      state.currentParams = {
        ...state.currentParams,
        ...action.payload,
      };
    },
    clearFacilityDetails(state) {
      state.facilityDetails = undefined;
    },
    resetPaginationAndParams(state) {
      state.paginationData = undefined;
      state.currentParams = {};
    },
    clearExpenseDetails(state) {
      state.expenseDetails = undefined;
    },
  },
  extraReducers: builder => {
    /**
     * GET_FACILITY_LIST
     */
    builder.addCase(getAllFacilities.pending, state => {
      state.error = false;
      state.loading = true;
    });
    builder.addCase(getAllFacilities.fulfilled, (state, action) => {
      const { data, meta } = action.payload;

      state.loading = false;
      state.facilityList = data;
      state.paginationData = {
        currentPage: meta?.current_page,
        perPage: meta?.per_page,
        total: meta?.total,
      };
    });
    builder.addCase(getAllFacilities.rejected, state => {
      facilityErrorHelper(state);
    });
    /**
     * CREATE_FACILITY
     */
    builder.addCase(entryAFacility.pending, state => {
      state.error = false;
      state.loading = true;
    });
    builder.addCase(entryAFacility.fulfilled, state => {
      state.loading = false;
    });
    builder.addCase(entryAFacility.rejected, state => {
      facilityErrorHelper(state);
    });
    /**
     * GET_FACILITY_DETAILS
     */
    builder.addCase(getAFacility.pending, state => {
      state.error = false;
      state.loading = true;
    });
    builder.addCase(
      getAFacility.fulfilled,
      (state, action: PayloadAction<InitialStateDef["facilityDetails"]>) => {
        state.loading = false;
        state.facilityDetails = action.payload;
      }
    );
    builder.addCase(getAFacility.rejected, state => {
      facilityErrorHelper(state);
    });
    /**
     * GET_SERVICE_LIST
     */
    builder.addCase(getAllServices.pending, state => {
      state.error = false;
      state.serviceLoading = true;
    });
    builder.addCase(
      getAllServices.fulfilled,
      (state, action: PayloadAction<InitialStateDef["serviceList"]>) => {
        state.serviceLoading = false;
        state.serviceList = action.payload;
      }
    );
    builder.addCase(getAllServices.rejected, state => {
      facilityErrorHelper(state);
    });
    /**
     * UPDATE_FACILITY
     */
    builder.addCase(modifyAFacility.pending, state => {
      state.error = false;
      state.loading = true;
    });
    builder.addCase(
      modifyAFacility.fulfilled,
      (state, action: PayloadAction<InitialStateDef["facilityDetails"]>) => {
        state.loading = false;

        if (action.payload) {
          state.facilityDetails = {
            ...state.facilityDetails,
            ...action.payload,
          };
        }
      }
    );
    builder.addCase(modifyAFacility.rejected, state => {
      facilityErrorHelper(state);
    });
    /**
     * ADD_SERVICE_TO_FACILITY
     */
    builder.addCase(addService.pending, state => {
      state.error = false;
      state.loading = true;
    });
    builder.addCase(
      addService.fulfilled,
      (state, action: PayloadAction<FacilityServiceDef>) => {
        state.loading = false;
        state.facilityDetails?.services.push(action.payload);
      }
    );
    builder.addCase(addService.rejected, state => {
      facilityErrorHelper(state);
    });
    /**
     * REMOVE_SERVICE_FROM_FACILITY
     */
    builder.addCase(removeService.pending, state => {
      state.error = false;
      state.loading = true;
    });
    builder.addCase(removeService.fulfilled, state => {
      state.loading = false;
    });
    builder.addCase(removeService.rejected, state => {
      facilityErrorHelper(state);
    });
    /**
     * ADD_DEVICE_TO_FACILITY
     */
    builder.addCase(addDevice.pending, state => {
      state.error = false;
      state.loading = true;
    });
    builder.addCase(addDevice.fulfilled, state => {
      state.loading = false;
    });
    builder.addCase(addDevice.rejected, state => {
      facilityErrorHelper(state);
    });
    /**
     * TOGGLE_FACILITY_STATUS
     */
    builder.addCase(toggleStatus.pending, state => {
      state.error = false;
      state.statusLoading = true;
    });
    builder.addCase(toggleStatus.fulfilled, state => {
      state.statusLoading = false;
    });
    builder.addCase(toggleStatus.rejected, state => {
      facilityErrorHelper(state);
    });
    /**
     * GET_FACILITY_EXPENSE_HISTORY
     */
    builder.addCase(getExpenseHistory.pending, state => {
      state.error = false;
      state.loading = true;
    });
    builder.addCase(getExpenseHistory.fulfilled, (state, action) => {
      const { data, meta } = action.payload;

      state.loading = false;
      state.expenseList = data;
      state.paginationData = {
        currentPage: meta?.current_page,
        perPage: meta?.per_page,
        total: meta?.total,
      };
    });
    builder.addCase(getExpenseHistory.rejected, state => {
      facilityErrorHelper(state);
    });
    /**
     * DELETE_FACILITY_EXPENSE
     */
    builder.addCase(removeExpense.pending, state => {
      state.error = false;
      state.loading = true;
    });
    builder.addCase(removeExpense.fulfilled, state => {
      state.loading = false;
    });
    builder.addCase(removeExpense.rejected, state => {
      facilityErrorHelper(state);
    });
    /**
     * CREATE_FACILITY_EXPENSE
     */
    builder.addCase(addExpense.pending, state => {
      state.error = false;
      state.loading = true;
    });
    builder.addCase(addExpense.fulfilled, state => {
      state.loading = false;
    });
    builder.addCase(addExpense.rejected, state => {
      facilityErrorHelper(state);
    });
    /**
     * GET_FACILITY_EXPENSE_DETAILS
     */
    builder.addCase(getExpenseDetails.pending, state => {
      state.error = false;
      state.loading = true;
    });
    builder.addCase(
      getExpenseDetails.fulfilled,
      (state, action: PayloadAction<InitialStateDef["expenseDetails"]>) => {
        state.loading = false;
        state.expenseDetails = action.payload;
      }
    );
    builder.addCase(getExpenseDetails.rejected, state => {
      facilityErrorHelper(state);
    });
    /**
     * UPDATE_FACILITY_EXPENSE
     */
    builder.addCase(modifyExpense.pending, state => {
      state.error = false;
      state.loading = true;
    });
    builder.addCase(
      modifyExpense.fulfilled,
      (state, action: PayloadAction<InitialStateDef["expenseDetails"]>) => {
        state.loading = false;
        state.expenseDetails = action.payload;
      }
    );
    builder.addCase(modifyExpense.rejected, state => {
      facilityErrorHelper(state);
    });
    /**
     * GET_FACILITY_USAGE_HISTORY
     */
    builder.addCase(getUsageHistory.pending, state => {
      state.error = false;
      state.loading = true;
    });
    builder.addCase(getUsageHistory.fulfilled, (state, action) => {
      const { data, meta } = action.payload;

      state.loading = false;
      state.usageList = data;
      state.paginationData = {
        currentPage: meta?.current_page,
        perPage: meta?.per_page,
        total: meta?.total,
      };
    });
    builder.addCase(getUsageHistory.rejected, state => {
      facilityErrorHelper(state);
    });
    /**
     * GET_FACILITY_TOTAL_EXPENSE
     */
    builder.addCase(getFacilityExpense.pending, state => {
      state.error = false;
      state.statsLoading = true;
    });
    builder.addCase(
      getFacilityExpense.fulfilled,
      (state, action: PayloadAction<FacilityStatsDef["total_expense"]>) => {
        state.statsLoading = false;
        state.facilityStats.total_expense = action.payload;
      }
    );
    builder.addCase(getFacilityExpense.rejected, state => {
      facilityErrorHelper(state);
    });
    /**
     * GET_FACILITY_TOTAL_INCOME
     */
    builder.addCase(getFacilityIncome.pending, state => {
      state.error = false;
      state.statsLoading = true;
    });
    builder.addCase(
      getFacilityIncome.fulfilled,
      (state, action: PayloadAction<FacilityStatsDef>) => {
        state.statsLoading = false;
        state.facilityStats = action.payload;
      }
    );
    builder.addCase(getFacilityIncome.rejected, state => {
      facilityErrorHelper(state);
    });
    /**
     * GET_FACILITY_TOTAL_USAGE
     */
    builder.addCase(getFacilityUsage.pending, state => {
      state.error = false;
      state.statsLoading = true;
    });
    builder.addCase(
      getFacilityUsage.fulfilled,
      (
        state,
        action: PayloadAction<
          Pick<FacilityStatsDef, "total_usage_male" | "total_usage_female">
        >
      ) => {
        state.statsLoading = false;
        state.facilityStats.total_usage_male = action.payload.total_usage_male;
        state.facilityStats.total_usage_female =
          action.payload.total_usage_female;
      }
    );
    builder.addCase(getFacilityUsage.rejected, state => {
      facilityErrorHelper(state);
    });
    /**
     * GET_FACILITY_FEEDBACK_LIST
     */
    builder.addCase(getFacilityFeedbacks.pending, state => {
      state.error = false;
      state.loading = true;
    });
    builder.addCase(getFacilityFeedbacks.fulfilled, (state, action) => {
      const { data, meta } = action.payload;

      state.loading = false;
      state.facilityFeedbacks = data;
      state.paginationData = {
        currentPage: meta?.current_page,
        perPage: meta?.per_page,
        total: meta?.total,
      };
    });
    builder.addCase(getFacilityFeedbacks.rejected, state => {
      facilityErrorHelper(state);
    });
    /**
     * GET_FACILITY_ENVIRONMENT_QUALITY_LIST
     */
    builder.addCase(getAllEnvironmentQuality.pending, state => {
      state.error = false;
      state.loading = true;
    });
    builder.addCase(getAllEnvironmentQuality.fulfilled, (state, action) => {
      const { data, meta } = action.payload;

      state.loading = false;
      state.environmentQualityList = data;
      state.paginationData = {
        currentPage: meta?.current_page,
        perPage: meta?.per_page,
        total: meta?.total,
      };
    });
    builder.addCase(getAllEnvironmentQuality.rejected, state => {
      facilityErrorHelper(state);
    });
    /**
     * REMOVE_DEVICE_FROM_FACILITY
     */
    builder.addCase(deleteDevice.pending, state => {
      state.error = false;
      state.loading = true;
    });
    builder.addCase(deleteDevice.fulfilled, state => {
      state.loading = false;
    });
    builder.addCase(deleteDevice.rejected, state => {
      facilityErrorHelper(state);
    });
    /**
     * CLEAR_ALL_FACILITY_ENVIRONMENT_QUALITY
     */
    builder.addCase(clearAllEnvironmentQuality.pending, state => {
      state.error = false;
      state.loading = true;
    });
    builder.addCase(clearAllEnvironmentQuality.fulfilled, state => {
      state.loading = false;
    });
    builder.addCase(clearAllEnvironmentQuality.rejected, state => {
      facilityErrorHelper(state);
    });
    /**
     * GET_FACILITY_SUBSCRIPTION_INCOME
     */
    builder.addCase(getSubscriptionIncome.pending, state => {
      state.error = false;
      state.statsLoading = true;
    });
    builder.addCase(
      getSubscriptionIncome.fulfilled,
      (
        state,
        action: PayloadAction<
          Pick<FacilityStatsDef, "total_income_subscriptions">
        >
      ) => {
        state.statsLoading = false;
        state.facilityStats.total_income_subscriptions =
          action.payload.total_income_subscriptions;
      }
    );
    builder.addCase(getSubscriptionIncome.rejected, state => {
      facilityErrorHelper(state);
    });
  },
});

export const {
  updateCurrentParams,
  clearFacilityDetails,
  resetPaginationAndParams,
  clearExpenseDetails,
} = facilitySlice.actions;

export const facilityReducer = facilitySlice.reducer;
