import {
  DollarOutlined,
  FileAddOutlined,
  UnorderedListOutlined,
} from "@ant-design/icons";

import { RouteItemDef } from "@app/types/route.types";

import { RechargePathsEnum } from "../constants/recharge.paths";
import AmountCreationScreen from "../screens/AmountCreationScreen/AmountCreationScreen";
import AmountListScreen from "../screens/AmountListScreen/AmountListScreen";
import RechargeRoutes from "./RechargeRoutes";

const RECHARGE_SCREEN: RouteItemDef = {
  id: "recharge",
  path: RechargePathsEnum.RECHARGE,
  navigationTitle: "Recharge",
  component: RechargeRoutes,
  sidebarIcon: DollarOutlined,
  nestedRoutes: [
    /* Amount list */
    {
      id: "amountList",
      path: RechargePathsEnum.AMOUNT_LIST,
      navigationTitle: "Amount List",
      component: AmountListScreen,
      sidebarIcon: UnorderedListOutlined,
    },
    /* Create new amount */
    {
      id: "createAmount",
      path: RechargePathsEnum.CREATE_AMOUNT,
      navigationTitle: "Create Amount",
      component: AmountCreationScreen,
      sidebarIcon: FileAddOutlined,
    },
  ],
};

export const RECHARGE_ROUTES = [RECHARGE_SCREEN];
