import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  createRechargeAmount,
  deleteAmount,
  getAmountList,
} from "../api/recharge.api";
import {
  RECHARGE_FEATURE_KEY,
  InitialStateDef,
  rechargeErrorHelper,
  DeleteRechargeAmountDef,
  CreateRechargeAmountDef,
} from "../recharge";

const initialState: InitialStateDef = {
  error: false,
  loading: false,
  amountList: [],
};

export const getAllAmounts = createAsyncThunk(
  `${RECHARGE_FEATURE_KEY}/getAllAmounts`,
  async (payload: undefined, { rejectWithValue }) => {
    try {
      const response = await getAmountList();

      return response.data.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const removeAmount = createAsyncThunk(
  `${RECHARGE_FEATURE_KEY}/removeAmount`,
  async (payload: DeleteRechargeAmountDef, { rejectWithValue }) => {
    try {
      const response = await deleteAmount(payload);

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const createAmount = createAsyncThunk(
  `${RECHARGE_FEATURE_KEY}/createAmount`,
  async (payload: CreateRechargeAmountDef, { rejectWithValue }) => {
    try {
      const response = await createRechargeAmount(payload);

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const rechargeSlice = createSlice({
  name: RECHARGE_FEATURE_KEY,
  initialState,
  reducers: {},
  extraReducers: builder => {
    /**
     * GET AMOUNT LIST
     */
    builder.addCase(getAllAmounts.pending, state => {
      state.error = false;
      state.loading = true;
    });
    builder.addCase(
      getAllAmounts.fulfilled,
      (state, action: PayloadAction<InitialStateDef["amountList"]>) => {
        state.loading = false;
        state.amountList = action.payload;
      }
    );
    builder.addCase(getAllAmounts.rejected, state => {
      rechargeErrorHelper(state);
    });
    /**
     * REMOVE AMOUNT
     */
    builder.addCase(removeAmount.pending, state => {
      state.error = false;
      state.loading = true;
    });
    builder.addCase(removeAmount.fulfilled, state => {
      state.loading = false;
    });
    builder.addCase(removeAmount.rejected, state => {
      rechargeErrorHelper(state);
    });
    /**
     * CREATE AMOUNT
     */
    builder.addCase(createAmount.pending, state => {
      state.error = false;
      state.loading = true;
    });
    builder.addCase(createAmount.fulfilled, state => {
      state.loading = false;
    });
    builder.addCase(createAmount.rejected, state => {
      rechargeErrorHelper(state);
    });
  },
});

export const rechargeReducer = rechargeSlice.reducer;
