import { FileAddOutlined, UnorderedListOutlined } from "@ant-design/icons";

import { ReactComponent as PackageIcon } from "@app/assets/images/icon-package.svg";
import { getIconReference } from "@app/components/atoms/CustomIcon/CustomIcon";
import { RouteItemDef } from "@app/types/route.types";

import { PackagePathsEnum } from "../constants/package.paths";
import PackageCreationScreen from "../screens/PackageCreationScreen/PackageCreationScreen";
import AmountListScreen from "../screens/PackageListScreen/PackageListScreen";
import PackageRoutes from "./PackageRoutes";

const PACKAGE_SCREEN: RouteItemDef = {
  id: "package",
  path: PackagePathsEnum.PACKAGE,
  navigationTitle: "Package",
  component: PackageRoutes,
  sidebarIcon: getIconReference(PackageIcon),
  nestedRoutes: [
    /* Package list */
    {
      id: "packageList",
      path: PackagePathsEnum.PACKAGE_LIST,
      navigationTitle: "Package List",
      component: AmountListScreen,
      sidebarIcon: UnorderedListOutlined,
    },
    /* Create new package */
    {
      id: "createPackage",
      path: PackagePathsEnum.CREATE_PACKAGE,
      navigationTitle: "Create Package",
      component: PackageCreationScreen,
      sidebarIcon: FileAddOutlined,
    },
  ],
};

export const PACKAGE_ROUTES = [PACKAGE_SCREEN];
