import ContentLayout from "@app/components/layouts/ContentLayout/ContentLayout";
import { useAppSelector } from "@app/redux/store";

import AdminForm, {
  AdminFormProps,
} from "../../../admin/components/AdminForm/AdminForm";

const MyAccountScreen = () => {
  const { user, loading } = useAppSelector(state => ({
    user: state.auth.user,
    loading: state.auth.accountLoading,
  }));

  const adminFormProps: AdminFormProps = {
    initialValues: user
      ? {
          id: Number(user.id),
          name: user.name,
          email: user.email,
          phone: user.phone_number,
        }
      : undefined,
  };

  return (
    <ContentLayout header={{ title: "My Account" }}>
      <AdminForm {...adminFormProps} profileMode loading={loading} />
    </ContentLayout>
  );
};

export default MyAccountScreen;
