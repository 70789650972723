export const DEFAULT_INPUT_LENGTH = 256;
export const DEFAULT_TEXT_AREA_LENGTH = 500;
export const DEFAULT_NUMBER_INPUT_PATTERN = /^\d{1,12}(\.\d{1,2})?$/; // Max 12 digit with max 2 decimal point
/** custom BD mobile no. only: supports following patterns for FE validation
 * 01234567890
 * 01234-567890
 * 01234 567890
 *
 * +8801234567890
 * +88-01234-567890
 * +88 01234 567890
 *
 * 008801234567890
 * 0088-01234-567890
 * 0088 01234 567890
 */
export const DEFAULT_BD_PHONE_NO_PATTERN =
  /^((\+|00)?88)?[-\s]*?01[0-9]{3}[-\s]*?[0-9]{6}$/;
