import { useEffect } from "react";

import { ExportOutlined } from "@ant-design/icons";
import { Button, Table, TablePaginationConfig, Tooltip } from "antd";
import { FilterValue, SorterResult } from "antd/lib/table/interface";
import dayjs from "dayjs";
import { useHistory, useLocation } from "react-router-dom";
import { usePrevious, useUnmount } from "react-use";

import TableView, {
  TableViewProps,
} from "@app/components/molecules/TableView/TableView";
import { DEFAULT_DATE_TIME_FORMAT } from "@app/constants/time.constants";
import { UserTypeEnum } from "@app/constants/user.constants";
import { UserPathsEnum } from "@app/features/user/user";
import { renderGender } from "@app/helpers/gender.helper";
import { exportTableData } from "@app/helpers/table.helper";
import { renderUserType } from "@app/helpers/user.helper";
import useSearchParams, { SearchParamDef } from "@app/hooks/useSearchParams";
import { useAppDispatch, useAppSelector } from "@app/redux/store";
import { SortEnum } from "@app/types/table.types";

import { resetPaginationAndParams } from "../../facility";
import useUsageHistory from "../../hooks/useUsageHistory";
import {
  GetFacilityUsageHistoryDef,
  UsageDef,
} from "../../types/facility.types";
import UsageFilter from "../UsageFilter/UsageFilter";
import styles from "./UsageHistory.module.scss";

type UsagesTableProps = TableViewProps<UsageDef>;

const UsageHistory = ({ ...props }: UsagesTableProps) => {
  const location = useLocation();
  const history = useHistory();
  const prevLocation = usePrevious(location);
  const { getSortOrder, setCurrentSort } = useSearchParams<SearchParamDef>();
  const {
    paginationData,
    loading,
    getInitialUsageList,
    getUpdatedUsageList,
    facilityId,
    usageList,
  } = useUsageHistory();
  const dispatch = useAppDispatch();
  const { currentParams } = useAppSelector(state => ({
    currentParams: state.facility.currentParams as GetFacilityUsageHistoryDef,
  }));

  useEffect(() => {
    // Fetch usage list on initial load
    if (prevLocation?.search === undefined) {
      getInitialUsageList();
    }
  }, [getInitialUsageList, prevLocation?.search]);

  useUnmount(() => {
    dispatch(resetPaginationAndParams());
  });

  const parseSort = (
    sorter: SorterResult<UsageDef> | SorterResult<UsageDef>[]
  ) => {
    if (!Array.isArray(sorter)) {
      const { order } = sorter;

      if (order) {
        return order === "ascend" ? SortEnum.ASC : SortEnum.DESC;
      }
    }

    return undefined;
  };

  const parseOrderBy = (
    sorter: SorterResult<UsageDef> | SorterResult<UsageDef>[]
  ) => {
    if (!Array.isArray(sorter)) {
      const { order } = sorter;

      if (order) {
        return sorter.columnKey === "time" ? "created_at" : sorter.columnKey;
      }
    }

    return undefined;
  };

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<UsageDef> | SorterResult<UsageDef>[]
  ) => {
    if (!loading) {
      getUpdatedUsageList({
        facilityId: String(facilityId),
        page: pagination.current,
        perPage: pagination.pageSize,
        sort: parseSort(sorter),
        orderBy: parseOrderBy(sorter) as string,
      });
    }

    if (!Array.isArray(sorter)) {
      setCurrentSort(sorter.order);
    }
  };

  const handleView = (record: UsageDef) => {
    history.push(`${UserPathsEnum.USER_DETAILS}/${record.user.id}`);
  };

  const handleExport = () => {
    const facilityInfo = [
      "Facility Name",
      usageList[0]?.facility_service.facility.name,
      "",
      "",
      "",
      "",
    ];
    const dateInfo = [
      "Date",
      !currentParams.from || !currentParams.to
        ? "N/A"
        : `${currentParams.from} - ${currentParams.to}`,
      "",
      "",
      "",
      "",
    ];
    const emptyRow = ["", "", "", "", "", ""];
    const totalCountRow = [
      "Total Count",
      String(paginationData?.total ?? 0),
      "",
      "",
      "",
      "",
    ];
    const header = [
      "User's Name",
      "Gender",
      "User Type",
      "Service's Name",
      "Price",
      "Time",
    ];
    const data = usageList.map(usage => ({
      title: `${usage.user.first_name} ${usage.user.last_name}`,
      gender: renderGender(usage.gender),
      userType: renderUserType(usage.user_type),
      serviceName: usage.facility_service.service.title_en,
      price: String(usage.price),
      time: dayjs(usage.created_at).format(DEFAULT_DATE_TIME_FORMAT),
    }));

    exportTableData(data, `usage_history_${Date.now()}`, [
      facilityInfo,
      dateInfo,
      totalCountRow,
      emptyRow,
      header,
    ]);
  };

  return (
    <TableView<UsageDef>
      dataSource={usageList}
      loading={loading}
      actionTitle="Action"
      pagination={{
        current: paginationData?.currentPage,
        pageSize: paginationData?.perPage,
        total: paginationData?.total,
        showSizeChanger: true,
        pageSizeOptions: ["6", "8", "10", "15", "25"],
      }}
      title={() => (
        <div>
          <div className={styles.usageHistoryTableTitle}>
            <UsageFilter facilityId={Number(facilityId)} />
          </div>

          <div>
            <span className="font-weight-bold">Total Count:</span>
            <span className={styles.usageCount}>
              {paginationData?.total ?? 0}
            </span>

            <Tooltip
              title={!usageList.length ? "There is no data to export" : ""}
            >
              <span>
                <Button
                  className={styles.exportButton}
                  type="primary"
                  onClick={handleExport}
                  icon={<ExportOutlined />}
                  disabled={!usageList.length}
                >
                  Export Data
                </Button>
              </span>
            </Tooltip>
          </div>
        </div>
      )}
      onChange={handleTableChange}
      onView={handleView}
      {...props}
    >
      <Table.Column
        title="User Name"
        key="name"
        dataIndex="user"
        render={(name: string, record: UsageDef) =>
          `${record.user.first_name} ${record.user.last_name}`
        }
      />
      <Table.Column
        title="Gender"
        key="gender"
        dataIndex="gender"
        render={(gender: string) => renderGender(gender)}
      />
      <Table.Column
        title="User Type"
        key="userType"
        dataIndex="user_type"
        render={(userType: string) => renderUserType(userType)}
      />
      <Table.Column
        title="Service's Name"
        key="serviceName"
        dataIndex="user_type"
        render={(userType: string, record: UsageDef) =>
          record.facility_service.service.title_en
        }
      />
      <Table.Column
        title="Price"
        key="price"
        dataIndex="price"
        render={(price: string, record: UsageDef) =>
          Number(
            record.user_type === UserTypeEnum.PACKAGE_USER ? 0 : price
          ).toFixed(2)
        }
      />
      <Table.Column
        title="Time"
        key="time"
        dataIndex="created_at"
        render={(time: string) =>
          time ? dayjs(time).format(DEFAULT_DATE_TIME_FORMAT) : ""
        }
        sorter
        sortOrder={getSortOrder("time")}
      />
    </TableView>
  );
};

export default UsageHistory;
