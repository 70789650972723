// import { useEffect, useState } from "react";

import { Row, Col, Descriptions, message } from "antd";
import dayjs from "dayjs";
import { useHistory, useParams } from "react-router-dom";
import { useMount } from "react-use";

import { DEFAULT_DATE_FORMAT } from "@app/constants/time.constants";
import { renderGender } from "@app/helpers/gender.helper";
import { useAppDispatch, useAppSelector } from "@app/redux/store";

import {
  getUserDetails,
  UserPathsEnum,
  // unblockAUser,
  // blockAUser,
} from "../../user";
import styles from "./UserInfo.module.scss";

interface UserInfoParams {
  id?: string;
}

const UserInfo = () => {
  const history = useHistory();
  const params = useParams<UserInfoParams>();
  const dispatch = useAppDispatch();
  const { userDetails } = useAppSelector(state => ({
    userDetails: state.user.userDetails,
  }));
  // const [
  //   isBlocked,
  //   setIsBlocked,
  // ] = useState(false);

  useMount(async () => {
    if (params.id) {
      const response = await dispatch(
        getUserDetails({ id: Number(params.id) })
      );

      if (!getUserDetails.fulfilled.match(response)) {
        message.error("User details fetching failed");
      }
    } else {
      history.replace(UserPathsEnum.USER_LIST);
    }
  });

  // useEffect(() => {
  //   setIsBlocked(Boolean(userDetails?.blocked));
  // }, [userDetails?.blocked]);

  /* TODO: Uncomment when needed */
  // const blockUnblockUser = async () => {
  //   if (isBlocked) {
  //     const response = await dispatch(unblockAUser({ id: Number(params.id) }));

  //     if (unblockAUser.fulfilled.match(response)) {
  //       message.success("User unblocked successfully.");
  //     } else {
  //       message.error("User unblocking failed");
  //     }
  //   } else {
  //     const response = await dispatch(blockAUser({ id: Number(params.id) }));

  //     if (blockAUser.fulfilled.match(response)) {
  //       message.success("User blocked successfully.");
  //     } else {
  //       message.error("User blocking failed");
  //     }
  //   }
  // };

  // const handleBlock = () => {
  //   blockUnblockUser();
  // };

  return (
    <Row justify="space-between">
      <Col xs={20}>
        <Descriptions
          className={styles.description}
          layout="vertical"
          column={2}
          colon={false}
        >
          <Descriptions.Item label="Name">
            {`${userDetails?.first_name} ${userDetails?.last_name}`}
          </Descriptions.Item>
          <Descriptions.Item label="Phone">
            {userDetails?.phone_number}
          </Descriptions.Item>
          <Descriptions.Item label="Email">
            {userDetails?.email}
          </Descriptions.Item>
          <Descriptions.Item label="Gender">
            {renderGender(userDetails?.gender)}
          </Descriptions.Item>
          <Descriptions.Item label="Balance">
            {userDetails?.balance}
          </Descriptions.Item>
          <Descriptions.Item label="Address" span={2}>
            {userDetails?.address}
          </Descriptions.Item>
          <Descriptions.Item label="Join Date">
            {dayjs(userDetails?.created_at).format(DEFAULT_DATE_FORMAT)}
          </Descriptions.Item>
        </Descriptions>
      </Col>

      {/* TODO: Uncomment when needed */}
      {/* <Col>
        <div>
          <Switch
            onChange={handleBlock}
            checkedChildren="Unblocked"
            unCheckedChildren="Blocked"
            checked={!isBlocked}
            disabled={loading}
          />
        </div>
      </Col> */}
    </Row>
  );
};

export default UserInfo;
