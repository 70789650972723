import { AxiosResponse } from "axios";

import { api } from "@app/api/api";

import { AdminEndpointsEnum } from "../constants/admin.endpoints";
import {
  CreateAdminDef,
  DeleteAdminDef,
  GetAdminListDef,
  UpdateAdminDef,
} from "../types/admin.types";

export const getAdminList = (data: GetAdminListDef): Promise<AxiosResponse> => {
  return api.get(AdminEndpointsEnum.GET_ADMIN_LIST, {
    params: {
      page: data.page,
      per_page: data.perPage,
      search_query: data.searchQuery,
      sort: data.sort,
      order_by: data.orderBy,
    },
  });
};

export const deleteAdmin = (data: DeleteAdminDef): Promise<AxiosResponse> => {
  return api.delete(`${AdminEndpointsEnum.DELETE_ADMIN_LIST}/${data.id}`);
};

export const createAdmin = (data: CreateAdminDef): Promise<AxiosResponse> => {
  return api.post(AdminEndpointsEnum.CREATE_ADMIN_LIST, { ...data });
};

export const updateAdmin = (data: UpdateAdminDef): Promise<AxiosResponse> => {
  // eslint-disable-next-line camelcase
  const { id, name, password, phone_number } = data;

  return api.patch(`${AdminEndpointsEnum.UPDATE_ADMIN}/${id}`, {
    name,
    phone_number,
    password,
  });
};
