import { memo, useEffect } from "react";

import { Select } from "antd";
import dayjs from "dayjs";
import { useLocation, useParams } from "react-router-dom";
import { usePrevious } from "react-use";

import { useForm } from "@app/components/atoms/Form/Form";
import PageFilter, {
  FilterItem,
} from "@app/components/molecules/PageFilter/PageFilter";
import {
  DEFAULT_API_DATE_FORMAT,
  DEFAULT_DATE_RANGE,
} from "@app/constants/time.constants";

import useUserHistories from "../../hooks/useUserHistories";
import styles from "./UserHistoriesFilter.module.scss";

export interface UserFilterProps {
  dateRange?: string;
}

const { Option } = Select;

interface UserHistoryFilterParams {
  id?: string;
}

const UsersHistoriesFilter = () => {
  const params = useParams<UserHistoryFilterParams>();
  const location = useLocation();
  const prevLocation = usePrevious(location);
  const [form] = useForm();
  const {
    getInitialUserPurchaseList,
    getUpdatedUserPurchaseList,
    purchaseHistoryLoading,
    getInitialPackageSubscriptionList,
    getUpdatedPackageSubscriptionList,
    packageSubscriptionHistoryLoading,
    updateSearchParams,
  } = useUserHistories();

  const userId = Number(params.id);

  useEffect(() => {
    // Fetch Recharge History on initial load
    if (prevLocation?.search === undefined) {
      getInitialUserPurchaseList(userId);
      getInitialPackageSubscriptionList(userId);
    }
  }, [
    getInitialUserPurchaseList,
    getInitialPackageSubscriptionList,
    prevLocation?.search,
    userId,
  ]);

  const filterUserHistoriesByDateRange = () => {
    const currentDate = dayjs().format(DEFAULT_API_DATE_FORMAT);

    updateSearchParams({ orderBy: undefined, sort: undefined }); // Resets search params
    getUpdatedUserPurchaseList({
      id: userId,
      from: dayjs(currentDate)
        .subtract(
          form.getFieldValue("dateRange") - 1 || DEFAULT_DATE_RANGE - 1,
          "days"
        )
        .format(DEFAULT_API_DATE_FORMAT),
      to: currentDate,
      orderBy: undefined,
      sort: undefined,
    });

    getUpdatedPackageSubscriptionList({
      id: userId,
      from: dayjs(currentDate)
        .subtract(
          form.getFieldValue("dateRange") - 1 || DEFAULT_DATE_RANGE - 1,
          "days"
        )
        .format(DEFAULT_API_DATE_FORMAT),
      to: currentDate,
      orderBy: undefined,
      sort: undefined,
    });
  };

  const handleFilters = async () => {
    form.submit();

    if (!purchaseHistoryLoading || !packageSubscriptionHistoryLoading) {
      filterUserHistoriesByDateRange();
    }
  };

  return (
    <PageFilter<UserFilterProps> parseNumbers={["dateRange"]} form={form}>
      <FilterItem className={styles.filterLabel} name="dateRange">
        <Select
          placeholder="Select a date range"
          onSelect={handleFilters}
          className={styles.dateFilter}
          defaultValue={7}
        >
          <Option value={7}>Last 7 days</Option>
          <Option value={30}>Last 30 days</Option>
          <Option value={90}>Last 90 days</Option>
        </Select>
      </FilterItem>
    </PageFilter>
  );
};

export default memo(UsersHistoriesFilter);
