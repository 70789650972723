import dayjs from "dayjs";
import { useParams } from "react-router-dom";

import { DEFAULT_PAGE_SIZE } from "@app/constants/pagination.constants";
import {
  DEFAULT_API_DATE_FORMAT,
  DEFAULT_DATE_RANGE,
} from "@app/constants/time.constants";
import useSearchParams, { SearchParamDef } from "@app/hooks/useSearchParams";
import { useAppDispatch, useAppSelector } from "@app/redux/store";

import { updateCurrentParams, getUsageHistory } from "../facility";
import {
  GetFacilityUsageHistoryDef,
  SearchQueryParamDef,
} from "../types/facility.types";

type UsageHistoryParams = {
  id?: string;
};

const useUsageHistory = () => {
  const dispatch = useAppDispatch();
  const { updateSearchParams, search } =
    useSearchParams<SearchParamDef<SearchQueryParamDef>>();
  const { id } = useParams<UsageHistoryParams>();
  const { usageList, loading, paginationData } = useAppSelector(state => ({
    usageList: state.facility.usageList,
    loading: state.facility.loading,
    paginationData: state.facility.paginationData,
  }));

  const parseSort = () => {
    if (!search.sort) {
      return undefined;
    }

    return search.sort;
  };

  const parseOrderBy = () => {
    if (!search.sort) {
      return undefined;
    }

    return search.orderBy === "time" ? "created_at" : search.orderBy;
  };

  const getInitialUsageList = () => {
    const currentDate = dayjs().format(DEFAULT_API_DATE_FORMAT);

    const params: GetFacilityUsageHistoryDef = {
      from: search.dateRange?.[0]
        ? dayjs(search.dateRange?.[0]).format(DEFAULT_API_DATE_FORMAT)
        : dayjs(currentDate)
            .subtract(DEFAULT_DATE_RANGE - 1, "days")
            .format(DEFAULT_API_DATE_FORMAT),
      to: search.dateRange?.[1]
        ? dayjs(search.dateRange?.[1]).format(DEFAULT_API_DATE_FORMAT)
        : currentDate,
      facilityId: String(id),
      orderBy: parseOrderBy(),
      sort: parseSort(),
      page: search.page ?? undefined,
      perPage: search.pageSize ?? DEFAULT_PAGE_SIZE,
      gender: search.gender,
      userType: search.type,
    };

    dispatch(updateCurrentParams(params));
    dispatch(getUsageHistory());
  };

  const getUpdatedUsageList = (params: GetFacilityUsageHistoryDef) => {
    dispatch(updateCurrentParams(params));
    dispatch(getUsageHistory());
  };

  return {
    getInitialUsageList,
    getUpdatedUsageList,
    usageList,
    loading,
    paginationData,
    updateSearchParams,
    facilityId: id,
    search,
  };
};

export default useUsageHistory;
