export enum ViewActionTypeEnum {
  GENERIC = "View",
  RECHARGE_HISTORY = "Recharge History",
}
export enum ToggleActionTypeEnum {
  ACTIVE_INACTIVE,
  ENABLE_DISABLE,
}

export const DYNAMIC_SCROLL_HEIGHT_FOR_BOTTOM_OF_SCREEN_TABLE =
  window.innerHeight - 350;
export const TABLE_SCROLL_HEIGHT_7_PLUS_ROWS = 415;
