import { memo } from "react";

import { useForm } from "antd/lib/form/Form";
import dayjs from "dayjs";
import { useMount } from "react-use";

import DateTimePicker from "@app/components/atoms/DateTimePicker/DateTimePicker";
import PageFilter, {
  FilterItem,
} from "@app/components/molecules/PageFilter/PageFilter";
import {
  DEFAULT_API_DATE_FORMAT,
  DEFAULT_DATE_FORMAT,
} from "@app/constants/time.constants";

import useEnvironmentQualityList from "../../hooks/useEnvironmentQualityList";
import styles from "./EnvironmentQualityFilter.module.scss";

export interface EnvironmentQualityFilterDef {
  date?: string;
}

export interface EnvironmentQualityFilterProps {
  facilityId: number;
}

const EnvironmentQualityFilter = ({
  facilityId,
}: EnvironmentQualityFilterProps) => {
  const [form] = useForm();
  const {
    loading,
    updateSearchParams,
    getUpdatedEnvironmentQualityList,
    search,
  } = useEnvironmentQualityList();

  useMount(() => {
    if (!search.date) {
      form.setFieldsValue({ date: dayjs() });
    }
  });

  const searchEnvironmentHistory = () => {
    const date = form.getFieldValue("date") || undefined;

    updateSearchParams({
      date,
      page: 1,
      orderBy: undefined,
      sort: undefined,
    }); // Resets search params
    getUpdatedEnvironmentQualityList({
      date: date ? dayjs(date).format(DEFAULT_API_DATE_FORMAT) : undefined,
      facility_id: Number(facilityId),
      page: 1,
      order_by: undefined,
      sort: undefined,
    });
  };

  const handleSearch = async () => {
    if (!loading) {
      searchEnvironmentHistory();
    }
  };

  return (
    <PageFilter<EnvironmentQualityFilterDef>
      parseDates
      form={form}
      submitOnChange
      onSubmit={handleSearch}
    >
      <FilterItem className={styles.filterLabel} name="date">
        <DateTimePicker
          className={styles.dateFilter}
          placeholder="Filter by date"
          inputReadOnly
          format={DEFAULT_DATE_FORMAT}
          allowClear={false}
        />
      </FilterItem>
    </PageFilter>
  );
};

export default memo(EnvironmentQualityFilter);
