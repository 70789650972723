import { memo, useEffect } from "react";

import { Select, Input } from "antd";
import { useLocation } from "react-router-dom";
import { usePrevious } from "react-use";

import { useForm } from "@app/components/atoms/Form/Form";
import PageFilter, {
  FilterItem,
} from "@app/components/molecules/PageFilter/PageFilter";
import { GenderEnum } from "@app/constants/gender.constants";

import useUserList from "../../hooks/useUserList";
import styles from "./UsersFilter.module.scss";

export interface UsersFilterProps {
  search?: string;
}

const { Search } = Input;
const { Option } = Select;

const UsersFilter = () => {
  const location = useLocation();
  const prevLocation = usePrevious(location);
  const [form] = useForm();
  const {
    getInitialUserList,
    getUpdatedUserList,
    loading,
    updateSearchParams,
  } = useUserList();

  useEffect(() => {
    // Fetch User on initial load
    if (prevLocation?.search === undefined) {
      getInitialUserList();
    }

    // Fetch data on navigation reset
    if (prevLocation?.search && location.search === "") {
      updateSearchParams({
        gender: undefined,
      });
      getInitialUserList(true);
    }
  }, [
    form,
    getInitialUserList,
    location.search,
    prevLocation?.search,
    updateSearchParams,
  ]);

  const searchUsers = () => {
    const gender = form.getFieldValue("gender") || undefined;
    const search = form.getFieldValue("search") || undefined;

    updateSearchParams({
      page: 1,
      gender,
      search,
      orderBy: undefined,
      sort: undefined,
    }); // Resets search params
    getUpdatedUserList({
      searchQuery: search,
      gender,
      page: 1,
      orderBy: undefined,
      sort: undefined,
    });
  };

  const handleSearch = async () => {
    form.submit();

    if (!loading) {
      searchUsers();
    }
  };

  return (
    <PageFilter<UsersFilterProps>
      parseNumbers={["search"]}
      form={form}
      columns={2}
    >
      <FilterItem className={styles.filterLabel} name="gender">
        <Select
          placeholder="Filter by gender"
          onSelect={handleSearch}
          onClear={() => {
            form.setFieldsValue({ gender: "" });
            handleSearch();
          }}
          allowClear
          className={styles.genderFilter}
        >
          <Option value={GenderEnum.MALE}>Male</Option>
          <Option value={GenderEnum.FEMALE}>Female</Option>
          <Option value={GenderEnum.OTHER}>Other</Option>
        </Select>
      </FilterItem>
      <FilterItem className={styles.filterLabel} name="search">
        <Search
          placeholder="Search by name, phone or email"
          enterButton
          onSearch={handleSearch}
        />
      </FilterItem>
    </PageFilter>
  );
};

export default memo(UsersFilter);
