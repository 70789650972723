import { memo } from "react";

import { Select } from "antd";
import { useForm } from "antd/lib/form/Form";
import { useEffectOnce } from "react-use";

import PageFilter, {
  FilterItem,
} from "@app/components/molecules/PageFilter/PageFilter";
import { getAllFacilities } from "@app/features/facility/facility";
import { useAppDispatch, useAppSelector } from "@app/redux/store";

import useFacilityList from "../../../facility/hooks/useFacilityList";
import usePackageList from "../../hooks/usePackageList";
import styles from "./PackagesFilter.module.scss";

export interface PackagesFilterProps {
  facilityId?: number;
}

const PackageFilterByFacility = () => {
  const [form] = useForm();
  const { loading, updateSearchParams, getUpdatedPackageList } =
    usePackageList();
  const dispatch = useAppDispatch();
  const { getUpdatedFacilityList } = useFacilityList();
  const { facilityList } = useAppSelector(state => ({
    facilityList: state.facility?.facilityList,
  }));

  useEffectOnce(() => {
    dispatch(getAllFacilities());
    if (getUpdatedFacilityList && !form.isFieldsTouched()) {
      form.setFieldsValue(getUpdatedFacilityList);
    }
  });

  const handleFacilitySearch = (value: string) => {
    return value;
  };

  const filterByFacility = () => {
    updateSearchParams({ page: 1, orderBy: undefined, sort: undefined }); // Resets search params
    getUpdatedPackageList({
      facilityId: form.getFieldValue("facilityId") || undefined,
      page: 1,
      orderBy: undefined,
      sort: undefined,
    });
  };

  const handleFacilityFilter = async () => {
    form.submit();

    if (!loading) {
      filterByFacility();
    }
  };

  return (
    <PageFilter<PackagesFilterProps> parseNumbers={["facilityId"]} form={form}>
      <FilterItem className={styles.filterLabel} name="facilityId">
        <Select
          showSearch
          allowClear
          placeholder="Please select a Facility"
          optionFilterProp="children"
          onSearch={handleFacilitySearch}
          onChange={handleFacilityFilter}
          filterOption={(input, option) =>
            (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
          }
          options={facilityList.map(item => ({
            label: item.name,
            value: item.id,
          }))}
        />
      </FilterItem>
    </PageFilter>
  );
};

export default memo(PackageFilterByFacility);
