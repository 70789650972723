import { AxiosResponse } from "axios";

import { api } from "@app/api/api";

import { UserEndpointsEnum } from "../constants/user.endpoints";
import {
  BlockUserDef,
  GetUserDef,
  GetUserListDef,
  GetUserHistoriesDef,
  UnblockUserDef,
} from "../types/user.types";

export const getUserList = (data: GetUserListDef): Promise<AxiosResponse> => {
  return api.get(UserEndpointsEnum.GET_USER_LIST, {
    params: {
      page: data.page,
      per_page: data.perPage,
      search_query: data.searchQuery,
      gender: data.gender,
      sort: data.sort,
      order_by: data.orderBy,
    },
  });
};

export const getUser = (data: GetUserDef): Promise<AxiosResponse> => {
  return api.get(`${UserEndpointsEnum.GET_USER_LIST}/${data.id}`);
};

export const blockUser = (data: BlockUserDef): Promise<AxiosResponse> => {
  return api.post(`${UserEndpointsEnum.BLOCK_USER}/${data.id}/block`, {
    blocked: true,
  });
};

export const unblockUser = (data: UnblockUserDef): Promise<AxiosResponse> => {
  return api.delete(`${UserEndpointsEnum.UNBLOCK_USER}/${data.id}/block`, {
    data: {
      blocked: false,
    },
  });
};

export const getUserPurchaseHistory = (
  data: GetUserHistoriesDef
): Promise<AxiosResponse> => {
  return api.get(`${UserEndpointsEnum.GET_USER_HISTORIES}/${data.id}/history`, {
    params: {
      from: data.from,
      to: data.to,
      sort: data.sort,
      order_by: data.orderBy,
    },
  });
};

export const getUserPackageSubscriptionHistory = (
  data: GetUserHistoriesDef
): Promise<AxiosResponse> => {
  return api.get(
    `${UserEndpointsEnum.GET_USER_HISTORIES}/${data.id}/subscription-packages`,
    {
      params: {
        from: data.from,
        to: data.to,
        sort: data.sort,
        order_by: data.orderBy,
      },
    }
  );
};
