import { DEFAULT_PAGE_SIZE } from "@app/constants/pagination.constants";
import useSearchParams, { SearchParamDef } from "@app/hooks/useSearchParams";
import { useAppDispatch, useAppSelector } from "@app/redux/store";
import { SortEnum } from "@app/types/table.types";

import {
  updateCurrentParams,
  getAllPackages,
  GetPackageListDef,
  SearchQueryParamDef,
} from "../package";

const usePackageList = () => {
  const dispatch = useAppDispatch();
  const { search, updateSearchParams } =
    useSearchParams<SearchParamDef<SearchQueryParamDef>>();

  const { packageList, loading, paginationData } = useAppSelector(state => ({
    packageList: state.subscriptionPackage.packageList,
    loading: state.subscriptionPackage.loading,
    paginationData: state.subscriptionPackage.paginationData,
  }));

  const parseSort = () => {
    if (!search.sort) {
      return undefined;
    }
    return search.sort;
  };

  const parseOrderBy = () => {
    if (!search.sort) {
      return undefined;
    }

    if (search.orderBy === "createdAt") {
      return "created_at";
    }

    if (search.orderBy === "name") {
      return "title";
    }

    return search.orderBy;
  };

  const getInitialPackageList = (reset?: boolean) => {
    const params = {
      searchQuery: !reset ? search.search ?? undefined : undefined,
      facilityId: !reset ? search.facilityId ?? undefined : undefined,
      orderBy: parseOrderBy() ?? "is_active",
      sort: parseSort() ?? SortEnum.DESC,
      page: !reset ? search.page : 1,
      perPage: !reset
        ? search.pageSize ?? DEFAULT_PAGE_SIZE
        : DEFAULT_PAGE_SIZE,
    };
    dispatch(updateCurrentParams(params));
    dispatch(getAllPackages());
  };

  const getUpdatedPackageList = (params: GetPackageListDef) => {
    dispatch(updateCurrentParams(params));
    dispatch(getAllPackages());
  };

  return {
    getInitialPackageList,
    getUpdatedPackageList,
    packageList,
    loading,
    paginationData,
    updateSearchParams,
  };
};

export default usePackageList;
